@charset "UTF-8";
/* Transition Mixin */
/* Border-Radius Mixin */
/* Box-Shadow Mixin */
/* Box-Shadow Mixin */
/* Text-Shadow Mixin */
/* Opacity Mixin */
/* Gradient Top Bottom Mixin */
/* Gradient Left Right Mixin */
/* Transform Mixin */
/* Rotate Mixin */
/* Scale Mixin */
/* Translate Mixin */
/* TranslateY Mixin */
/* TranslateX Mixin */
/* Transform Origin Mixin */
/* Animate Mixin */
/* Placeholder Mixin (Placeholder styling doesnt work in every browser) */
/* Flexbox Mixin */
@import url(//fonts.googleapis.com/earlyaccess/nanumpenscript.css);
input::-moz-focus-inner {
  border: 0;
}

object, embed, a, a:hover, a:active, a:focus {
  outline: 0;
}

/* Grid */
.clearfix, div.pageTools, .row {
  zoom: 1;
}
.clearfix:before, div.pageTools:before, .row:before, .clearfix:after, div.pageTools:after, .row:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after, div.pageTools:after, .row:after {
  clear: both;
}

.left, .column, footer ul.doormat > li, footer ul.doormat, .col12,
.col-md-12, .col11,
.col-md-11, .col10,
.col-md-10, .col9,
.col-md-9, .col8,
.col-md-8, .col7,
.col-md-7, .col6,
.col-md-6, .col5,
.col-md-5, .col4,
.col-md-4, .col3,
.col-md-3, .col2,
.col-md-2, .col1,
.col-md-1 {
  float: left;
}

.row {
  margin: 0 -20px;
}

.column, footer ul.doormat > li, footer ul.doormat, .col12,
.col-md-12, .col11,
.col-md-11, .col10,
.col-md-10, .col9,
.col-md-9, .col8,
.col-md-8, .col7,
.col-md-7, .col6,
.col-md-6, .col5,
.col-md-5, .col4,
.col-md-4, .col3,
.col-md-3, .col2,
.col-md-2, .col1,
.col-md-1 {
  margin-left: 1.6949152542%;
}

.col1,
.col-md-1 {
  width: 6.7796610169%;
}
@media screen and (max-width: 767px) {
  .col1,
  .col-md-1 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col1.centered:first-child,
.col-md-1.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col1:first-child,
.col-md-1:first-child {
  margin-left: 0;
}

.col2,
.col-md-2 {
  width: 15.2542372881%;
}
@media screen and (max-width: 767px) {
  .col2,
  .col-md-2 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col2.centered:first-child,
.col-md-2.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col2:first-child,
.col-md-2:first-child {
  margin-left: 0;
}

.col3,
.col-md-3 {
  width: 23.7288135593%;
}
@media screen and (max-width: 767px) {
  .col3,
  .col-md-3 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col3.centered:first-child,
.col-md-3.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col3:first-child,
.col-md-3:first-child {
  margin-left: 0;
}

.col4,
.col-md-4 {
  width: 32.2033898305%;
}
@media screen and (max-width: 767px) {
  .col4,
  .col-md-4 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col4.centered:first-child,
.col-md-4.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col4:first-child,
.col-md-4:first-child {
  margin-left: 0;
}

.col5,
.col-md-5 {
  width: 40.6779661017%;
}
@media screen and (max-width: 767px) {
  .col5,
  .col-md-5 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col5.centered:first-child,
.col-md-5.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col5:first-child,
.col-md-5:first-child {
  margin-left: 0;
}

.col6,
.col-md-6 {
  width: 49.1525423729%;
}
@media screen and (max-width: 767px) {
  .col6,
  .col-md-6 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col6.centered:first-child,
.col-md-6.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col6:first-child,
.col-md-6:first-child {
  margin-left: 0;
}

.col7,
.col-md-7 {
  width: 57.6271186441%;
}
@media screen and (max-width: 767px) {
  .col7,
  .col-md-7 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col7.centered:first-child,
.col-md-7.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col7:first-child,
.col-md-7:first-child {
  margin-left: 0;
}

.col8,
.col-md-8 {
  width: 66.1016949153%;
}
@media screen and (max-width: 767px) {
  .col8,
  .col-md-8 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col8.centered:first-child,
.col-md-8.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col8:first-child,
.col-md-8:first-child {
  margin-left: 0;
}

.col9,
.col-md-9 {
  width: 74.5762711864%;
}
@media screen and (max-width: 767px) {
  .col9,
  .col-md-9 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col9.centered:first-child,
.col-md-9.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col9:first-child,
.col-md-9:first-child {
  margin-left: 0;
}

.col10,
.col-md-10 {
  width: 83.0508474576%;
}
@media screen and (max-width: 767px) {
  .col10,
  .col-md-10 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col10.centered:first-child,
.col-md-10.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col10:first-child,
.col-md-10:first-child {
  margin-left: 0;
}

.col11,
.col-md-11 {
  width: 91.5254237288%;
}
@media screen and (max-width: 767px) {
  .col11,
  .col-md-11 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col11.centered:first-child,
.col-md-11.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col11:first-child,
.col-md-11:first-child {
  margin-left: 0;
}

.col12,
.col-md-12 {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .col12,
  .col-md-12 {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
.col12.centered:first-child,
.col-md-12.centered:first-child {
  float: none;
  margin: 0 auto;
}
.col12:first-child,
.col-md-12:first-child {
  margin-left: 0;
}

section.content .push1,
section.content .push1:first-child {
  margin: 0 8.4745762712%;
}
@media screen and (max-width: 1000px) {
  section.content .push1,
  section.content .push1:first-child {
    margin: 0;
  }
}
section.content .push1 h1,
section.content .push1 .row > div > div > h2,
section.content .push1 .row > div > div > h3,
section.content .push1 .row > div > div > h4,
section.content .push1 .row > div > div > ul,
section.content .push1 .row > div > div > p,
section.content .push1 .row > div > div > table,
section.content .push1 .pageWidgets,
section.content .push1 .row > div > div > .highlighted,
section.content .push1 .row > div > div > .additionalContent,
section.content .push1 span.newsDate,
section.content .push1:first-child h1,
section.content .push1:first-child .row > div > div > h2,
section.content .push1:first-child .row > div > div > h3,
section.content .push1:first-child .row > div > div > h4,
section.content .push1:first-child .row > div > div > ul,
section.content .push1:first-child .row > div > div > p,
section.content .push1:first-child .row > div > div > table,
section.content .push1:first-child .pageWidgets,
section.content .push1:first-child .row > div > div > .highlighted,
section.content .push1:first-child .row > div > div > .additionalContent,
section.content .push1:first-child span.newsDate {
  margin-left: 8.4745762712%;
  margin-right: 8.4745762712%;
}
@media screen and (max-width: 767px) {
  section.content .push1 h1,
  section.content .push1 .row > div > div > h2,
  section.content .push1 .row > div > div > h3,
  section.content .push1 .row > div > div > h4,
  section.content .push1 .row > div > div > ul,
  section.content .push1 .row > div > div > p,
  section.content .push1 .row > div > div > table,
  section.content .push1 .pageWidgets,
  section.content .push1 .row > div > div > .highlighted,
  section.content .push1 .row > div > div > .additionalContent,
  section.content .push1 span.newsDate,
  section.content .push1:first-child h1,
  section.content .push1:first-child .row > div > div > h2,
  section.content .push1:first-child .row > div > div > h3,
  section.content .push1:first-child .row > div > div > h4,
  section.content .push1:first-child .row > div > div > ul,
  section.content .push1:first-child .row > div > div > p,
  section.content .push1:first-child .row > div > div > table,
  section.content .push1:first-child .pageWidgets,
  section.content .push1:first-child .row > div > div > .highlighted,
  section.content .push1:first-child .row > div > div > .additionalContent,
  section.content .push1:first-child span.newsDate {
    margin-left: 0;
    margin-right: 0;
  }
}
section.content .push1 div.pageTools,
section.content .push1:first-child div.pageTools {
  margin-left: 8.4745762712%;
  margin-right: 8.4745762712%;
  width: 83.0508474576%;
}
@media screen and (max-width: 767px) {
  section.content .push1 div.pageTools,
  section.content .push1:first-child div.pageTools {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
section.content .push2,
section.content .push2:first-child {
  margin: 0 16.9491525424%;
}
@media screen and (max-width: 1000px) {
  section.content .push2,
  section.content .push2:first-child {
    margin: 0;
  }
}
section.content .push2 h1,
section.content .push2 .row > div > div > h2,
section.content .push2 .row > div > div > h3,
section.content .push2 .row > div > div > h4,
section.content .push2 .row > div > div > ul,
section.content .push2 .row > div > div > p,
section.content .push2 .row > div > div > table,
section.content .push2 .pageWidgets,
section.content .push2 .row > div > div > .highlighted,
section.content .push2 .row > div > div > .additionalContent,
section.content .push2 span.newsDate,
section.content .push2:first-child h1,
section.content .push2:first-child .row > div > div > h2,
section.content .push2:first-child .row > div > div > h3,
section.content .push2:first-child .row > div > div > h4,
section.content .push2:first-child .row > div > div > ul,
section.content .push2:first-child .row > div > div > p,
section.content .push2:first-child .row > div > div > table,
section.content .push2:first-child .pageWidgets,
section.content .push2:first-child .row > div > div > .highlighted,
section.content .push2:first-child .row > div > div > .additionalContent,
section.content .push2:first-child span.newsDate {
  margin-left: 16.9491525424%;
  margin-right: 16.9491525424%;
}
@media screen and (max-width: 767px) {
  section.content .push2 h1,
  section.content .push2 .row > div > div > h2,
  section.content .push2 .row > div > div > h3,
  section.content .push2 .row > div > div > h4,
  section.content .push2 .row > div > div > ul,
  section.content .push2 .row > div > div > p,
  section.content .push2 .row > div > div > table,
  section.content .push2 .pageWidgets,
  section.content .push2 .row > div > div > .highlighted,
  section.content .push2 .row > div > div > .additionalContent,
  section.content .push2 span.newsDate,
  section.content .push2:first-child h1,
  section.content .push2:first-child .row > div > div > h2,
  section.content .push2:first-child .row > div > div > h3,
  section.content .push2:first-child .row > div > div > h4,
  section.content .push2:first-child .row > div > div > ul,
  section.content .push2:first-child .row > div > div > p,
  section.content .push2:first-child .row > div > div > table,
  section.content .push2:first-child .pageWidgets,
  section.content .push2:first-child .row > div > div > .highlighted,
  section.content .push2:first-child .row > div > div > .additionalContent,
  section.content .push2:first-child span.newsDate {
    margin-left: 0;
    margin-right: 0;
  }
}
section.content .push2 div.pageTools,
section.content .push2:first-child div.pageTools {
  margin-left: 16.9491525424%;
  margin-right: 16.9491525424%;
  width: 83.0508474576%;
}
@media screen and (max-width: 767px) {
  section.content .push2 div.pageTools,
  section.content .push2:first-child div.pageTools {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
section.content .push3,
section.content .push3:first-child {
  margin: 0 25.4237288136%;
}
@media screen and (max-width: 1000px) {
  section.content .push3,
  section.content .push3:first-child {
    margin: 0;
  }
}
section.content .push3 h1,
section.content .push3 .row > div > div > h2,
section.content .push3 .row > div > div > h3,
section.content .push3 .row > div > div > h4,
section.content .push3 .row > div > div > ul,
section.content .push3 .row > div > div > p,
section.content .push3 .row > div > div > table,
section.content .push3 .pageWidgets,
section.content .push3 .row > div > div > .highlighted,
section.content .push3 .row > div > div > .additionalContent,
section.content .push3 span.newsDate,
section.content .push3:first-child h1,
section.content .push3:first-child .row > div > div > h2,
section.content .push3:first-child .row > div > div > h3,
section.content .push3:first-child .row > div > div > h4,
section.content .push3:first-child .row > div > div > ul,
section.content .push3:first-child .row > div > div > p,
section.content .push3:first-child .row > div > div > table,
section.content .push3:first-child .pageWidgets,
section.content .push3:first-child .row > div > div > .highlighted,
section.content .push3:first-child .row > div > div > .additionalContent,
section.content .push3:first-child span.newsDate {
  margin-left: 25.4237288136%;
  margin-right: 25.4237288136%;
}
@media screen and (max-width: 767px) {
  section.content .push3 h1,
  section.content .push3 .row > div > div > h2,
  section.content .push3 .row > div > div > h3,
  section.content .push3 .row > div > div > h4,
  section.content .push3 .row > div > div > ul,
  section.content .push3 .row > div > div > p,
  section.content .push3 .row > div > div > table,
  section.content .push3 .pageWidgets,
  section.content .push3 .row > div > div > .highlighted,
  section.content .push3 .row > div > div > .additionalContent,
  section.content .push3 span.newsDate,
  section.content .push3:first-child h1,
  section.content .push3:first-child .row > div > div > h2,
  section.content .push3:first-child .row > div > div > h3,
  section.content .push3:first-child .row > div > div > h4,
  section.content .push3:first-child .row > div > div > ul,
  section.content .push3:first-child .row > div > div > p,
  section.content .push3:first-child .row > div > div > table,
  section.content .push3:first-child .pageWidgets,
  section.content .push3:first-child .row > div > div > .highlighted,
  section.content .push3:first-child .row > div > div > .additionalContent,
  section.content .push3:first-child span.newsDate {
    margin-left: 0;
    margin-right: 0;
  }
}
section.content .push3 div.pageTools,
section.content .push3:first-child div.pageTools {
  margin-left: 25.4237288136%;
  margin-right: 25.4237288136%;
  width: 83.0508474576%;
}
@media screen and (max-width: 767px) {
  section.content .push3 div.pageTools,
  section.content .push3:first-child div.pageTools {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
section.content .push4,
section.content .push4:first-child {
  margin: 0 33.8983050847%;
}
@media screen and (max-width: 1000px) {
  section.content .push4,
  section.content .push4:first-child {
    margin: 0;
  }
}
section.content .push4 h1,
section.content .push4 .row > div > div > h2,
section.content .push4 .row > div > div > h3,
section.content .push4 .row > div > div > h4,
section.content .push4 .row > div > div > ul,
section.content .push4 .row > div > div > p,
section.content .push4 .row > div > div > table,
section.content .push4 .pageWidgets,
section.content .push4 .row > div > div > .highlighted,
section.content .push4 .row > div > div > .additionalContent,
section.content .push4 span.newsDate,
section.content .push4:first-child h1,
section.content .push4:first-child .row > div > div > h2,
section.content .push4:first-child .row > div > div > h3,
section.content .push4:first-child .row > div > div > h4,
section.content .push4:first-child .row > div > div > ul,
section.content .push4:first-child .row > div > div > p,
section.content .push4:first-child .row > div > div > table,
section.content .push4:first-child .pageWidgets,
section.content .push4:first-child .row > div > div > .highlighted,
section.content .push4:first-child .row > div > div > .additionalContent,
section.content .push4:first-child span.newsDate {
  margin-left: 33.8983050847%;
  margin-right: 33.8983050847%;
}
@media screen and (max-width: 767px) {
  section.content .push4 h1,
  section.content .push4 .row > div > div > h2,
  section.content .push4 .row > div > div > h3,
  section.content .push4 .row > div > div > h4,
  section.content .push4 .row > div > div > ul,
  section.content .push4 .row > div > div > p,
  section.content .push4 .row > div > div > table,
  section.content .push4 .pageWidgets,
  section.content .push4 .row > div > div > .highlighted,
  section.content .push4 .row > div > div > .additionalContent,
  section.content .push4 span.newsDate,
  section.content .push4:first-child h1,
  section.content .push4:first-child .row > div > div > h2,
  section.content .push4:first-child .row > div > div > h3,
  section.content .push4:first-child .row > div > div > h4,
  section.content .push4:first-child .row > div > div > ul,
  section.content .push4:first-child .row > div > div > p,
  section.content .push4:first-child .row > div > div > table,
  section.content .push4:first-child .pageWidgets,
  section.content .push4:first-child .row > div > div > .highlighted,
  section.content .push4:first-child .row > div > div > .additionalContent,
  section.content .push4:first-child span.newsDate {
    margin-left: 0;
    margin-right: 0;
  }
}
section.content .push4 div.pageTools,
section.content .push4:first-child div.pageTools {
  margin-left: 33.8983050847%;
  margin-right: 33.8983050847%;
  width: 83.0508474576%;
}
@media screen and (max-width: 767px) {
  section.content .push4 div.pageTools,
  section.content .push4:first-child div.pageTools {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  section.content div.container .push1,
  section.content div.container .push1:first-child {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  section.content div.container h1,
  section.content div.container .row > div > div > h2,
  section.content div.container .row > div > div > h3,
  section.content div.container .row > div > div > h4,
  section.content div.container .row > div > div > ul,
  section.content div.container .row > div > div > p,
  section.content div.container .pageWidgets,
  section.content div.container .row > div > div > .highlighted,
  section.content div.container .row > div > div > .additionalContent,
  section.content div.container span.newsDate,
  section.content div.container div.pageTools {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  section.content div.container .push2,
  section.content div.container .push2:first-child {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  section.content div.container h1,
  section.content div.container .row > div > div > h2,
  section.content div.container .row > div > div > h3,
  section.content div.container .row > div > div > h4,
  section.content div.container .row > div > div > ul,
  section.content div.container .row > div > div > p,
  section.content div.container .pageWidgets,
  section.content div.container .row > div > div > .highlighted,
  section.content div.container .row > div > div > .additionalContent,
  section.content div.container span.newsDate,
  section.content div.container div.pageTools {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  section.content div.container .push3,
  section.content div.container .push3:first-child {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  section.content div.container h1,
  section.content div.container .row > div > div > h2,
  section.content div.container .row > div > div > h3,
  section.content div.container .row > div > div > h4,
  section.content div.container .row > div > div > ul,
  section.content div.container .row > div > div > p,
  section.content div.container .pageWidgets,
  section.content div.container .row > div > div > .highlighted,
  section.content div.container .row > div > div > .additionalContent,
  section.content div.container span.newsDate,
  section.content div.container div.pageTools {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  section.content div.container .push4,
  section.content div.container .push4:first-child {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  section.content div.container h1,
  section.content div.container .row > div > div > h2,
  section.content div.container .row > div > div > h3,
  section.content div.container .row > div > div > h4,
  section.content div.container .row > div > div > ul,
  section.content div.container .row > div > div > p,
  section.content div.container .pageWidgets,
  section.content div.container .row > div > div > .highlighted,
  section.content div.container .row > div > div > .additionalContent,
  section.content div.container span.newsDate,
  section.content div.container div.pageTools {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  section.content .col10.push1 {
    width: 100%;
  }
}

.moreInfoCol {
  float: Right;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-weight: bold;
  font-style: italic;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
}

input,
select {
  vertical-align: middle;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.browserUpgrade {
  background: #ffffff;
  border-bottom: 1px solid #000000;
  color: #000000;
  left: 0;
  line-height: 20px;
  padding: 8px 20px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
}
.browserUpgrade a {
  color: #000000;
  text-decoration: underline;
}
.browserUpgrade a:hover {
  text-decoration: none;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 20px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .container.noPaddingMobile {
    padding: 0;
  }
}

.container.fullHeight {
  height: 100vh;
  min-height: 800px;
}

.container:before,
.container:after {
  content: "";
  display: table;
}

.container:after {
  clear: both;
}

img {
  max-width: 100%;
  pointer-events: none !important;
}

/* Core Owl Carousel CSS */
/* clearfix 
.carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}*/
/* display none until init 
.carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}

.carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.carousel .owl-wrapper-outer{
	@include border-radius(3px);
	overflow: hidden;
	position: relative;
	width: 100%;
}
.carousel .owl-wrapper-outer.autoHeight{
	@include transition(height 500ms ease-in-out);
}

.carousel .owl-item{
	float: left;
}
.owl-controls .owl-pagination {
	bottom: -42px;
	display: block;
	position: absolute;
	text-align: center;
	width: 100%;
}

.owl-controls .owl-page {
	background: #E6DFDA;
	@include border-radius(50%);
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 5px 0 6px;
	width: 12px;

	&.active {
		background: #999799;
	}
}

.owl-controls .owl-buttons div{
	background-color: #D54D12;
	@include border-radius(3px);
	bottom: 30px;
	@include box-shadow(0px 1px 3px 0px rgba(138,49,11,0.24));
	box-sizing: border-box;
	cursor: pointer;
	height: 40px;
	line-height: 20px;
	margin-top: -20px;
	@include opacity(0.9);
	padding: 7px;
	position: absolute;
	right: 40px;
	text-align: center;
	top: 50%;
	@include transition(all 0.2s ease-out);
	width: 40px;
	z-index: 1;

	@include max-screen($menuBreakpointRes) {
		right: 0;
	}

	@include max-screen($tabletPortrait) {
		display: none;
	}

	@include max-screen-height(600px) {
		bottom: 30px;
		left: 10px;
		right: auto;
	}

	&:after {
		content: '\E802';
		color: #ffffff;
		display: inline;
		font-family: 'helioIcon';
		font-size: 12px;
		margin-right: -2px;
	} 
}
.owl-controls .owl-buttons div:hover {
	@include opacity(1);
}
.owl-controls .owl-buttons .owl-prev {
	left: 40px;
}
.owl-controls .owl-buttons .owl-next {
	right: 40px;
}
.owl-controls .owl-buttons .owl-prev:after {
	content: '\E806';
	margin-right: 2px;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*/
/* mouse grab icon 
.grabbing { 
    cursor:  move;
}
*/
/* fix 
.carousel .owl-wrapper,
.carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}*/
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-controls .owl-dots {
  bottom: -42px;
  display: block;
  position: absolute;
  text-align: center;
  width: 100%;
}

.owl-controls .owl-dot {
  background: #E6DFDA;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  margin: 0 5px 0 6px;
  width: 12px;
}
.owl-controls .owl-dot.active {
  background: #999799;
}

.owl-controls .owl-nav div {
  background-color: #D54D12;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  bottom: 30px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  -moz-box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  box-sizing: border-box;
  cursor: pointer;
  height: 40px;
  line-height: 20px;
  margin-top: -20px;
  opacity: 0.9;
  filter: alpha(opacity=90);
  pointer-events: all;
  padding: 7px;
  position: absolute;
  right: 40px;
  text-align: center;
  top: 50%;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 40px;
  z-index: 1;
}
@media screen and (max-width: 1000px) {
  .owl-controls .owl-nav div {
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .owl-controls .owl-nav div {
    display: none;
  }
}
.owl-controls .owl-nav div.disabled {
  display: none !important;
}
.owl-controls .owl-nav div:after {
  content: "\e802";
  color: #ffffff;
  display: inline;
  font-family: "helioIcon";
  font-size: 12px;
  margin-right: -2px;
}

.owl-controls .owl-nav div:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}

.owl-controls .owl-nav .owl-prev {
  left: 40px;
}

.owl-controls .owl-nav .owl-next {
  right: 40px;
}

.owl-controls .owl-nav .owl-prev:after {
  content: "\e806";
  margin-right: 2px;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

:root {
  --consent-cookiebar-bg: #fff;
  --consent-cookiebar-color: black;
  --consent-button-background: #D54D12;
  --consent-button-color: #fff;
  --consent-button-highlight-background: #D54D12;
  --consent-button-highlight-color: #fff;
}

.consent-cookiebar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--consent-cookiebar-bg);
  backdrop-filter: blur(10px);
  color: var(--consent-cookiebar-color);
  padding: 20px 0;
  transform: translateY(100%);
  z-index: 9999;
  transition: transform 800ms ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.consent-cookiebar.open, .consent-cookiebar[open] {
  transform: translateY(0);
}
@media only screen and (min-width: 768px) {
  .consent-cookiebar {
    flex-direction: row;
  }
}
.consent-cookiebar .col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (min-width: 768px) {
  .consent-cookiebar .col {
    flex-direction: row;
  }
}
.consent-cookiebar .content {
  flex: 1 1 auto;
}
.consent-cookiebar .content .title {
  display: block;
  margin-bottom: 10px;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--consent-cookiebar-color);
}
.consent-cookiebar .content .mobile {
  font-size: 1rem;
  line-height: 1.1rem;
}
@media only screen and (min-width: 768px) {
  .consent-cookiebar .content .mobile {
    display: none;
  }
}
.consent-cookiebar .content .desktop {
  font-size: 1rem;
  line-height: 1.4rem;
  display: none;
}
@media only screen and (min-width: 768px) {
  .consent-cookiebar .content .desktop {
    display: block;
  }
}
.consent-cookiebar .actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 0 0 auto;
  gap: 10px;
}
@media only screen and (min-width: 768px) {
  .consent-cookiebar .actions {
    flex-direction: column;
  }
}
.consent-cookiebar .actions .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--consent-button-background);
  color: var(--consent-button-color);
}
.consent-cookiebar .actions .button:hover, .consent-cookiebar .actions .button:focus {
  background: var(--consent-button-highlight-background);
  color: var(--consent-button-highlight-color);
}

.consent-form .title {
  display: block;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 20px;
}
.consent-form .checkboxTitle {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
}
.consent-form .consentCheckbox {
  margin-bottom: 20px;
}
.consent-form .consentCheckbox input[type=checkbox] {
  position: absolute;
  height: 0.1px;
  width: 0.1px;
  left: -100vw;
  overflow: hidden;
  visibility: hidden;
}
.consent-form .consentCheckbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 0;
}
.consent-form .consentCheckbox label:before, .consent-form .consentCheckbox label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
}
.consent-form .consentCheckbox label:before {
  border: 1px solid black;
}
.consent-form .consentCheckbox label:after {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  content: "✓";
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.consent-form .consentCheckbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.consent-form .consentCheckbox input[type=checkbox]:disabled + label:before {
  opacity: 0.7;
}
.consent-form .saveRow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .consent-form .saveRow {
    flex-direction: row;
    align-items: center;
  }
}
.consent-form .successText {
  margin-top: 10px;
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}
@media only screen and (min-width: 768px) {
  .consent-form .successText {
    margin-top: 0;
    margin-left: 20px;
  }
}
.consent-form .successText.saved {
  opacity: 1;
  transition: none;
}

@font-face {
  font-family: "latolight";
  src: url("/fonts/lato-lig-webfont.eot");
  src: url("/fonts/lato-lig-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-lig-webfont.woff2") format("woff2"), url("/fonts/lato-lig-webfont.woff") format("woff"), url("/fonts/lato-lig-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latobold";
  src: url("/fonts/lato-bol-webfont.eot");
  src: url("/fonts/lato-bol-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-bol-webfont.woff2") format("woff2"), url("/fonts/lato-bol-webfont.woff") format("woff"), url("/fonts/lato-bol-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.consent-cookiebar {
  border-radius: 4px 4px 0 0;
  z-index: 9999999;
  border-top: 1px solid #d8d8d8;
}
.consent-cookiebar .row {
  margin: 0;
}
.consent-cookiebar .content .title {
  font-size: 34px;
  line-height: 43px;
  font-family: latolight;
  margin-bottom: 30px;
  color: #532078;
}
.consent-cookiebar .content .text {
  max-width: 800px;
}

.consent-cookiebar .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.consent-cookiebar .actions a.button, .consent-cookiebar .actions button.button {
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 10px 15px;
  justify-content: center;
  text-decoration: none;
  z-index: 1;
  outline: 0;
  border: 0;
  font-size: 1rem;
  line-height: normal;
  display: block;
}
.consent-cookiebar .actions a.button span, .consent-cookiebar .actions button.button span {
  font-size: unset;
  line-height: unset;
  margin: 0;
}
.consent-cookiebar .actions a.button:hover, .consent-cookiebar .actions button.button:hover {
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .consent-cookiebar .actions a.button, .consent-cookiebar .actions button.button {
    max-width: none;
    display: block;
    font-size: 1.2rem;
    padding: 7px 15px;
  }
}

.consent-form {
  margin-bottom: 40px;
  margin-top: 40px;
}
.consent-form .title {
  font-size: 21px;
  font-family: latobold;
  font-weight: 400;
}
.consent-form .checkboxTitle {
  font-size: 18px;
  font-weight: 400;
}
.consent-form .consentCheckbox label {
  cursor: pointer;
}
.consent-form .consentCheckbox label::before, .consent-form .consentCheckbox label::after {
  top: 4px;
}
.consent-form .button {
  border: 0;
  cursor: pointer;
}

@font-face {
  font-family: "helioIcon";
  src: url("/fonts/fontello.eot?28043464");
  src: url("/fonts/fontello.eot?28043464#iefix") format("embedded-opentype"), url("/fonts/fontello.woff?28043464") format("woff"), url("/fonts/fontello.ttf?28043464") format("truetype"), url("/fonts/fontello.svg?28043464#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latoblack";
  src: url("/fonts/lato-bla-webfont.eot");
  src: url("/fonts/lato-bla-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-bla-webfont.woff2") format("woff2"), url("/fonts/lato-bla-webfont.woff") format("woff"), url("/fonts/lato-bla-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latobold";
  src: url("/fonts/lato-bol-webfont.eot");
  src: url("/fonts/lato-bol-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-bol-webfont.woff2") format("woff2"), url("/fonts/lato-bol-webfont.woff") format("woff"), url("/fonts/lato-bol-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latobold_italic";
  src: url("/fonts/lato-bolita-webfont.eot");
  src: url("/fonts/lato-bolita-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-bolita-webfont.woff2") format("woff2"), url("/fonts/lato-bolita-webfont.woff") format("woff"), url("/fonts/lato-bolita-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latolight";
  src: url("/fonts/lato-lig-webfont.eot");
  src: url("/fonts/lato-lig-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-lig-webfont.woff2") format("woff2"), url("/fonts/lato-lig-webfont.woff") format("woff"), url("/fonts/lato-lig-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latoregular";
  src: url("/fonts/lato-reg-webfont.eot");
  src: url("/fonts/lato-reg-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-reg-webfont.woff2") format("woff2"), url("/fonts/lato-reg-webfont.woff") format("woff"), url("/fonts/lato-reg-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latoitalic";
  src: url("/fonts/lato-regita-webfont.eot");
  src: url("/fonts/lato-regita-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-regita-webfont.woff2") format("woff2"), url("/fonts/lato-regita-webfont.woff") format("woff"), url("/fonts/lato-regita-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
html,
body {
  height: 100%;
  width: 100%;
}

body {
  color: #504D4C;
  font-family: "latoregular";
  font-size: 18px;
  margin: 0;
  min-width: 320px;
  overflow-y: scroll !important;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

@media screen and (max-width: 1000px) {
  body.showMenu {
    overflow-y: hidden !important;
    position: fixed;
    -webkit-transform: translateX(-304px);
    -moz-transform: translateX(-304px);
    -ms-transform: translateX(-304px);
    transform: translateX(-304px);
  }
}
@media screen and (max-width: 360px) {
  body.showMenu {
    -webkit-transform: translateX(-264px);
    -moz-transform: translateX(-264px);
    -ms-transform: translateX(-264px);
    transform: translateX(-264px);
  }
}
body.showMenu .openMenu:before {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

h1 {
  color: #4F1E73;
  font-size: 40px;
  font-family: "latobold";
  font-weight: normal;
  line-height: 46px;
  margin: 3px 0 19px;
}
@media screen and (max-width: 1000px) {
  h1 {
    font-size: 35px;
    line-height: 40px;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
h1 + span.address {
  color: #999799;
  display: block;
  margin: -8px 0 47px;
}

h2 {
  font-size: 21px;
  font-family: "latobold";
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 18px;
  }
}

h3 {
  font-size: 18px;
  font-family: "latobold";
  font-weight: normal;
}

div.serviceBar a.solliciteren,
a.solliciteren {
  background: #D54D12;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #ffffff;
  display: inline-block;
  font-family: "latobold";
  font-size: 16px;
  line-height: 29px;
  margin: 25px 0 73px;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  padding: 3px 14px 7px 15px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.serviceBar a.solliciteren:hover,
a.solliciteren:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  pointer-events: all;
}
div.serviceBar a.solliciteren:after, div.serviceBar a.solliciteren:before,
a.solliciteren:after,
a.solliciteren:before {
  display: none;
}

div.serviceBar a.solliciteren {
  display: block;
  margin: 32px 0 0;
  text-align: center;
}

strong {
  font-family: "latobold";
  font-weight: normal;
}

.button {
  background: #D54D12;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #ffffff;
  display: inline-block;
  font-family: "latobold";
  font-size: 16px;
  line-height: 30px;
  padding: 4px 12px 6px 15px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.button:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  pointer-events: all;
}

a.moreLink,
span.moreLink a {
  color: #D54D12;
  text-decoration: none;
}
a.moreLink:after,
span.moreLink a:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 25px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
a.moreLink.blue,
span.moreLink a.blue {
  color: #0E92C7;
}
a.moreLink.blue:after,
span.moreLink a.blue:after {
  color: rgba(14, 146, 199, 0.75);
}

span.moreLink a:after {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  span.moreLink a:after {
    line-height: 25px;
  }
}

a.moreLink:hover:after,
span.moreLink a:hover:after {
  margin: 1px 5px 0 6px;
}

a.backLink,
span.backLink a {
  color: #D54D12;
  text-decoration: none;
}
a.backLink:before,
span.backLink a:before {
  color: #EAA688;
  content: "\e806";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 25px;
  margin: 1px 11px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}

span.backLink a:before {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  span.backLink a:before {
    line-height: 25px;
  }
}

a.backLink:hover:before,
span.backLink a:hover:before {
  margin: 1px 6px 0 5px;
}

div.homeColors {
  position: relative;
  width: 100%;
}
div.homeColors.top {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999;
}
div.homeColors:after, div.homeColors:before {
  content: "";
  display: table;
}
div.homeColors:after {
  clear: both;
}
div.homeColors span {
  float: left;
  height: 5px;
  width: 20%;
}
div.homeColors span:nth-child(1) {
  background: #8EBD02;
}
div.homeColors span:nth-child(2) {
  background: #017CCB;
}
div.homeColors span:nth-child(3) {
  background: #F19600;
}
div.homeColors span:nth-child(4) {
  background: #E03501;
}
div.homeColors span:nth-child(5) {
  background: #C343B6;
}

a.logo {
  display: inline-block;
  margin: 45px 0;
}
@media screen and (max-width: 1000px) {
  a.logo {
    margin: 25px 0 17px;
    -webkit-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -moz-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -o-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  }
}
a.logo svg {
  max-height: 72px;
  max-width: 151px;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  a.logo svg {
    max-height: 49px;
    max-width: 105px;
  }
}
a.logo svg path.o {
  fill: #C9502A;
}
a.logo svg path.pL {
  fill: #B4A5C2;
}
a.logo svg path.pD {
  fill: #4A266D;
}

header {
  border-bottom: 1px solid #D8D8D8;
  padding-top: 5px;
  position: relative;
  max-width: 100%;
}
header div.container {
  max-width: 1460px;
}
header div.nav {
  float: right;
}
@media screen and (max-width: 1000px) {
  header div.nav {
    background: #E6DFDA;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: translateX(304px);
    -moz-transform: translateX(304px);
    -ms-transform: translateX(304px);
    transform: translateX(304px);
    width: 304px;
  }
}
@media screen and (max-width: 360px) {
  header div.nav {
    -webkit-transform: translateX(264px);
    -moz-transform: translateX(264px);
    -ms-transform: translateX(264px);
    transform: translateX(264px);
    width: 264px;
  }
}
header div.nav nav.main {
  text-align: right;
  margin-top: 82px;
  width: auto;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main {
    margin: 0;
  }
}
header div.nav nav.main ul {
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul {
    display: block;
    margin-top: 22px;
  }
}
header div.nav nav.main ul li {
  margin: 0 0 0 -4px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li {
    margin: 0;
    width: 100%;
  }
}
header div.nav nav.main ul li a {
  border: 1px solid transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  color: #532078;
  display: inline-block;
  font-family: "latobold";
  line-height: 24px;
  padding: 11px 41px 14px 39px;
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li a {
    padding: 13px 25px 16px 42px;
    width: 100%;
  }
}
header div.nav nav.main ul li a:before {
  background: #D8D8D8;
  content: "";
  display: block;
  height: 41px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 1px;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li a:before {
    display: none;
  }
}
header div.nav nav.main ul li a span {
  display: block;
}
header div.nav nav.main ul li a:last-child:after {
  background: #D8D8D8;
  content: "";
  display: block;
  height: 41px;
  right: 0;
  position: absolute;
  top: 5px;
  width: 1px;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li a:last-child:after {
    display: none;
  }
}
header div.nav nav.main ul li a:hover {
  border: 1px solid #D8D8D8;
  color: #C343B6;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li a:hover {
    border: 1px solid transparent;
  }
}
header div.nav nav.main ul li.active a {
  border: 1px solid #D8D8D8;
  color: #C343B6;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul li.active a {
    border: 1px solid transparent;
  }
}
header div.nav nav.meta {
  position: absolute;
  right: 10px;
  top: 16px;
  width: auto;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.meta {
    position: relative;
    right: auto;
    top: auto;
  }
}
@media screen and (max-width: 1000px) {
  header div.nav nav.meta ul {
    padding: 16px 20px 10px 43px;
  }
}
header div.nav nav.meta ul li {
  margin-right: 25px;
}
@media screen and (max-width: 1100px) {
  header div.nav nav.meta ul li {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1000px) {
  header div.nav nav.meta ul li {
    display: block;
    margin: 17px 0 0;
    width: 100%;
  }
}
header div.nav nav.meta ul li a {
  color: #999799;
  font-size: 15px;
  text-decoration: none;
}
header div.nav nav.meta ul li a.locations {
  color: #D54D12;
}
header div.nav nav.meta ul li a:hover {
  text-decoration: underline;
}
header div.nav nav.meta ul li.active a {
  color: #504D4C;
  font-family: "latobold";
}
header div.nav nav.meta ul li.active a.locations {
  color: #D54D12;
}
header div.nav nav.meta ul li.readMe {
  margin: 0 0 0 10px;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.meta ul li.readMe {
    display: none;
  }
}
header div.nav nav.meta ul li.readMe a:before {
  content: "\e801";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 12px;
  margin-right: 5px;
}
header div.nav nav.meta ul li.dossier {
  background-color: #4a266d;
  transition: background-color 400ms ease-in-out;
  margin-top: -20px;
  border-radius: 0 0 5px 5px;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.meta ul li.dossier {
    margin-top: 20px;
  }
}
header div.nav nav.meta ul li.dossier a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 17px 22px 14px 15px;
  transform: translateY(3px);
}
header div.nav nav.meta ul li.dossier a svg {
  width: 24px;
  height: 20px;
  margin-right: 7px;
}
header div.nav nav.meta ul li.dossier:hover {
  background-color: #532078;
}
header div.nav nav.main,
header div.nav nav.meta {
  display: block;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main,
  header div.nav nav.meta {
    position: relative;
  }
}
header div.nav nav.main ul,
header div.nav nav.meta ul {
  display: block;
  text-align: right;
}
@media screen and (max-width: 1000px) {
  header div.nav nav.main ul,
  header div.nav nav.meta ul {
    text-align: left;
  }
}
header div.nav nav.main ul li,
header div.nav nav.meta ul li {
  display: inline-block;
}
header div.nav div.telHolder {
  display: none;
}
@media screen and (max-width: 1000px) {
  header div.nav div.telHolder {
    display: block;
    float: left;
    font-size: 14px;
    line-height: 18px;
    margin: 26px 0 0;
    padding: 0 30px 0 43px;
    width: 100%;
    position: relative;
    z-index: 1;
  }
}
header div.nav div.telHolder div.telNum {
  margin: 10px 0 15px;
  padding-bottom: 26px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  header div.nav div.telHolder div.telNum:before {
    background: #999799;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
header div.nav div.telHolder div.telNum span.title {
  color: #999799;
  display: block;
  font-family: "latoregular";
  margin: -3px 0 18px;
}
header div.nav div.telHolder div.telNum p,
header div.nav div.telHolder div.telNum a,
header div.nav div.telHolder div.telNum a:hover {
  color: #504D4C;
  font-family: "latobold";
  font-size: 18px;
}
header div.nav div.telHolder div.address {
  line-height: 24px;
  margin: 0 0 18px;
  padding-top: 26px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  header div.nav div.telHolder div.address:before {
    background: #999799;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
header div.nav div.telHolder div.address p {
  color: #4D4C4D;
  margin-bottom: 24px;
}
header div.nav div.telHolder div.address p a {
  color: #532078;
  text-decoration: none;
}
header div.nav div.telHolder div.address p a:hover {
  text-decoration: underline;
}
header div.hamburgerSearch {
  float: right;
}
@media screen and (max-width: 1000px) {
  header div.hamburgerSearch {
    background: #ffffff;
    margin-right: -10px;
    padding-right: 10px;
    padding: 20px 10px 12px 0;
    position: relative;
    -webkit-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -moz-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -o-transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    z-index: 10;
  }
}
header div.search {
  height: 48px;
  float: right;
  margin: 80px 0 0 -1px;
  position: relative;
  width: 50px;
  z-index: 1;
}
@media screen and (max-width: 1000px) {
  header div.search {
    margin-top: 1px;
  }
}
@media screen and (max-width: 750px) {
  header div.search {
    margin-top: 3px;
  }
}
header div.search div.searchForm {
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #532078;
  display: none;
  float: right;
  line-height: 24px;
  padding: 10px 27px 12px 8px;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
header div.search div.searchForm.js {
  display: inline-block;
}
header div.search div.searchForm.js + a {
  display: none;
}
header div.search div.searchForm:before {
  color: #999999;
  content: "\e804";
  cursor: pointer;
  font-family: "helioIcon";
  font-size: 20px;
  left: 12px;
  position: absolute;
  top: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header div.search div.searchForm:hover:before {
  color: #532078;
}
header div.search div.searchForm form input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  color: #8A310B;
  font-family: "latoregular";
  font-size: 18px;
  line-height: 21px;
  min-width: 0;
  max-width: 0;
  padding-left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
header div.search div.searchForm form input[type=search]:focus {
  outline: none;
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0;
}
header div.search div.searchForm form ::-webkit-input-placeholder {
  color: #999799;
  font-family: "latoregular";
  font-size: 18px;
}
header div.search div.searchForm form :-moz-placeholder {
  color: #999799;
  font-family: "latoregular";
  font-size: 18px;
}
header div.search div.searchForm form ::-moz-placeholder {
  color: #999799;
  font-family: "latoregular";
  font-size: 18px;
}
header div.search div.searchForm form :-ms-input-placeholder {
  color: #999799;
  font-family: "latoregular";
  font-size: 18px;
}
header div.search div.searchForm form button {
  position: absolute;
  top: -2000px;
}
header div.search div.searchForm div.closeBox {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header div.search div.searchForm div.closeBox:before {
  color: #999999;
  content: "\e808";
  cursor: pointer;
  display: inline-block;
  font-family: "helioIcon";
  font-size: 14px;
}
header div.search div.searchForm.open {
  background: #F2EBE6;
  border: 1px solid #BFBAB6;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
}
header div.search div.searchForm.open:after {
  background: #ffffff;
  content: "";
  display: block;
  height: 100px;
  position: absolute;
  top: -20px;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 1000px) {
  header div.search div.searchForm.open:after {
    display: none;
  }
}
header div.search div.searchForm.open form input[type=search] {
  min-width: 630px;
  max-width: 630px;
  padding-left: 39px;
  padding-right: 19px;
}
@media screen and (max-width: 950px) {
  header div.search div.searchForm.open form input[type=search] {
    min-width: 430px;
    max-width: 430px;
  }
}
@media screen and (max-width: 750px) {
  header div.search div.searchForm.open form input[type=search] {
    max-width: 215px;
    min-width: 215px;
  }
}
@media screen and (max-width: 479px) {
  header div.search div.searchForm.open form input[type=search] {
    max-width: 160px;
    min-width: 160px;
  }
}
header div.search div.searchForm.open div.closeBox {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}
header div.search div.searchForm.open:hover:before {
  color: #999999;
}
header div.search a {
  color: #532078;
  cursor: pointer;
  display: none;
  float: right;
  line-height: 24px;
  margin: 69px 0 0 -1px;
  padding: 23px 18px 26px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
header div.search a:after {
  color: #999999;
  content: "\e804";
  font-family: "helioIcon";
  font-size: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header div.openMenu {
  display: none;
}
@media screen and (max-width: 1000px) {
  header div.openMenu {
    color: #C343B6;
    cursor: pointer;
    display: block;
    float: right;
    font-family: "latobold";
    font-size: 13px;
    margin: 8px 0 0;
    padding: 10px 0 10px 17px;
    text-transform: uppercase;
  }
}
header div.openMenu:after {
  color: #532078;
  content: "\e805";
  font-family: "helioIcon";
  font-size: 17px;
  margin-left: 10px;
}
header .slideOutMenu {
  background: #F2EFEC;
  /* border-top: 1px solid $mainMenuBorder; */
  -webkit-box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.5);
  max-height: 0;
  overflow: hidden;
  position: absolute;
  margin-top: 1px;
  -webkit-transition: max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  width: 100%;
  z-index: 1000;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu {
    bottom: 0;
    height: 100%;
    max-height: auto;
    min-height: 100%;
    overflow: auto;
    position: fixed;
    right: -304px;
    top: 0;
    -webkit-transform: translateX(304px);
    -moz-transform: translateX(304px);
    -ms-transform: translateX(304px);
    transform: translateX(304px);
    -webkit-transition: right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -moz-transition: right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -o-transition: right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    transition: right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    width: 304px;
  }
}
@media screen and (max-width: 360px) {
  header .slideOutMenu {
    right: -264px;
    -webkit-transform: translateX(264px);
    -moz-transform: translateX(264px);
    -ms-transform: translateX(264px);
    transform: translateX(264px);
    width: 264px;
  }
}
header .slideOutMenu div.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1220px;
  padding: 54px 20px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper {
    padding: 36px 30px;
  }
}
header .slideOutMenu div.wrapper:after, header .slideOutMenu div.wrapper:before {
  content: "";
  display: table;
}
header .slideOutMenu div.wrapper:after {
  clear: both;
}
header .slideOutMenu div.wrapper div.actions {
  float: left;
  width: 66.1016949153%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions {
    width: 100%;
  }
}
header .slideOutMenu div.wrapper div.actions > span.mobileTitle {
  color: #C343B6;
  cursor: pointer;
  display: none;
  font-family: "latoblack";
  line-height: 24px;
  margin-bottom: 15px;
  max-width: 190px;
  padding: 0 41px 14px 30px;
  position: relative;
  text-align: left;
  text-decoration: none;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions > span.mobileTitle {
    display: inline-block;
  }
}
header .slideOutMenu div.wrapper div.actions > span.mobileTitle:before {
  color: #999799;
  content: "\e806";
  display: block;
  font-family: "helioIcon";
  font-size: 11px;
  left: 0;
  position: absolute;
  top: 12px;
}
header .slideOutMenu div.wrapper div.actions > span.title {
  color: #999799;
  display: block;
  font-size: 13px;
  font-family: "latobold";
  margin-top: 1px;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions > span.title {
    display: none;
  }
}
header .slideOutMenu div.wrapper div.actions .colLeft {
  margin-right: 6%;
}
header .slideOutMenu div.wrapper div.actions .colLeft,
header .slideOutMenu div.wrapper div.actions .colRight {
  float: left;
  width: 47%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions .colLeft,
  header .slideOutMenu div.wrapper div.actions .colRight {
    float: none;
    margin-right: 0;
    width: 100%;
  }
}
header .slideOutMenu div.wrapper div.actions .colLeft span.title,
header .slideOutMenu div.wrapper div.actions .colRight span.title {
  color: #504D4C;
  display: block;
  font-size: 15px;
  line-height: 21px;
  margin: 21px 0 29px;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul,
header .slideOutMenu div.wrapper div.actions .colRight ul {
  line-height: 18px;
  list-style: none;
  margin: 19px 0 29px;
  padding: 0;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul li,
header .slideOutMenu div.wrapper div.actions .colRight ul li {
  margin-top: 3px;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul li:first-child,
header .slideOutMenu div.wrapper div.actions .colRight ul li:first-child {
  margin-top: 4px;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul li a,
header .slideOutMenu div.wrapper div.actions .colRight ul li a {
  color: #D54D12;
  display: block;
  font-size: 15px;
  line-height: 21px;
  position: relative;
  padding: 0 0 0 19px;
  text-decoration: none;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul li a:before,
header .slideOutMenu div.wrapper div.actions .colRight ul li a:before {
  color: rgba(213, 77, 18, 0.5);
  content: "\e802";
  font-family: "helioIcon";
  font-size: 7px;
  left: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header .slideOutMenu div.wrapper div.actions .colLeft ul li a:hover:before,
header .slideOutMenu div.wrapper div.actions .colRight ul li a:hover:before {
  left: 3px;
}
header .slideOutMenu div.wrapper div.actions .colLeft a.moreLink,
header .slideOutMenu div.wrapper div.actions .colRight a.moreLink {
  font-family: "latobold";
  font-size: 15px;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder {
  margin-top: 20px;
  max-width: 325px;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder:after, header .slideOutMenu div.wrapper div.actions div.themesHolder:before {
  content: "";
  display: table;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder:after {
  clear: both;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a {
  border: 2px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: left;
  font-family: "latobold";
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
  margin-left: 6%;
  min-height: 65px;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 47%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions div.themesHolder > a {
    margin-left: 0;
    width: 100%;
  }
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a:nth-child(odd) {
  margin-left: 0;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a > span {
  display: inline-block;
  left: 0;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a:first-child {
  margin-left: 0;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a:hover {
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.blue {
  border-color: rgba(1, 124, 203, 0.35);
  color: #017CCB;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.blue:hover {
  background: #017CCB;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.orange {
  border-color: rgba(241, 150, 0, 0.35);
  color: #F19600;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.orange:hover {
  background: #F19600;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.purple {
  border-color: rgba(83, 32, 120, 0.35);
  color: #532078;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.purple:hover {
  background: #532078;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.red {
  border-color: rgba(213, 77, 18, 0.35);
  color: #E03501;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.red:hover {
  background: #E03501;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.rose {
  border-color: rgba(195, 67, 182, 0.35);
  color: #C343B6;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.rose:hover {
  background: #C343B6;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.green {
  border-color: rgba(142, 189, 2, 0.35);
  color: #8EBD02;
}
header .slideOutMenu div.wrapper div.actions div.themesHolder > a.green:hover {
  background: #8EBD02;
  border-color: transparent;
  color: #ffffff;
}
header .slideOutMenu div.wrapper div.actions div.persons {
  margin: 13px 0 15px;
}
header .slideOutMenu div.wrapper div.actions div.persons:after, header .slideOutMenu div.wrapper div.actions div.persons:before {
  content: "";
  display: table;
}
header .slideOutMenu div.wrapper div.actions div.persons:after {
  clear: both;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person {
  border-bottom: 1px solid #E5DFDA;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;
  margin-left: 6%;
  max-width: 332px;
  padding: 20px 0 27px;
  width: 47%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.actions div.persons a.person {
    margin-left: 0;
    width: 100%;
  }
}
header .slideOutMenu div.wrapper div.actions div.persons a.person:nth-child(odd) {
  margin-left: 0;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person:last-child {
  border-bottom: none;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person span.img {
  display: block;
  float: left;
  padding-top: 4px;
  width: 19%;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person span.img img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  max-width: 100%;
  vertical-align: top;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person span.personText {
  display: block;
  float: left;
  margin-left: 6%;
  width: 75%;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person span.personText span.name {
  color: #504D4C;
  display: block;
  font-family: "latoblack";
  font-size: 16px;
  margin-bottom: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person span.personText span.text {
  color: #504D4C;
  font-size: 15px;
  line-height: 21px;
  width: 100%;
}
header .slideOutMenu div.wrapper div.actions div.persons a.person:hover span.personText span.name {
  color: #532078;
}
header .slideOutMenu div.wrapper div.textHolder {
  float: left;
  margin-left: 1.6949152542%;
  width: 32.2033898305%;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu div.wrapper div.textHolder {
    display: none;
  }
}
header .slideOutMenu div.wrapper div.textHolder > span.title {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 35px;
}
header .slideOutMenu div.wrapper div.textHolder > p {
  color: #504D4C;
  font-size: 18px;
  line-height: 30px;
}
header .slideOutMenu.open {
  max-height: 900px;
}
@media screen and (max-width: 1000px) {
  header .slideOutMenu.open {
    right: 0;
  }
}
header.fixedBreadcrumb {
  margin-bottom: 65px;
}
@media screen and (max-width: 767px) {
  header.fixedBreadcrumb {
    margin-bottom: 43px;
  }
}
header.fixedBreadcrumb + section.breadcrumb {
  left: 0;
  position: fixed;
  top: 5px;
  width: 100%;
  z-index: 999;
}
header.fixedBreadcrumb + section.breadcrumb div.container div.controls {
  margin-right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  pointer-events: all;
}
header.fixedBreadcrumb + section.breadcrumb + .topTasks.four.slideOut {
  left: 0;
  pointer-events: all;
  position: fixed;
  top: 69px;
}
@media screen and (max-width: 767px) {
  header.fixedBreadcrumb + section.breadcrumb + .topTasks.four.slideOut {
    top: 48px;
  }
}

section.breadcrumb {
  background: #F2EFEC;
  padding: 20px 0 23px;
}
@media screen and (max-width: 767px) {
  section.breadcrumb {
    padding: 10px 0 12px;
  }
}
section.breadcrumb div.container {
  max-width: 1460px;
}
section.breadcrumb div.container ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container ul {
    display: none;
  }
}
section.breadcrumb div.container ul li {
  display: inline-block;
  font-size: 15px;
  padding: 0 24px 0 0;
  position: relative;
}
section.breadcrumb div.container ul li:after {
  color: #C7BBD2;
  content: "\e802";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 7px;
  position: absolute;
  right: 8px;
  top: 5px;
  vertical-align: top;
}
section.breadcrumb div.container ul li a {
  color: #532078;
  display: block;
  text-decoration: none;
  vertical-align: top;
}
section.breadcrumb div.container ul li a:hover {
  text-decoration: underline;
}
section.breadcrumb div.container ul li a.home {
  color: #999799;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
section.breadcrumb div.container ul li a.home:before {
  content: "\e800";
  font-family: "helioIcon";
  font-size: 15px;
  margin-right: 6px;
  vertical-align: top;
}
section.breadcrumb div.container ul li a.home:hover {
  color: #532078;
  text-decoration: none;
}
section.breadcrumb div.container ul li span {
  color: #636263;
}
section.breadcrumb div.container ul li:last-child:after {
  display: none;
}
section.breadcrumb div.container a.backLink {
  display: none;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container a.backLink {
    color: #504D4C;
    display: inline;
    font-size: 15px;
    text-decoration: none;
  }
}
section.breadcrumb div.container a.backLink:before {
  color: #999799;
  content: "\e806";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 11px;
  margin: 5px 19px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container a.backLink:before {
    line-height: 16px;
  }
}
section.breadcrumb div.container div.controls {
  float: right;
  margin-right: -10px;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  pointer-events: none;
  position: relative;
  text-align: right;
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  width: 165px;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container div.controls {
    margin: -2px 0 0;
    top: 4px;
  }
}
section.breadcrumb div.container div.controls span {
  color: #E03501;
  cursor: pointer;
  display: inline-block;
  font-family: "latobold";
  font-size: 17px;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  padding: 0 0 0 15px;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container div.controls span {
    font-size: 15px;
  }
}
section.breadcrumb div.container div.controls span:hover:before {
  left: 4px;
}
section.breadcrumb div.container div.controls span:before {
  color: rgba(213, 77, 18, 0.5);
  content: "\e802";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 11px;
  left: 0;
  position: absolute;
  top: 5px;
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container div.controls span:before {
    top: 4px;
  }
}
section.breadcrumb div.container div.controls span.close {
  color: rgba(120, 76, 151, 0.5);
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  padding: 0 26px 0 0;
}
section.breadcrumb div.container div.controls span.close:before {
  border-bottom: 7px solid #9A49A0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  bottom: -25px;
  content: "";
  left: auto;
  right: 2px;
  position: absolute;
}
@media screen and (max-width: 767px) {
  section.breadcrumb div.container div.controls span.close:before {
    bottom: -14px;
  }
}
section.breadcrumb div.container div.controls span.close:hover {
  color: rgb(100, 54, 133);
}
section.breadcrumb div.container div.controls span.close:after {
  color: rgb(100, 54, 133);
  content: "\e808";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 14px;
  right: 0;
  position: absolute;
  top: 3px;
  vertical-align: top;
}
section.breadcrumb div.container div.controls.opened {
  margin-right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  pointer-events: all;
}
section.breadcrumb div.container div.controls.opened span.open {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
}
section.breadcrumb div.container div.controls.opened span.close {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}

div.backTopHolder {
  position: relative;
}
div.backTopHolder div.backToTop {
  background-color: #D54D12;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  bottom: 30px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  -moz-box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  box-shadow: 0px 1px 3px 0px rgba(138, 49, 11, 0.24);
  box-sizing: border-box;
  height: 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  padding: 7px;
  pointer-events: none;
  position: fixed;
  right: 30px;
  text-align: center;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 40px;
  z-index: 999999;
}
@media screen and (max-width: 1000px) {
  div.backTopHolder div.backToTop {
    bottom: 10px;
    height: 20px;
    line-height: 12px;
    padding: 0;
    right: 10px;
    width: 20px;
  }
}
div.backTopHolder div.backToTop:after {
  content: "\e803";
  color: #ffffff;
  display: inline;
  font-family: "helioIcon";
  font-size: 10px;
}
@media screen and (max-width: 1000px) {
  div.backTopHolder div.backToTop:after {
    font-size: 7px;
    line-height: 12px;
  }
}
div.backTopHolder div.backToTop.show {
  cursor: pointer;
  opacity: 0.8;
  filter: alpha(opacity=80);
  pointer-events: all;
  pointer-events: all;
}
div.backTopHolder div.backToTop.stopped {
  bottom: 30px;
  position: absolute;
}
@media screen and (max-width: 1000px) {
  div.backTopHolder div.backToTop.stopped {
    bottom: 10px;
  }
}
div.backTopHolder div.backToTop:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}

section.prefooter {
  background: #441A63;
  padding: 59px 0 64px;
}
@media screen and (max-width: 767px) {
  section.prefooter {
    padding: 20px 0 30px;
  }
}
@media screen and (max-width: 767px) {
  section.prefooter .col6:first-child {
    margin-bottom: 30px;
  }
}
section.prefooter p {
  color: #DDD2E4;
}
section.prefooter span.title {
  color: #F3F0F6;
  font-family: "latolight";
  font-size: 22px;
  line-height: 35px;
}
section.prefooter span.title p {
  font-size: 22px;
  margin: 0;
  font-family: "latolight";
  color: #F3F0F6;
}
section.prefooter div.resultHighlight {
  background: transparent;
  border: none;
  margin-top: 34px;
  padding: 0 18.6440677966% 0 0;
}
section.prefooter div.resultHighlight:after, section.prefooter div.resultHighlight:before {
  content: "";
  display: table;
}
section.prefooter div.resultHighlight:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  section.prefooter div.resultHighlight {
    margin-top: 14px;
    padding-right: 0;
  }
}
section.prefooter div.resultHighlight p {
  color: #DDD2E4;
}
section.prefooter div.resultHighlight div.barGraphs div.chartlineHolder {
  background: rgba(255, 255, 255, 0.1);
}
section.prefooter div.resultHighlight div.barGraphs div.chartlineHolder div.chartline {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
section.prefooter div.resultHighlight div.legend span {
  color: #B6A4C4;
}
section.prefooter div.resultHighlight div.chartTitle {
  color: #9B84AB;
}
section.prefooter div.resultHighlight div.chartTitle + .barGraphs {
  margin-top: 9px;
}
section.prefooter .moreLink {
  color: #FF57EE;
  display: inline-block;
  font-size: 16px;
  margin-top: 19px;
}
section.prefooter .moreLink:after {
  color: rgba(255, 87, 238, 0.5);
  line-height: 22px;
}
section.prefooter div.ambassador {
  margin: 43px 0 0;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador {
    margin-top: 23px;
  }
}
section.prefooter div.ambassador blockquote {
  color: #ffffff;
  font-family: "latobold_italic";
  font-size: 40px;
  line-height: 49px;
  margin: 0 0 0 18.1%;
  position: relative;
  padding: 0 0 0 16.5%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador blockquote {
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 20px 0;
  }
}
section.prefooter div.ambassador blockquote:before {
  background: #ffffff;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  opacity: 0.2;
  filter: alpha(opacity=20);
  pointer-events: all;
  position: absolute;
  top: 29px;
  width: 13.5%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador blockquote:before {
    top: 17px;
  }
}
section.prefooter div.ambassador div.person {
  left: 0;
  position: absolute;
  top: 0;
  width: 32.2033898305%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador div.person {
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  section.prefooter div.ambassador div.person:after, section.prefooter div.ambassador div.person:before {
    content: "";
    display: table;
  }
  section.prefooter div.ambassador div.person:after {
    clear: both;
  }
}
section.prefooter div.ambassador div.person div.image {
  margin-top: 7px;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador div.person div.image {
    float: left;
    width: 23.7288135593%;
  }
}
section.prefooter div.ambassador div.person div.image img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  vertical-align: top;
  width: 43%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador div.person div.image img {
    max-width: 80px;
    width: 100%;
  }
}
section.prefooter div.ambassador div.person span.name {
  color: #F3F0F6;
  display: block;
  font-family: "latobold";
  font-size: 15px;
  line-height: 24px;
  margin: 20px 0 2px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador div.person span.name {
    float: left;
    margin-top: 0;
    margin-left: 1.6949152542%;
    width: 74.5762711864%;
  }
}
section.prefooter div.ambassador div.person span.function {
  color: #F3F0F6;
  display: block;
  font-size: 15px;
  line-height: 24px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador div.person span.function {
    float: left;
    margin-left: 1.6949152542%;
    width: 74.5762711864%;
  }
}
section.prefooter div.ambassador p {
  color: #DDD2E4;
  font-size: 18px;
  line-height: 30px;
  margin: 32px 0 0 34.6%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador p {
    margin: 16px 0 0 0;
  }
}
section.prefooter div.ambassador + .moreLink {
  margin-left: 34.6%;
}
@media screen and (max-width: 767px) {
  section.prefooter div.ambassador + .moreLink {
    margin-left: 0;
  }
}
section.prefooter div.content {
  margin-top: 36px;
}
section.prefooter div.content div.images:after, section.prefooter div.content div.images:before {
  content: "";
  display: table;
}
section.prefooter div.content div.images:after {
  clear: both;
}
section.prefooter div.content div.images > img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: left;
  margin: 0 0 21px 3.5%;
  width: 31%;
}
section.prefooter div.content div.images > img:first-child {
  margin-left: 0;
}
section.prefooter div.content div.images > img:nth-child(3n+4) {
  margin-left: 0;
}
section.prefooter div.content div.images > a {
  float: left;
  margin: 0 0 21px 3.5%;
  width: 31%;
}
section.prefooter div.content div.images > a:first-child {
  margin-left: 0;
}
section.prefooter div.content div.images > a:nth-child(3n+4) {
  margin-left: 0;
}
section.prefooter div.content div.images > a > img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  max-width: 100%;
  vertical-align: top;
}
section.prefooter div.content p {
  font-size: 15px;
  line-height: 24px;
  margin: 15px 0 12px;
}

footer {
  background: #F2EFEC;
  border-top: 1px solid #E1E8ED;
  font-size: 14px;
  padding: 50px 0 109px;
}
@media screen and (max-width: 700px) {
  footer {
    padding: 30px 0 39px;
  }
}
footer div.container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@media screen and (max-width: 1000px) {
  footer div.container {
    display: block;
  }
}
footer div.logoHolder {
  float: left;
  order: 1;
  position: relative;
  width: 15.2542372881%;
}
@media screen and (max-width: 1000px) {
  footer div.logoHolder {
    float: left;
    max-width: 151px;
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  footer div.logoHolder {
    max-width: 100%;
  }
}
footer div.logoHolder a.logo {
  margin: 0;
}
@media screen and (max-width: 1000px) {
  footer div.logoHolder a.logo {
    max-width: 151px;
  }
}
@media screen and (max-width: 1000px) {
  footer div.logoHolder a.logo svg {
    max-width: 151px;
  }
}
footer div.logoHolder div.socials {
  bottom: 26px;
  position: absolute;
  right: 29px;
  text-align: right;
}
@media screen and (max-width: 1000px) {
  footer div.logoHolder div.socials {
    bottom: auto;
    margin-top: 20px;
    position: relative;
    right: auto;
    text-align: left;
  }
}
footer div.logoHolder div.socials > a {
  display: inline-block;
  margin-left: 5px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
@media screen and (max-width: 1000px) {
  footer div.logoHolder div.socials > a {
    margin-left: 0;
    margin-right: 5px;
  }
}
footer div.logoHolder div.socials > a:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
  pointer-events: all;
}
footer ul.doormat {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin-top: 26px;
  order: 2;
  padding: 0;
  width: 66.1016949153%;
}
@media screen and (max-width: 1000px) {
  footer ul.doormat {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  footer ul.doormat {
    display: none;
  }
}
footer ul.doormat li:first-child {
  margin-left: 0;
}
footer ul.doormat > li {
  border-left: 1px solid rgba(153, 151, 153, 0.25);
  display: block;
  line-height: 18px;
  padding: 0 5px 0 18px;
  width: 23.7288135593%;
}
footer ul.doormat > li span {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  margin: -3px 0 33px;
}
footer ul.doormat > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer ul.doormat > li ul li {
  margin: 6px 0 0;
}
footer ul.doormat > li ul li a {
  color: #4D4C4D;
  text-decoration: none;
}
footer ul.doormat > li ul li a:hover {
  text-decoration: underline;
}
footer div.telHolder {
  border-left: 1px solid rgba(153, 151, 153, 0.25);
  float: right;
  line-height: 18px;
  margin: 26px 0 0 1.6949152542%;
  order: 3;
  padding: 0 0 0 18px;
  width: 15.2542372881%;
}
@media screen and (max-width: 1000px) {
  footer div.telHolder {
    margin-bottom: 73px;
    width: 49.1525423729%;
  }
}
@media screen and (max-width: 700px) {
  footer div.telHolder {
    border-left: none;
    margin: 26px 0 0;
    padding: 0;
    width: 100%;
  }
}
footer div.telHolder div.telNum {
  margin: 0 0 18px;
}
@media screen and (max-width: 1000px) {
  footer div.telHolder div.telNum {
    float: left;
    width: 50%;
  }
}
@media screen and (max-width: 360px) {
  footer div.telHolder div.telNum {
    float: none;
    width: 100%;
  }
}
footer div.telHolder div.telNum span.title {
  color: #999799;
  display: block;
  font-family: "latoregular";
  margin: -3px 0 18px;
}
@media screen and (max-width: 1000px) {
  footer div.telHolder div.telNum span.title {
    margin: -3px 0 13px;
  }
}
@media screen and (max-width: 700px) {
  footer div.telHolder div.telNum span.title {
    margin-left: 0;
  }
}
footer div.telHolder div.telNum p,
footer div.telHolder div.telNum a,
footer div.telHolder div.telNum a:hover {
  color: #504D4C;
  font-family: "latobold";
  font-size: 18px;
}
footer div.telHolder div.address {
  line-height: 24px;
}
@media screen and (max-width: 1000px) {
  footer div.telHolder div.address {
    float: right;
    margin: -6px 0 0;
    padding: 0 5px 0 16px;
    width: 50%;
  }
}
@media screen and (max-width: 360px) {
  footer div.telHolder div.address {
    float: none;
    margin: 10px 0 0;
    padding: 0;
    width: 100%;
  }
}
footer div.telHolder div.address p {
  color: #4D4C4D;
  margin-bottom: 24px;
}
footer div.telHolder div.address p a {
  color: #532078;
  text-decoration: none;
}
footer div.telHolder div.address p a:hover {
  text-decoration: underline;
}

section.content {
  line-height: 29px;
  padding: 30px 0 40px;
}
@media screen and (max-width: 1000px) {
  section.content {
    padding: 30px 0 18px;
  }
  section.content > .container > .row {
    margin: 0;
  }
}
section.content div.hero.hasImage {
  position: relative;
  margin: 30px 0 50px;
}
section.content div.hero.hasImage h1 {
  position: absolute;
  bottom: 40px;
  color: white;
  z-index: 1;
}
section.content div.hero.hasImage img {
  margin: 0;
}
section.content div.hero.hasImage:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(rgba(83, 32, 120, 0.15) 50%, rgb(42, 16, 60));
  top: 0;
}
@media screen and (max-width: 1000px) {
  section.content div.hero.hasImage {
    margin: 30px -20px 50px;
  }
}
section.content div.submenu {
  background: #F2EFEC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: right;
  line-height: 24px;
  margin-left: 1.6949152542%;
  padding: 29px 30px 17px;
}
@media screen and (max-width: 1000px) {
  section.content div.submenu {
    background: #ffffff;
    border: 1px solid #E6DFDA;
    float: left;
    margin: 0 0 40px;
    max-width: 340px;
    padding: 0;
    width: 100%;
  }
}
section.content div.submenu > span {
  color: #4D4D4D;
  display: block;
  font-family: "latobold";
  margin: 0 0 17px;
}
@media screen and (max-width: 1000px) {
  section.content div.submenu > span {
    display: none;
  }
}
section.content div.submenu > span.openSub {
  display: none;
}
@media screen and (max-width: 1000px) {
  section.content div.submenu > span.openSub {
    color: #D54D12;
    cursor: pointer;
    display: block;
    font-family: "latoregular";
    font-size: 16px;
    margin: 0;
    padding: 12px 23px 12px 15px;
  }
}
@media screen and (max-width: 1000px) {
  section.content div.submenu > span.openSub:after {
    color: #EAA688;
    content: "\e807";
    display: inline-block;
    float: right;
    font-family: "helioIcon";
    font-size: 8px;
    margin: 2px 0 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
section.content div.submenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1000px) {
  section.content div.submenu ul {
    font-size: 15px;
    margin: 0 16px;
    max-height: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    pointer-events: none;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
section.content div.submenu ul li {
  padding-bottom: 14px;
}
section.content div.submenu ul li a {
  color: #D54D12;
  display: inline-block;
  font-family: "latobold";
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
section.content div.submenu ul li a.active {
  color: #8A310B;
  padding-left: 19px;
  position: relative;
}
section.content div.submenu ul li a.active:before {
  color: #EAA688;
  content: "\e802";
  display: block;
  font-family: "helioIcon";
  font-size: 6px;
  left: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
section.content div.submenu ul li a:hover {
  color: #8A310B;
}
section.content div.submenu.showSub > span.openSub:after {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media screen and (max-width: 1000px) {
  section.content div.submenu.showSub ul {
    max-height: 600px;
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: all;
  }
}
section.content div.col8 {
  margin-left: 0;
}
@media screen and (max-width: 1000px) {
  section.content div.col8 {
    float: left;
    width: 100%;
  }
}
section.content h1 + img {
  margin: 33px 0 42px;
}
@media screen and (max-width: 767px) {
  section.content h1 + img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    margin-left: -20px;
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  section.content h1 + img {
    margin: 10px 0 10px -20px;
  }
}
section.content > div > div > img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
section.content .intro {
  display: block;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 51px;
}
@media screen and (max-width: 767px) {
  section.content .intro {
    font-size: 18px;
    line-height: 29px;
  }
}
section.content div.umb-grid h2 {
  margin: 45px 0 9px;
}
section.content div.umb-grid p + h3 {
  margin-top: 44px;
}
section.content div.umb-grid section.blCTA {
  background: #F2EFEC;
  border-radius: 3px;
  padding: 30px;
}
section.content div.umb-grid section.blCTA h2 {
  margin: 0;
}
@media screen and (min-width: 767px) {
  section.content div.umb-grid section.blCTA {
    padding: 30px 100px;
  }
}
section.content div.umb-grid blockquote {
  color: #8EBD02;
  font-family: "latoitalic";
  font-size: 24px;
  line-height: 38px;
  margin: 45px 0 37px;
  position: relative;
  padding: 0 12.5%;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid blockquote {
    margin: 35px 0 17px;
    padding: 20px 0 0;
  }
}
section.content div.umb-grid blockquote:before {
  background: #C7DF81;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 9.8%;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid blockquote:before {
    top: 0;
    width: 74px;
  }
}
section.content div.umb-grid blockquote + p span.quotedPerson {
  color: #999799;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  margin: -20px 12.5% 43px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid blockquote + p span.quotedPerson {
    margin: 0;
  }
}
section.content div.umb-grid ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
section.content div.umb-grid ul li {
  margin: 0 0 6px;
  padding-left: 30px;
  position: relative;
}
section.content div.umb-grid ul li:before {
  color: #EAA688;
  content: "•";
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  vertical-align: top;
}
section.content div.umb-grid ul li a {
  color: #D54D12;
  text-decoration: none;
}
section.content div.umb-grid ul li a:hover {
  text-decoration: underline;
}
section.content div.umb-grid ul li p {
  margin: 0;
}
section.content div.umb-grid ol {
  counter-reset: number;
  list-style: none;
  margin: 11px 0 40px;
  padding: 0;
}
section.content div.umb-grid ol li {
  margin: 0 0 6px;
  padding-left: 30px;
  position: relative;
}
section.content div.umb-grid ol li:before {
  counter-increment: number;
  content: counter(number) ".";
  color: #EAA688;
  font-size: 18px;
  left: 0;
  position: absolute;
  top: 0;
  vertical-align: top;
}
section.content div.umb-grid ol li a {
  color: #D54D12;
  text-decoration: none;
}
section.content div.umb-grid ol li a:hover {
  text-decoration: underline;
}
section.content div.umb-grid .iframeRatio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
}
section.content div.umb-grid .iframeRatio iframe.autoScaling {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
section.content div.umb-grid .intro {
  display: block;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 51px;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid .intro {
    font-size: 18px;
    line-height: 29px;
  }
}
section.content div.umb-grid .sup {
  display: block;
  font-size: 15px;
  line-height: 24px;
  margin: 48px 0 0;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid .sup {
    font-size: 13px;
    line-height: 22px;
    margin: 38px 0 0;
  }
}
section.content div.umb-grid h1 + .umb-grid p + img {
  margin-top: 0;
}
section.content div.umb-grid img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 38px;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    margin-left: -20px;
    max-width: calc(100% + 40px);
    width: calc(100% + 40px);
  }
}
section.content div.umb-grid .video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 38px;
}
section.content div.umb-grid .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
section.content div.umb-grid p {
  margin-bottom: 26px;
}
@media screen and (max-width: 767px) {
  section.content div.umb-grid p {
    font-size: 15px;
    line-height: 24px;
  }
}
section.content div.umb-grid p > a,
section.content div.umb-grid p > span > a {
  color: #D54D12;
  text-decoration: none;
}
section.content div.umb-grid p > a:hover,
section.content div.umb-grid p > span > a:hover {
  text-decoration: underline;
}
section.content div.umb-grid p > a.morelink:hover,
section.content div.umb-grid p > span.morelink a:hover {
  text-decoration: none;
}
section.content div.umb-grid audio {
  margin-bottom: 25px;
}
section.content p {
  margin-bottom: 26px;
}
@media screen and (max-width: 767px) {
  section.content p {
    font-size: 15px;
    line-height: 24px;
  }
}
section.content ul.disabilityList {
  list-style: none;
  margin: 0;
  padding: 15px 0;
}
section.content ul.disabilityList > li > a {
  color: #D54D12;
  display: block;
  font-size: 18px;
  line-height: 29px;
  position: relative;
  padding: 0 0 0 19px;
  text-decoration: none;
}
section.content ul.disabilityList > li > a:before {
  color: rgba(213, 77, 18, 0.5);
  content: "\e802";
  font-family: "helioIcon";
  font-size: 9px;
  left: 0;
  line-height: 28px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
section.content ul.disabilityList > li > a:hover:before {
  left: 3px;
}
section.content span.newsDate {
  color: #9F9D9F;
  display: block;
  font-size: 13px;
  margin-bottom: 18px;
  margin-top: -30px;
  text-transform: uppercase;
}

.resultHighlight {
  background: #ffffff;
  border: 1px solid #E6DFDA;
  border-left: 6px solid #E7E1DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 43px;
  padding: 29px 39px 21px 34px;
  position: relative;
}
.resultHighlight:after, .resultHighlight:before {
  content: "";
  display: table;
}
.resultHighlight:after {
  clear: both;
}
@media screen and (max-width: 520px) {
  .resultHighlight {
    margin: 70px auto 0;
  }
}
.resultHighlight div.content {
  float: left;
  width: 47.2%;
}
.resultHighlight div.content:after, .resultHighlight div.content:before {
  content: "";
  display: table;
}
.resultHighlight div.content:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .resultHighlight div.content {
    float: none;
    width: 100%;
  }
}
.resultHighlight div.content p.source {
  margin-top: 5px;
}
.resultHighlight span.title {
  color: #504D4C;
  font-family: "latobold";
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 12px;
}
.resultHighlight span.subTitle {
  display: block;
  color: #999799;
  font-family: "latobold";
  font-size: 16px;
  margin: 0 0 20px;
}
.resultHighlight p {
  color: #504D4C;
  font-size: 15px;
  line-height: 24px;
}
.resultHighlight p.source {
  color: #504D4C;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  margin: 30px 0 0;
}
.resultHighlight p.source a {
  padding-left: 6px;
}
.resultHighlight div.articleHolder {
  margin: 0 0 44px;
}
.resultHighlight div.articleHolder:after, .resultHighlight div.articleHolder:before {
  content: "";
  display: table;
}
.resultHighlight div.articleHolder:after {
  clear: both;
}
.resultHighlight div.articleHolder div.image {
  float: left;
  margin-right: 4.5%;
  width: 17.2%;
}
@media screen and (max-width: 520px) {
  .resultHighlight div.articleHolder div.image {
    float: none;
    margin: 0 0 20px;
    max-width: 120px;
    width: 100%;
  }
}
.resultHighlight div.articleHolder div.image img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin: 6px 0 0;
  max-width: 100%;
  vertical-align: top;
}
.resultHighlight div.articleHolder div.content {
  float: left;
  width: 75%;
}
@media screen and (max-width: 520px) {
  .resultHighlight div.articleHolder div.content {
    float: none;
    width: 100%;
  }
}
.resultHighlight div.articleHolder div.content span.title {
  color: #504D4C;
  font-family: "latobold";
  font-size: 21px;
  line-height: 26px;
}

div.chartTitle {
  color: #504D4C;
  font-family: "latobold";
  font-size: 16px;
  line-height: 30px;
  margin: 40px 0 5px;
}
div.chartTitle:first-child {
  margin-top: 0;
}

div.graphsHolder {
  float: left;
  margin-left: 5.6%;
  padding-bottom: 20px;
  width: 47.2%;
}
@media screen and (max-width: 767px) {
  div.graphsHolder {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
div.graphsHolder div.barGraphs {
  margin-top: 0;
}

div.barGraphs {
  margin-top: 31px;
}
div.barGraphs div.chartlineHolder {
  position: relative;
  background: #F2EFEC;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  width: 100%;
}
div.barGraphs div.chartlineHolder > div.chartline {
  position: relative;
  background: #441A63;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  color: #ffffff;
  font-family: "latobold";
  font-size: 21px;
  line-height: 30px;
  padding: 6px 10px 8px;
  margin-top: 10px;
  overflow: hidden;
}
div.barGraphs div.chartlineHolder > div.chartline.shadow {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  color: #441A63;
  background-color: transparent;
  width: 100%;
  overflow: visible;
  box-shadow: none !important;
}
div.barGraphs div.chartlineHolder:nth-child(2) > div.chartline, div.barGraphs div.chartlineHolder.big > div.chartline {
  background: #C343B6;
}
div.barGraphs div.chartlineHolder:nth-child(2) > div.chartline.shadow, div.barGraphs div.chartlineHolder.big > div.chartline.shadow {
  color: #C343B6;
  background: transparent;
}
div.barGraphs div.chartlineHolder:nth-child(3) > div.chartline, div.barGraphs div.chartlineHolder.big > div.chartline {
  background: #38B3FF;
}
div.barGraphs div.chartlineHolder:nth-child(3) > div.chartline.shadow, div.barGraphs div.chartlineHolder.big > div.chartline.shadow {
  color: #38B3FF;
  background: transparent;
}
div.barGraphs div.chartlineHolder:nth-child(4) > div.chartline, div.barGraphs div.chartlineHolder.big > div.chartline {
  background: #F19600;
}
div.barGraphs div.chartlineHolder:nth-child(4) > div.chartline.shadow, div.barGraphs div.chartlineHolder.big > div.chartline.shadow {
  color: #F19600;
  background: transparent;
}
div.barGraphs.big {
  padding-top: 3px;
}
div.barGraphs.big div.chartlineHolder > div.chartline {
  background: #38B3FF;
  font-family: "latolight";
  font-size: 60px;
  line-height: 72px;
  padding: 41px 32px 36px;
}
div.barGraphs.big div.chartlineHolder > div.chartline strong {
  font-family: "latobold";
}
div.barGraphs.big div.number {
  background: #38B3FF;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  color: #ffffff;
  font-family: "latobold";
  font-size: 60px;
  line-height: 72px;
  padding: 41px 20px 36px;
  text-align: center;
}
div.barGraphs.big + div.legend span:before {
  background: #38B3FF;
}

div.legend {
  margin: 23px 0 0;
  display: flex;
  flex-direction: column;
}
div.legend span {
  color: #532078;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  margin-right: 28px;
  padding: 0 0 0 21px;
  position: relative;
}
div.legend span:before {
  background: #441A63;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 12px;
}
div.legend span:nth-child(2):before {
  background: #C343B6;
}
div.legend span:nth-child(3):before {
  background: #38B3FF;
}
div.legend span:nth-child(4):before {
  background: #F19600;
}

.highlighted {
  background: rgba(241, 150, 0, 0.2);
  border-left: 6px solid #F19600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 100px;
  position: relative;
}
.highlighted:after, .highlighted:before {
  content: "";
  display: table;
}
.highlighted:after {
  clear: both;
}
@media screen and (max-width: 520px) {
  .highlighted {
    margin: 70px auto 0;
    max-width: 335px;
  }
}
.highlighted span.blockTitle {
  display: block;
  margin: 0 0 0 11px;
  position: absolute;
  top: -66px;
}
.highlighted span.blockTitle svg {
  margin: 13px 19px 0 0;
  vertical-align: top;
}
.highlighted span.blockTitle svg .dot {
  fill: #F19600;
}
.highlighted span.blockTitle svg .link {
  fill: rgba(241, 150, 0, 0.5);
}
.highlighted span.blockTitle span.title {
  color: #F19600;
  font-family: "Nanum Pen Script";
  font-size: 25px;
  vertical-align: top;
}
.highlighted div.image {
  float: left;
  width: 32.82%;
}
@media screen and (max-width: 520px) {
  .highlighted div.image {
    float: none;
    width: 100%;
  }
}
.highlighted div.image img {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  margin-top: 0;
  vertical-align: top;
}
.highlighted div.image + div.content {
  float: left;
  padding: 31px 30px 10px 39px;
  width: 67.18%;
}
@media screen and (max-width: 520px) {
  .highlighted div.image + div.content {
    float: none;
    padding: 24px 30px 21px;
    width: 100%;
  }
}
.highlighted div.image + div.content p:last-child {
  margin-bottom: 0;
}
.highlighted div.content {
  color: #A66600;
  font-size: 18px;
  padding: 34px 30px 1px;
}
.highlighted div.content p {
  margin-bottom: 12px;
}
.highlighted div.content .title {
  font-family: "latobold";
  font-size: 21px;
}
.highlighted div.content .moreLink {
  display: inline-block;
  font-size: 16px;
  margin: 12px 0 0;
}
.highlighted div.content ul {
  list-style: none;
  margin: 11px 0 11px;
  padding: 0;
}
.highlighted div.content ul li {
  margin: 0 0 6px;
  padding-left: 30px;
  position: relative;
}
.highlighted div.content ul li:before {
  color: #F19600;
  content: "•";
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  vertical-align: top;
}
.highlighted div.content ul li a {
  color: #D54D12;
  text-decoration: none;
}
.highlighted div.content ul li a:hover {
  text-decoration: underline;
}
.highlighted div.content ul li p {
  margin: 0;
}
.highlighted + .additionalContent {
  margin-top: 156px;
}

.additionalContent {
  background: #ffffff;
  border: 1px solid #EAA688;
  border-left: 6px solid #EAA688;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin: 100px 0 0;
  position: relative;
}
.additionalContent:after, .additionalContent:before {
  content: "";
  display: table;
}
.additionalContent:after {
  clear: both;
}
.additionalContent span.blockTitle {
  display: block;
  margin: 0 0 0 11px;
  position: absolute;
  top: -66px;
}
.additionalContent span.blockTitle svg {
  margin: 13px 19px 0 0;
  vertical-align: top;
}
.additionalContent span.blockTitle svg .dot {
  fill: #E03501;
}
.additionalContent span.blockTitle svg .link {
  fill: rgba(213, 77, 18, 0.5);
}
.additionalContent span.blockTitle span.title {
  color: #E03501;
  font-family: "Nanum Pen Script";
  font-size: 25px;
  vertical-align: top;
}
.additionalContent div.content {
  color: #504D4C;
  font-size: 15px;
  padding: 34px 30px 33px;
}
.additionalContent div.content p {
  line-height: 24px;
  margin-bottom: 12px;
}
.additionalContent div.content p a {
  color: #D54D12;
  text-decoration: none;
}
.additionalContent div.content p a:hover {
  text-decoration: underline;
}
.additionalContent div.content .title {
  color: #999799;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  margin-top: 42px;
}
.additionalContent div.content p:first-child {
  margin-top: 0;
}
.additionalContent div.content p:first-child > .title {
  margin-top: 0;
}
.additionalContent div.content .moreLink {
  display: inline-block;
  font-size: 16px;
  margin: 12px 0 0;
}
.additionalContent div.content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.additionalContent div.content ul li {
  margin-top: 0 0 6px;
  padding-left: 30px;
  position: relative;
}
.additionalContent div.content ul li:before {
  color: #E03501;
  content: "•";
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  vertical-align: top;
}
.additionalContent div.content ul li:first-child {
  margin-top: 4px;
}
.additionalContent div.content ul li a {
  color: #D54D12;
  display: block;
  font-size: 15px;
  line-height: 21px;
  position: relative;
  padding: 0 0 0 19px;
  text-decoration: none;
}
.additionalContent div.content ul li a:before {
  color: rgba(213, 77, 18, 0.5);
  content: "\e802";
  font-family: "helioIcon";
  font-size: 7px;
  left: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.additionalContent div.content ul li a:hover:before {
  left: 3px;
}

div.pageTools {
  border-top: 1px solid #E6DFDA;
  float: left;
  margin: 27px 0 0;
  padding: 20px 0;
  width: 100%;
}
div.pageTools div.toTop {
  color: #D54D12;
  cursor: pointer;
  float: left;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  div.pageTools div.toTop {
    float: none;
    margin-top: 25px;
    width: 100%;
  }
}
div.pageTools div.toTop:before {
  color: #EAA688;
  content: "\e803";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 7px;
  margin: 2px 15px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
div.pageTools div.toTop:hover:before {
  margin: -1px 15px 0 0;
}
@media screen and (max-width: 767px) {
  div.pageTools div.toTop:hover:before {
    margin: 2px 15px 0 0;
  }
}
div.pageTools div.shareTools {
  color: #999799;
  float: right;
  font-family: "latobold";
  font-size: 13px;
  line-height: 34px;
  text-transform: uppercase;
}
div.pageTools div.shareTools:after, div.pageTools div.shareTools:before {
  content: "";
  display: table;
}
div.pageTools div.shareTools:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  div.pageTools div.shareTools {
    float: none;
    width: 100%;
  }
}
div.pageTools div.shareTools div.dcShare {
  float: right;
  margin-left: 20px;
}
@media screen and (max-width: 360px) {
  div.pageTools div.shareTools div.dcShare {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
div.pageTools div.shareTools div.dcShare > a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-left: 9px;
  max-height: 30px;
  max-width: 30px;
  overflow: hidden;
  vertical-align: top;
}
@media screen and (max-width: 360px) {
  div.pageTools div.shareTools div.dcShare > a:first-child {
    margin-left: 0;
  }
}
div.pageTools div.shareTools div.dcShare > a svg {
  max-height: 30px;
  max-width: 30px;
}

@media print {
  body {
    font-size: 15px;
    line-height: 24px;
  }
  body h1 {
    font-size: 24px;
  }
  body h2 {
    font-size: 18px;
  }
  header a.logo {
    margin: 5px 0;
  }
  header div.hamburgerSearch,
  header div.nav {
    display: none;
  }
  section.breadcrumb {
    display: none;
  }
  section.content {
    color: #000000;
    line-height: 24px;
    padding: 20px 0 15px;
  }
  section.content h1,
  section.content div.umb-grid blockquote,
  section.content div.umb-grid ol li:before,
  section.content div.umb-grid ul li:before {
    color: #000000;
  }
  section.content div.umb-grid blockquote {
    margin-top: 25px;
    padding: 0 20px;
  }
  section.content div.umb-grid blockquote:before {
    display: none;
  }
  section.content div.umb-grid .sup {
    font-family: "latoitalic";
    font-size: 13px;
    margin-top: 25px;
  }
  section.content div.umb-grid .intro {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  section.content div.umb-grid .video {
    display: none;
  }
  section.content div.umb-grid p {
    margin-bottom: 16px;
  }
  section.content div.umb-grid a,
  section.content div.umb-grid p a {
    color: #000000;
    text-decoration: underline;
  }
  section.content div.umb-grid a:after,
  section.content div.umb-grid a:before {
    display: none;
  }
  section.content div.umb-grid h2,
  section.content div.umb-grid h3,
  section.content div.umb-grid p + h3 {
    margin: 25px 0 8px;
  }
  section.content img {
    max-width: 75%;
  }
  section.content .submenu,
  section.content .pageTools {
    display: none;
  }
  section.content .col8 {
    width: 100%;
  }
  footer {
    background: #ffffff;
    padding: 20px 0 30px;
  }
  footer div.logoHolder,
  footer ul.doormat {
    display: none;
  }
  footer div.telHolder {
    width: 100%;
  }
  footer div.telHolder div.telNum,
  footer div.telHolder div.address {
    float: left;
    width: 50%;
  }
  footer div.telHolder div.telNum .moreLink,
  footer div.telHolder div.address .moreLink {
    display: none;
  }
}
#cookieOptIn {
  background: #2C1140;
  padding: 18px 0 20px;
  pointer-events: all;
  position: relative;
  width: 100%;
}
#cookieOptIn .cookieOptInInner {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  min-height: 20px;
  padding: 0 60px 0 10px;
  line-height: 22px;
  color: #AA8FB9;
}
#cookieOptIn .cookieOptInInner:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
#cookieOptIn .cookieOptInInner > span {
  display: inline-block;
  margin-right: 25px;
}
@media screen and (max-width: 750px) {
  #cookieOptIn .cookieOptInInner > span {
    display: block;
    margin: 0 0 7px;
  }
}
#cookieOptIn .cookieOptInInner a {
  color: #D94B1B;
  text-decoration: none;
}
#cookieOptIn .cookieOptInInner a:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 25px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
#cookieOptIn .cookieOptInInner a:hover:after {
  margin: 1px 5px 0 6px;
}
#cookieOptIn .cookieOptInInner .closeCookie {
  color: #ffffff;
  font-family: "latobold";
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 2px;
}
#cookieOptIn .cookieOptInInner .closeCookie:after {
  color: #7F5796;
  content: "\e808";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 14px;
  margin: 0 0 0 15px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
#cookieOptIn .cookieOptInInner .closeCookie:hover:after {
  color: #AA8FB9;
}

li.a:after {
  content: "a";
}

li.b:after {
  content: "b";
}

li.c:after {
  content: "c";
}

li.d:after {
  content: "d";
}

li.e:after {
  content: "e";
}

li.f:after {
  content: "f";
}

li.g:after {
  content: "g";
}

li.h:after {
  content: "h";
}

li.i:after {
  content: "i";
}

li.j:after {
  content: "j";
}

li.k:after {
  content: "k";
}

li.l:after {
  content: "l";
}

li.m:after {
  content: "m";
}

li.n:after {
  content: "n";
}

li.o:after {
  content: "o";
}

li.p:after {
  content: "p";
}

li.q:after {
  content: "q";
}

li.r:after {
  content: "r";
}

li.s:after {
  content: "s";
}

li.t:after {
  content: "t";
}

li.u:after {
  content: "u";
}

li.v:after {
  content: "v";
}

li.w:after {
  content: "w";
}

li.x:after {
  content: "x";
}

li.y:after {
  content: "y";
}

li.z:after {
  content: "z";
}

ul.resultList li {
  padding: 0;
  position: relative;
}
ul.resultList li:before {
  content: "";
}
ul.resultList li:after {
  font-size: 30px;
  position: absolute;
  right: 0;
}

.serviceWidget {
  background: rgba(209, 231, 246, 0.5);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 53px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .serviceWidget {
    margin-top: 20px;
  }
}
.serviceWidget.load {
  pointer-events: none;
}
.serviceWidget.load div.widgetFooter:after {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  z-index: 9999;
}
.serviceWidget span.title {
  color: #003D65;
  display: block;
  font-family: "latobold";
  font-size: 24px;
  line-height: 38px;
  padding: 20px 20px 18px;
}
.serviceWidget form {
  display: block;
  padding: 0 20px 20px;
}
.serviceWidget form:after, .serviceWidget form:before {
  content: "";
  display: table;
}
.serviceWidget form:after {
  clear: both;
}
.serviceWidget form label {
  display: block;
  float: left;
  margin-left: 2%;
  max-width: 32.37%;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .serviceWidget form label {
    float: none;
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
  }
}
.serviceWidget form label:first-child {
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .serviceWidget form label:first-child {
    margin-top: 0;
  }
}
.serviceWidget form label:after {
  background: transparent;
  color: #3A9AD7;
  content: "\e807";
  font-family: "helioIcon";
  font-size: 8px;
  padding: 0 0 2px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.serviceWidget form label:hover:after {
  /*top: 8px;*/
}
.serviceWidget form label select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #003D65;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  outline: none;
  padding: 8px 36px 8px 8px;
  vertical-align: top;
  width: 100%;
}
.serviceWidget form label select.disabled, .serviceWidget form label select:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
.serviceWidget form label select.disabled:hover:after, .serviceWidget form label select:disabled:hover:after {
  top: 9px;
}
.serviceWidget form label select.hide {
  display: none;
}
.serviceWidget form label select:after, .serviceWidget form label select:before {
  content: "";
  display: table;
}
.serviceWidget form label select:after {
  clear: both;
}
.serviceWidget form label.select {
  cursor: pointer;
  max-width: 49%;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .serviceWidget form label.select {
    float: none;
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
  }
}
.serviceWidget form label.select:after {
  display: none;
}
.serviceWidget form label .styledSelect {
  background: #ffffff;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #003D65;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  outline: none;
  padding: 8px 36px 8px 12px;
  vertical-align: top;
  width: 100%;
}
.serviceWidget form label .styledSelect:after {
  background: transparent;
  color: #3A9AD7;
  content: "\e807";
  font-family: "helioIcon";
  font-size: 8px;
  padding: 0 0 2px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 9px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.serviceWidget form label .styledSelect:hover:after {
  top: 12px;
}
.serviceWidget form label .styledSelect:active:hover:after, .serviceWidget form label .styledSelect.active:hover:after {
  top: 9px;
}
.serviceWidget form label .styledSelect.disabled, .serviceWidget form label .styledSelect:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
.serviceWidget form label .styledSelect.disabled:hover:after, .serviceWidget form label .styledSelect:disabled:hover:after {
  top: 9px;
}
.serviceWidget form label .options {
  background-color: white;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  display: none;
  font-size: 16px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 4px 0 11px;
  position: absolute;
  right: 0;
  top: 100%;
  top: calc(100% + 4px);
  z-index: 999;
}
.serviceWidget form label .options li {
  color: #003D65;
  line-height: 30px;
  margin: 0;
  padding: 0 12px;
}
.serviceWidget form label .options li:hover, .serviceWidget form label .options li.selected {
  background-color: #E8F3FB;
}
.serviceWidget form button {
  background: #017CCB;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: "latobold";
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin-left: 2%;
  padding: 10px 11px 9px;
  vertical-align: top;
  width: 31%;
}
.serviceWidget.threeSelects label {
  width: 23.5%;
}
.serviceWidget.threeSelects label.select {
  width: 32%;
}
@media screen and (max-width: 767px) {
  .serviceWidget.threeSelects label.select {
    max-width: 100%;
    width: 100%;
  }
}
.serviceWidget.threeSelects button {
  width: 22.5%;
}
.serviceWidget button.showResults {
  border-radius: 5px;
  background-color: #F19600;
  padding: 9px 20px;
  border: none;
  margin: 0 0 30px 20px;
  color: white;
  font-size: 18px;
  line-height: 18px;
}
.serviceWidget div.resultsHolder {
  background: rgb(209, 231, 246);
}
.serviceWidget div.resultsHolder:after, .serviceWidget div.resultsHolder:before {
  content: "";
  display: table;
}
.serviceWidget div.resultsHolder:after {
  clear: both;
}
.serviceWidget div.resultsHolder a.tipBlock {
  background: #ffffff;
  border-left: 6px solid #F19600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.1);
  color: #A66600;
  display: block;
  margin: 21px 20px 8px;
  padding: 16px 15px 15px 61px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
.serviceWidget div.resultsHolder a.tipBlock > span.tip {
  color: #F19600;
  font-family: "Nanum Pen Script";
  font-size: 25px;
  left: 18px;
  position: absolute;
  top: 16px;
}
.serviceWidget div.resultsHolder a.tipBlock > span {
  display: block;
  font-size: 18px;
  font-family: "latobold";
  margin-top: 1px;
}
.serviceWidget div.resultsHolder a.tipBlock:after {
  color: transparent;
  content: "\e802";
  font-family: "helioIcon";
  font-size: 16px;
  height: 17px;
  line-height: 18px;
  margin-top: -10px;
  position: absolute;
  right: 23px;
  top: 50%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
  width: 12px;
}
.serviceWidget div.resultsHolder a.tipBlock:hover {
  color: #F19600;
}
.serviceWidget div.resultsHolder a.tipBlock:hover:after {
  color: #F19600;
  right: 20px;
}
.serviceWidget div.resultsHolder ul.resultList {
  list-style: none;
  margin: 4px 20px 28px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .serviceWidget div.resultsHolder ul.resultList {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.serviceWidget div.resultsHolder ul.resultList:after, .serviceWidget div.resultsHolder ul.resultList:before {
  content: "";
  display: table;
}
.serviceWidget div.resultsHolder ul.resultList:after {
  clear: both;
}
.serviceWidget div.resultsHolder ul.resultList li {
  display: block;
  width: 100%;
}
.serviceWidget div.resultsHolder ul.resultList li.letter {
  display: block;
  position: relative;
  width: 100%;
}
.serviceWidget div.resultsHolder ul.resultList li.letter span {
  color: rgba(1, 62, 102, 0.5);
  font-family: "latobold";
  font-size: 18px;
  left: 0;
  line-height: 28px;
  position: absolute;
  top: 9px;
}
@media screen and (max-width: 767px) {
  .serviceWidget div.resultsHolder ul.resultList li.letter span {
    display: block;
    padding: 5px 15px;
    position: relative;
    top: 0;
  }
}
.serviceWidget div.resultsHolder ul.resultList li.letter:first-child + li a:before {
  display: none;
}
@media screen and (max-width: 767px) {
  .serviceWidget div.resultsHolder ul.resultList li.letter:first-child + li a:before {
    display: block;
  }
}
.serviceWidget div.resultsHolder ul.resultList li a {
  background: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 60, 101, 0.1);
  -moz-box-shadow: 0 0 0 0 rgba(0, 60, 101, 0.1);
  box-shadow: 0 0 0 0 rgba(0, 60, 101, 0.1);
  color: #017CCB;
  display: block;
  font-family: "latobold";
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 0 38px;
  padding: 9px 15px 11px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  .serviceWidget div.resultsHolder ul.resultList li a {
    margin: 0;
  }
}
.serviceWidget div.resultsHolder ul.resultList li a:after, .serviceWidget div.resultsHolder ul.resultList li a:before {
  content: "";
  display: table;
}
.serviceWidget div.resultsHolder ul.resultList li a:after {
  clear: both;
}
.serviceWidget div.resultsHolder ul.resultList li a:before {
  border-top: 1px solid rgba(255, 255, 255, 0.75);
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  width: calc(100% - 15px);
}
.serviceWidget div.resultsHolder ul.resultList li a:after {
  color: transparent;
  content: "\e802";
  font-family: "helioIcon";
  font-size: 8px;
  position: absolute;
  right: 13px;
  top: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.serviceWidget div.resultsHolder ul.resultList li a:hover {
  background: #ffffff;
  color: #003D65;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 60, 101, 0.1);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 60, 101, 0.1);
  box-shadow: 0 1px 1px 0 rgba(0, 60, 101, 0.1);
}
.serviceWidget div.resultsHolder ul.resultList li a:hover:after {
  color: #017CCB;
  right: 10px;
}
.serviceWidget div.resultsHolder ul.resultList li a:hover:before {
  top: 0;
  width: calc(100% - 17px);
}
.serviceWidget div.widgetFooter {
  background: #ffffff;
  border: 1px solid rgb(209, 231, 246);
  border-top: none;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  color: #003D65;
  padding: 11px 17px 16px;
}
.serviceWidget div.widgetFooter:after {
  background: url(/css/img/load.svg) rgba(0, 61, 101, 0.4) no-repeat center center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  content: "";
  display: block;
  height: 100%;
  opacity: 0;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -ms-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  width: 100%;
  z-index: -1;
}
.serviceWidget div.widgetFooter > a {
  color: #D54D12;
  margin: 0 0 0 11px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  .serviceWidget div.widgetFooter > a {
    display: block;
    margin: 0;
  }
}
.serviceWidget div.widgetFooter > a:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 8px;
  line-height: 26px;
  margin: 0 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: middle;
}
.serviceWidget div.widgetFooter > a:hover {
  color: #8A310B;
}
.serviceWidget div.widgetFooter > a:hover:after {
  margin: 0 5px 0 6px;
}

div.pageWidgets:after, div.pageWidgets:before {
  content: "";
  display: table;
}
div.pageWidgets:after {
  clear: both;
}

div.topTasks {
  background: #9A49A0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 51px;
  padding: 0 20px 20px;
  pointer-events: none;
  position: relative;
}
div.topTasks.slideOut {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  left: 0;
  margin-bottom: 0;
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-transition: max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -moz-transition: max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -ms-transition: max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  -o-transition: max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  transition: max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  top: 233px;
  width: 100%;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  div.topTasks.slideOut {
    top: 144px;
  }
}
div.topTasks.slideOut > div.container {
  max-width: 940px;
}
@media screen and (max-width: 825px) {
  div.topTasks.slideOut > div.container {
    padding: 0 10px;
  }
}
div.topTasks.slideOut a:hover > span.text:after {
  display: none;
}
div.topTasks.four a {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 13px 5px;
  margin: 31px 0 29px 1.6%;
  width: 23.8%;
}
@media screen and (max-width: 767px) {
  div.topTasks.four a {
    margin-left: 0;
    width: 100%;
  }
}
div.topTasks.four a > svg {
  height: 55px;
  width: 52px;
}
div.topTasks.four a > span.text {
  display: inline-block;
  min-width: 62%;
  padding: 5px 0 0 7px;
  text-align: left;
}
@media screen and (max-width: 976px) {
  div.topTasks.four a > span.text {
    font-size: 15px;
    padding-left: 4px;
  }
}
@media screen and (max-width: 825px) {
  div.topTasks.four a > span.text {
    font-size: 14px;
    line-height: 19px;
    padding-top: 7px;
  }
}
@media screen and (max-width: 767px) {
  div.topTasks.four a > span.text {
    font-size: 18px;
    line-height: 21px;
    padding-top: 5px;
  }
}
div.topTasks.four a > span.text:after {
  bottom: -79px;
}
@media screen and (max-width: 976px) {
  div.topTasks.four a > span.text:after {
    bottom: -99px;
  }
}
div.topTasks.four a:first-child {
  margin-left: 0;
}
div.topTasks.four a:nth-child(2) {
  margin-left: 1.6%;
}
@media screen and (max-width: 767px) {
  div.topTasks.four a:nth-child(2) {
    margin-left: 0;
  }
}
div.topTasks.four a:hover > span.text:after {
  bottom: -81px;
}
@media screen and (max-width: 976px) {
  div.topTasks.four a:hover > span.text:after {
    bottom: -101px;
  }
}
div.topTasks.four a:nth-child(1) {
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  div.topTasks.four a:nth-child(1) {
    margin-top: 25px;
  }
}
div.topTasks.slideOut.opened {
  max-height: 200px;
}
@media screen and (max-width: 767px) {
  div.topTasks.slideOut.opened {
    max-height: 900px;
  }
}
@media screen and (max-height: 530px) {
  div.topTasks.slideOut.opened {
    height: calc(100% - 43px);
    overflow-y: scroll;
  }
}
@media screen and (max-width: 767px) {
  div.topTasks {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
div.topTasks:after, div.topTasks:before {
  content: "";
  display: table;
}
div.topTasks:after {
  clear: both;
}
div.topTasks:hover a {
  background: rgba(255, 255, 255, 0.9);
}
div.topTasks > span.title {
  color: #EAD1EB;
  display: block;
  font-family: "latobold";
  font-size: 24px;
  padding: 16px 0 20px;
}
div.topTasks a {
  background: rgb(255, 255, 255);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #C343B6;
  display: block;
  float: left;
  margin-left: 2%;
  padding: 35px 20px 23px;
  pointer-events: all;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 32%;
}
@media screen and (min-width: 767px) {
  div.topTasks a:after, div.topTasks a:before {
    content: "";
    display: table;
  }
  div.topTasks a:after {
    clear: both;
  }
}
@media screen and (max-width: 767px) {
  div.topTasks a {
    float: none;
    margin-left: 0;
    margin-top: 10px;
    padding: 23px 20px 23px;
    position: relative;
    width: 100%;
  }
}
div.topTasks a:after, div.topTasks a:before {
  content: "";
  display: table;
}
div.topTasks a:after {
  clear: both;
}
div.topTasks a > svg {
  display: inline-block;
  vertical-align: top;
}
div.topTasks a > span.text {
  display: block;
  line-height: 21px;
  padding: 20px 0 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  div.topTasks a > span.text {
    display: inline-block;
    padding: 10px 0 0 20px;
    text-align: left;
  }
}
div.topTasks a > span.text > strong {
  font-family: "latobold";
  font-weight: normal;
}
div.topTasks a > span.subText {
  bottom: -58px;
  color: #532078;
  display: block;
  font-family: "latobold";
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  padding: 30px 20px 0;
  pointer-events: none;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 767px) {
  div.topTasks a > span.subText {
    display: none;
  }
}
div.topTasks a:hover {
  background: rgb(255, 255, 255);
  color: #532078;
}
div.topTasks a:hover > span.text:after {
  bottom: -70px;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}
div.topTasks a:hover > span.subText {
  bottom: -62px;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}
div.topTasks a:nth-child(2) {
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  div.topTasks a:nth-child(2) {
    margin-top: 0;
  }
}
div.topTasks.tasks2 a {
  margin-left: 0;
  margin-right: 2.75%;
  width: 48.625%;
}
@media screen and (max-width: 767px) {
  div.topTasks.tasks2 a {
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
div.topTasks.tasks2 a:last-child {
  margin-right: 0;
}
div.topTasks.tasks1 {
  text-align: center;
}
div.topTasks.tasks1 a {
  display: inline-block;
  float: none;
  margin-left: 0;
  margin-right: 0;
  width: 48.625%;
}
@media screen and (max-width: 767px) {
  div.topTasks.tasks1 a {
    width: 100%;
  }
}
div.topTasks.tasks1 a span.text:after {
  left: -2px;
  right: auto;
}

.serviceBar div.topTasks {
  margin-top: -10px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.serviceBar div.topTasks > span.title {
  display: none;
}
.serviceBar div.topTasks a {
  width: 100%;
  margin: 10px 0;
  float: none;
  margin: 0;
  margin-top: 20px;
  padding: 10px 18px;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.serviceBar div.topTasks a:after, .serviceBar div.topTasks a:before {
  content: "";
  display: table;
}
.serviceBar div.topTasks a:after {
  clear: both;
}
.serviceBar div.topTasks a > svg {
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.serviceBar div.topTasks a > span.text {
  display: inline-block;
  padding: 0 0 0 10px;
  text-align: left;
}
.serviceBar div.topTasks a > span.text:after {
  display: none;
}
.serviceBar div.topTasks a > span.subText {
  display: none;
}

div.themes {
  margin: 93px 0 75px;
}
@media screen and (max-width: 767px) {
  div.themes {
    margin: 23px 0 0;
  }
}
div.themes > span.title {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  font-size: 21px;
  line-height: 34px;
  margin-bottom: 2px;
}
div.themes > p {
  line-height: 25px;
  margin-bottom: 15px;
}
div.themes > p:after, div.themes > p:before {
  content: "";
  display: table;
}
div.themes > p:after {
  clear: both;
}
div.themes div.themesHolder {
  margin-top: 20px;
}
div.themes div.themesHolder:after, div.themes div.themesHolder:before {
  content: "";
  display: table;
}
div.themes div.themesHolder:after {
  clear: both;
}
div.themes div.themesHolder > a {
  border: 2px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: left;
  font-family: "latobold";
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
  margin-left: 3.5%;
  min-height: 65px;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 31%;
}
div.themes div.themesHolder > a:nth-child(3n-2) {
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  div.themes div.themesHolder > a {
    margin-left: 6%;
    width: 47%;
  }
  div.themes div.themesHolder > a:nth-child(3n-2) {
    margin-left: 6%;
  }
  div.themes div.themesHolder > a:nth-child(odd) {
    margin-left: 0;
  }
}
@media screen and (max-width: 568px) {
  div.themes div.themesHolder > a {
    width: 100%;
    margin-left: 0;
  }
  div.themes div.themesHolder > a:nth-child(3n-2) {
    margin-left: 0;
  }
}
div.themes div.themesHolder > a > span {
  display: inline-block;
  left: 0;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
div.themes div.themesHolder > a:first-child {
  margin-left: 0;
}
div.themes div.themesHolder > a:hover {
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.blue {
  border-color: rgba(1, 124, 203, 0.35);
  color: #017CCB;
}
div.themes div.themesHolder > a.blue:hover {
  background: #017CCB;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.orange {
  border-color: rgba(241, 150, 0, 0.35);
  color: #F19600;
}
div.themes div.themesHolder > a.orange:hover {
  background: #F19600;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.purple {
  border-color: rgba(83, 32, 120, 0.35);
  color: #532078;
}
div.themes div.themesHolder > a.purple:hover {
  background: #532078;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.red {
  border-color: rgba(213, 77, 18, 0.35);
  color: #E03501;
}
div.themes div.themesHolder > a.red:hover {
  background: #E03501;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.rose {
  border-color: rgba(195, 67, 182, 0.35);
  color: #C343B6;
}
div.themes div.themesHolder > a.rose:hover {
  background: #C343B6;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder > a.green {
  border-color: rgba(142, 189, 2, 0.35);
  color: #8EBD02;
}
div.themes div.themesHolder > a.green:hover {
  background: #8EBD02;
  border-color: transparent;
  color: #ffffff;
}
div.themes div.themesHolder.all {
  margin-top: 0;
}
div.themes div.themesHolder.all > a {
  margin-top: 20px;
  width: 31.65%;
}
@media screen and (max-width: 767px) {
  div.themes div.themesHolder.all > a:nth-child(odd) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  div.themes div.themesHolder.all > a {
    margin-bottom: 20px;
    margin-left: 6%;
    width: 47%;
  }
}
@media screen and (min-width: 767px) {
  div.themes div.themesHolder.all > a:nth-child(3n+4) {
    margin-left: 0;
  }
}

.serviceBar {
  border: 1px solid #E6DFDA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: right;
  margin: 6px 0 0;
  padding: 10px 19px 29px;
}
.serviceBar > span.title {
  color: #999799;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  margin: 45px 0 0;
  text-transform: uppercase;
}
.serviceBar > span.title:first-child {
  margin-top: 0;
}
.serviceBar.moBet {
  position: absolute;
  right: 20px;
  top: 0;
}
@media screen and (max-width: 1000px) {
  .serviceBar.moBet {
    float: none;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }
}
.serviceBar a {
  color: #D54D12;
  display: block;
  font-size: 15px;
  line-height: 21px;
  position: relative;
  padding: 0 0 0 19px;
  text-decoration: none;
}
.serviceBar a:before {
  color: rgba(213, 77, 18, 0.5);
  content: "\e802";
  font-family: "helioIcon";
  font-size: 7px;
  left: 0;
  line-height: 21px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.serviceBar a:hover:before {
  left: 3px;
}
.serviceBar a.location {
  margin: 2px 0 13px;
  padding: 0;
}
.serviceBar a.location:last-child {
  margin-bottom: 5px;
}
.serviceBar a.location:before {
  display: none;
}
.serviceBar a.location > span.title {
  font-family: "latobold";
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 7px;
}
.serviceBar a.location > span.title > span {
  display: block;
}
.serviceBar a.location > span.address {
  color: #504D4C;
  font-size: 15px;
}
.serviceBar > p {
  font-size: 15px;
  line-height: 24px;
}
.serviceBar > p:last-child {
  margin-bottom: 0;
}
.serviceBar ul.links {
  line-height: 23px;
  list-style: none;
  margin: 2px 0 0;
  padding: 0;
}
.serviceBar ul.links li {
  display: inline-block;
  font-size: 15px;
}
.serviceBar ul.links li:after {
  color: #504D4C;
  content: "/";
}
.serviceBar ul.links li:last-child {
  padding-right: 3px;
}
.serviceBar ul.links li:last-child:after {
  content: "";
}
.serviceBar ul.links li a {
  display: inline;
  padding: 0;
}
.serviceBar ul.links li a:before {
  display: none;
}
.serviceBar ul.links li a:hover {
  text-decoration: underline;
}
.serviceBar ul.locationLinks {
  line-height: 18px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.serviceBar ul.locationLinks li {
  margin-top: 10px;
}
.serviceBar ul.locationLinks li:first-child {
  margin-top: 4px;
}
.serviceBar ul.locationLinks li a > span {
  display: block;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  div.heroImage {
    max-height: 233px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  div.heroImage {
    max-height: 155px;
  }
}
div.heroImage {
  height: 47vh;
  min-height: 460px;
  overflow: hidden;
  position: relative;
}
div.heroImage.detail {
  margin-bottom: 23px;
  max-height: 430px;
}
@media screen and (max-width: 767px) {
  div.heroImage {
    min-height: 130px;
  }
}
div.heroImage .container {
  height: 100%;
}
@media screen and (max-width: 1220px) {
  div.heroImage .container {
    padding: 0;
  }
}
div.heroImage img {
  width: 100%;
  height: 100%;
}
div.heroImage svg {
  bottom: -1px;
  fill: #ffffff;
  left: 0;
  max-height: 110px;
  overflow: visible;
  position: absolute;
  vertical-align: bottom;
  width: 100%;
}
div.heroImage > span {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#bf000000", GradientType=0);
  bottom: 0;
  display: block;
  height: 55%;
  position: absolute;
  width: 100%;
}
div.heroImage .videoContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
}
@media screen and (min-width: 768px) {
  div.heroImage .videoContainer {
    display: block;
  }
}
div.heroImage .videoContainer #headerVideo {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section.topTasksHero {
  margin-bottom: 100px;
  margin-top: -91px;
}
@media screen and (max-width: 767px) {
  section.topTasksHero {
    margin-bottom: 0;
  }
}
section.topTasksHero > div.container {
  max-width: 980px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  section.topTasksHero > div.container {
    padding: 0 20px;
  }
}
section.topTasksHero div.topTasks {
  background: rgba(142, 53, 149, 0.9);
  margin-top: 0;
}
@media screen and (max-width: 825px) {
  section.topTasksHero div.topTasks {
    padding: 0 10px 20px;
  }
}
@media screen and (max-width: 767px) {
  section.topTasksHero div.topTasks {
    padding: 20px;
  }
}
section.topTasksHero div.topTasks > a {
  float: left;
  margin: 20px 0 0 1.6%;
}
@media screen and (max-width: 825px) {
  section.topTasksHero div.topTasks > a {
    padding: 13px 2px;
  }
}
@media screen and (max-width: 767px) {
  section.topTasksHero div.topTasks > a {
    float: none;
    margin: 20px 0 0 0;
    padding: 13px 5px;
  }
}
section.topTasksHero div.topTasks > a > span.subText {
  background: #ffffff;
  font-size: 24px;
  padding: 73px 20px 116px;
  top: 121px;
  z-index: 2;
}
section.topTasksHero div.topTasks > a > span.text:after {
  z-index: 3;
}
@media screen and (max-width: 767px) {
  section.topTasksHero div.topTasks > a:nth-child(1) {
    margin-top: 0;
  }
}
section.topTasksHero div.topTasks:hover + div.defaultQuoteHolder {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
}
section.topTasksHero div.defaultQuoteHolder {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out 0.3s;
  -moz-transition: all 0.2s ease-in-out 0.3s;
  -ms-transition: all 0.2s ease-in-out 0.3s;
  -o-transition: all 0.2s ease-in-out 0.3s;
  transition: all 0.2s ease-in-out 0.3s;
}
section.topTasksHero div.defaultQuoteHolder h1 {
  display: block;
  font-size: 24px;
  font-weight: normal;
  line-height: 31px;
  margin-bottom: 12px;
}

section.ambassadorHeroInfo {
  margin-bottom: 53px;
  margin-top: -150px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1000px) {
  section.ambassadorHeroInfo {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo {
    margin: 35px 0 0;
  }
}
section.ambassadorHeroInfo h1.quote {
  color: #ffffff;
  font-family: "latobold_italic";
  font-size: 40px;
  line-height: 49px;
  margin: 0;
  position: relative;
  padding: 0 0 0 12.5%;
  text-shadow: 1px 1px #999;
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo h1.quote {
    color: #532078;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }
}
section.ambassadorHeroInfo h1.quote:before {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 9.8%;
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo h1.quote:before {
    background: rgba(83, 32, 120, 0.35);
    top: 17px;
  }
}
section.ambassadorHeroInfo div.person {
  left: 20px;
  position: absolute;
  top: 49px;
  width: 15.2542372881%;
}
@media screen and (max-width: 1000px) {
  section.ambassadorHeroInfo div.person {
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }
}
section.ambassadorHeroInfo div.person img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  max-width: 80px;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo div.person img {
    float: left;
    width: 23.7288135593%;
  }
}
section.ambassadorHeroInfo div.person span.name {
  display: block;
  font-family: "latobold";
  font-size: 15px;
  margin: 37px 0 8px;
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo div.person span.name {
    float: left;
    margin-top: 0;
    margin-left: 1.6949152542%;
    width: 74.5762711864%;
  }
}
section.ambassadorHeroInfo div.person span.function {
  display: block;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  section.ambassadorHeroInfo div.person span.function {
    float: left;
    margin-left: 1.6949152542%;
    width: 74.5762711864%;
  }
}
section.ambassadorHeroInfo div.person a.emailAddress {
  font-size: 15px;
  color: #D54D12;
  text-decoration: none;
}
section.ambassadorHeroInfo div.person a.emailAddress:hover {
  text-decoration: underline;
}
section.ambassadorHeroInfo + section.content div.container div.col8 {
  float: none;
  margin: 0 auto;
}

section.wizardEntree {
  margin: 120px 0 120px;
}
@media screen and (max-width: 767px) {
  section.wizardEntree {
    margin: 27px 0 30px;
  }
}
section.wizardEntree div.greyBlock {
  background: #F2EFEC;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
section.wizardEntree div.greyBlock:after, section.wizardEntree div.greyBlock:before {
  content: "";
  display: table;
}
section.wizardEntree div.greyBlock:after {
  clear: both;
}
section.wizardEntree div.greyBlock h2 {
  color: #532078;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  section.wizardEntree div.greyBlock h2 {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 15px;
  }
}
section.wizardEntree div.greyBlock > .col4 {
  box-sizing: border-box;
  padding: 38px 30px 30px 46px;
}
@media screen and (max-width: 767px) {
  section.wizardEntree div.greyBlock > .col4 {
    padding: 23px 15px 27px;
  }
}
section.wizardEntree div.greyBlock > .col4 > p {
  font-size: 21px;
  line-height: 31px;
  margin-bottom: 42px;
}
@media screen and (max-width: 767px) {
  section.wizardEntree div.greyBlock > .col4 > p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 12px;
  }
}
section.wizardEntree div.greyBlock div.imageHolder {
  box-sizing: border-box;
  margin-bottom: -52px;
  margin-top: -52px;
  padding-right: 46px;
}
@media screen and (max-width: 1024px) {
  section.wizardEntree div.greyBlock div.imageHolder {
    margin: 0;
    padding: 10px;
  }
}
section.wizardEntree div.greyBlock div.imageHolder img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  max-width: 100%;
  vertical-align: top;
}
section.wizardEntree div.greyBlock div.imageHolder a {
  display: block;
}

section.ambassadors {
  margin: 120px 0 103px;
  max-width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  section.ambassadors {
    margin: 30px 0;
  }
}
section.ambassadors div.ambassadorsBlock {
  background: #def0fc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  position: relative;
}
section.ambassadors div.ambassadorsBlock div.backgroundImage {
  top: 0;
  position: absolute;
}
@media screen and (max-width: 800px) {
  section.ambassadors div.ambassadorsBlock div.backgroundImage {
    display: none;
  }
}
section.ambassadors div.ambassadorsBlock div.backgroundImage img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  vertical-align: top;
}
section.ambassadors div.ambassadorsBlock a.button {
  background: #003D65;
  margin-bottom: 10px;
}
section.ambassadors div.ambassadorsBlock ul.ambassadors li {
  list-style: none;
}
@media screen and (max-width: 600px) {
  section.ambassadors div.ambassadorsBlock .owl-carousel .owl-stage-outer {
    overflow: visible;
  }
}
section.ambassadors div.ambassadorsBlock .owl-dots .owl-dot {
  background: transparent;
  border: 1px solid #D54D12;
  margin: 0 20px 0 21px;
}
section.ambassadors div.ambassadorsBlock .owl-dots .owl-dot.active {
  background: #D54D12;
}
section.ambassadors div.ambassadorsBlock div.content {
  box-sizing: border-box;
  color: #003D65;
  float: left;
  max-width: 100%;
  padding: 45px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  section.ambassadors div.ambassadorsBlock div.content {
    max-width: 100%;
    padding: 25px 25px 0;
  }
}
section.ambassadors div.ambassadorsBlock div.content > h2 {
  font-family: "latoregular";
  font-size: 21px;
  line-height: 25px;
  margin: 0 0 17px;
}
section.ambassadors div.ambassadorsBlock div.content > p {
  font-family: "latolight";
  font-size: 34px;
  line-height: 43px;
  margin: 0 0 19px;
}
@media screen and (max-width: 767px) {
  section.ambassadors div.ambassadorsBlock div.content > p {
    font-size: 29px;
    line-height: 37px;
  }
}
section.ambassadors div.ambassadorsBlock div.content > a.moreLink.blue {
  color: #017CCB;
  font-family: "latobold";
}
section.ambassadors div.ambassadorsBlock div.ambassadorHolder {
  float: right;
  position: relative;
  width: 100% !important;
  padding: 20px;
}
section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a {
  display: inline-block;
  border: none;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  /*margin: 20px;*/
  width: 100% !important;
  max-width: 100%;
  position: relative;
}
@media screen and (max-width: 1175px) {
  section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a {
    /*margin: 25px;*/
  }
}
@media screen and (max-width: 1000px) {
  section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a {
    /*margin: 30px auto 25px;*/
    /*margin: 30px;*/
  }
  section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a:after, section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a:before {
    content: "";
    display: table;
  }
  section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a:after {
    clear: both;
  }
}
section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a .imageContainer {
  position: relative;
  height: 0;
  padding-bottom: 54.16%;
}
section.ambassadors div.ambassadorsBlock div.ambassadorHolder > a .imageContainer img {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #E6DFDA;
  object-fit: cover;
}
@media screen and (max-width: 1000px) {
  section.ambassadors div.ambassadorsBlock div.ambassadorHolder {
    width: 100%;
  }
}

section.topical {
  margin: 100px 0;
}
@media screen and (max-width: 767px) {
  section.topical {
    margin: 20px 0;
  }
}
section.topical span.title {
  color: #532078;
  font-size: 34px;
  line-height: 43px;
  margin-bottom: 30px;
  font-family: "latolight";
}
section.topical .col6 {
  padding-left: 35px;
}
@media screen and (max-width: 767px) {
  section.topical .col6 {
    padding-left: 0;
  }
}
section.topical .col6:first-child {
  padding-left: 0;
  padding-right: 35px;
}
@media screen and (max-width: 767px) {
  section.topical .col6:first-child {
    margin-bottom: 20px;
    padding-right: 0;
  }
}
section.topical a.moreLink {
  display: inline-block;
  margin-top: 19px;
}
section.topical ul {
  margin-top: 20px;
}
section.topical ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid #DDD2E4;
}
section.topical ul li:last-child {
  border-bottom: 1px solid #DDD2E4;
}
section.topical ul li a {
  display: inline-block;
  padding: 10px 0;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  color: #532078;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
section.topical ul li a:active, section.topical ul li a:hover {
  color: #D54D12;
}

a.actualBlock {
  display: block;
  text-decoration: none;
}
a.actualBlock > span.title {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 13px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.actualBlock > span.date {
  color: #9F9D9F;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  margin-bottom: 13px;
}
a.actualBlock > span.content {
  display: block;
}
a.actualBlock > span.content:after, a.actualBlock > span.content:before {
  content: "";
  display: table;
}
a.actualBlock > span.content:after {
  clear: both;
}
a.actualBlock > span.content > span.image {
  display: block;
  float: left;
  margin-right: 3.5%;
  padding-top: 5px;
  width: 51.5%;
}
@media screen and (max-width: 767px) {
  a.actualBlock > span.content > span.image {
    float: none;
    margin-bottom: 15px;
    margin-right: 0;
    width: 100%;
  }
}
a.actualBlock > span.content > span.image img {
  max-width: 100%;
  vertical-align: top;
}
a.actualBlock > span.content > span.text {
  color: #504D4C;
  display: block;
  float: left;
  font-size: 15px;
  line-height: 21px;
  width: 45%;
}
@media screen and (max-width: 767px) {
  a.actualBlock > span.content > span.text {
    float: none;
    width: 100%;
  }
}
a.actualBlock:hover > span.title {
  color: #D54D12;
}

section.proposition {
  margin-bottom: 60px;
}
section.proposition p {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #532078;
  margin-bottom: 20px;
}
section.proposition .container > div {
  text-align: center;
  margin-left: 8.4745762712%;
}
@media screen and (max-width: 767px) {
  section.proposition .container > div {
    margin-left: 0;
  }
}

section.expertises h2 {
  font-size: 34px;
  line-height: 43px;
  font-family: "latolight";
  margin-bottom: 30px;
  color: #532078;
}
section.expertises ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
}
section.expertises ul li {
  flex: 1 1 50%;
  padding: 1px;
  list-style: none;
}
@media screen and (max-width: 1080px) {
  section.expertises ul li {
    flex-basis: 100%;
  }
}

a.expertiseBlock {
  display: block;
  height: 265px;
  position: relative;
  color: white;
  text-decoration: none;
  padding: 100px 50px 20px;
}
@media screen and (max-width: 1080px) {
  a.expertiseBlock {
    min-height: 265px;
    height: auto;
    padding: 26px 20px;
  }
}
a.expertiseBlock .backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
a.expertiseBlock .backgroundImage img {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
a.expertiseBlock .backgroundImage:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: grey;
  z-index: 1;
  opacity: 0.8;
}
a.expertiseBlock .content {
  position: relative;
  z-index: 1;
  padding-left: 50px;
}
@media screen and (max-width: 1080px) {
  a.expertiseBlock .content {
    padding-left: 0;
    position: absolute;
    bottom: 26px;
    width: calc(100% - 40px);
  }
}
a.expertiseBlock .content:before {
  content: "";
  position: absolute;
  top: 18px;
  width: 30px;
  height: 2px;
  background: white;
  left: 0;
}
@media screen and (max-width: 1080px) {
  a.expertiseBlock .content:before {
    display: none;
  }
}
a.expertiseBlock .content h3 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 10px;
  font-family: "latoregular";
}
a.expertiseBlock .content p {
  margin: 0;
  font-size: 18px;
  line-height: 21px;
}
a.expertiseBlock:hover, a.expertiseBlock:active {
  z-index: 2;
}
@media screen and (min-width: 1081px) {
  a.expertiseBlock:hover .backgroundImage, a.expertiseBlock:active .backgroundImage {
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
  }
}
a.expertiseBlock.blue .backgroundImage:after {
  background: #017CCB;
}
a.expertiseBlock.red .backgroundImage:after {
  background: #E03501;
}
a.expertiseBlock.orange .backgroundImage:after {
  background: #F19600;
}
a.expertiseBlock.green .backgroundImage:after {
  background: #8EBD02;
}
a.expertiseBlock.lastActive {
  z-index: 2;
}

.serviceHeader {
  position: relative;
}
@media screen and (max-width: 1000px) {
  .serviceHeader {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -30px;
  }
}
.serviceHeader .image:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(83, 32, 120, 0.15);
  background: linear-gradient(to bottom, rgba(83, 32, 120, 0.15) 0%, rgba(83, 32, 120, 0.15) 50%, #2A103C 100%);
}
.serviceHeader .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.serviceHeader h1 {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0 48px 40px;
  color: white;
  z-index: 2;
}
@media screen and (max-width: 1000px) {
  .serviceHeader h1 {
    padding: 0 40px 30px;
  }
}

header.wizard {
  border-bottom: none;
}
header.wizard a.logo {
  margin: 0;
  position: absolute;
  top: 20px;
  width: 100px;
}
@media screen and (max-width: 880px) {
  header.wizard a.logo {
    top: 10px;
  }
}
@media screen and (max-width: 767px) {
  header.wizard a.logo {
    float: left;
    width: 30%;
  }
}
header.wizard ul.steps {
  list-style: none;
  margin: 41px auto;
  max-width: 980px;
  padding: 0;
  width: 100%;
}
@media screen and (max-width: 1220px) {
  header.wizard ul.steps {
    float: right;
  }
}
@media screen and (max-width: 1100px) {
  header.wizard ul.steps {
    margin-top: 100px;
  }
}
@media screen and (max-width: 980px) {
  header.wizard ul.steps {
    float: none;
  }
}
@media screen and (max-width: 880px) {
  header.wizard ul.steps {
    margin-bottom: 0;
    margin-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  header.wizard ul.steps {
    float: right;
    margin-top: 27px;
    width: 60%;
  }
}
header.wizard ul.steps li {
  float: left;
  position: relative;
  text-align: center;
  width: 25%;
}
header.wizard ul.steps li:after {
  background: url(/css/img/binding.svg) no-repeat scroll center center/100% auto;
  content: "";
  display: block;
  height: 20px;
  left: calc(50% + 15px);
  position: absolute;
  top: -15px;
  width: calc(100% - 30px);
}
@media screen and (max-width: 767px) {
  header.wizard ul.steps li:after {
    display: none;
  }
}
header.wizard ul.steps li.active a {
  color: #67338B;
}
@media screen and (max-width: 767px) {
  header.wizard ul.steps li.active a {
    color: transparent;
  }
}
header.wizard ul.steps li.active a:before {
  background: #C343B6;
}
header.wizard ul.steps li.done a {
  color: #8EBD02;
}
@media screen and (max-width: 767px) {
  header.wizard ul.steps li.done a {
    color: transparent;
  }
}
header.wizard ul.steps li.done a:before {
  background: rgba(142, 189, 2, 0.35);
}
header.wizard ul.steps li.done a:after {
  color: #739600;
  content: "\e809";
  display: block;
  font-family: "helioIcon";
  font-size: 15px;
  height: 15px;
  left: calc(50% - 4px);
  margin: 0 auto 12px;
  position: absolute;
  top: -5px;
  width: 15px;
}
header.wizard ul.steps li:last-child:after {
  display: none;
}
header.wizard ul.steps li a {
  color: #999799;
  display: block;
  font-family: "latobold";
  text-decoration: none;
}
@media screen and (max-width: 880px) {
  header.wizard ul.steps li a {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  header.wizard ul.steps li a {
    color: transparent;
    height: 15px;
  }
}
header.wizard ul.steps li a:before {
  background: #E6DFDA;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 15px;
  margin: 0 auto 12px;
  width: 15px;
}

div.wizardHolder {
  margin-bottom: 20px;
  margin-top: 36px;
}
@media screen and (max-width: 767px) {
  div.wizardHolder {
    margin-bottom: 80px;
    margin-top: 28px;
  }
}
div.wizardHolder div.step div.header {
  background: #F9F7F6;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  border-left: 1px solid #E6DFDA;
  border-right: 1px solid #E6DFDA;
  border-top: 1px solid #E6DFDA;
  padding: 21px 15px 5px;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.header {
    padding-top: 10px;
  }
}
div.wizardHolder div.step div.header h1 {
  color: #532078;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.header h1 {
    font-size: 20px;
    line-height: 27px;
  }
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.header h1 {
    margin-bottom: 10px;
  }
}
div.wizardHolder div.step div.content {
  min-height: 440px;
}
@media screen and (max-width: 1220px) {
  div.wizardHolder div.step div.content {
    min-height: 300px;
  }
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content {
    min-height: 0;
  }
}
div.wizardHolder div.step div.content:after, div.wizardHolder div.step div.content:before {
  content: "";
  display: table;
}
div.wizardHolder div.step div.content:after {
  clear: both;
}
div.wizardHolder div.step div.content div.themeBlocks {
  background: #E6DFDA;
  width: 100%;
}
div.wizardHolder div.step div.content div.themeBlocks:after, div.wizardHolder div.step div.content div.themeBlocks:before {
  content: "";
  display: table;
}
div.wizardHolder div.step div.content div.themeBlocks:after {
  clear: both;
}
div.wizardHolder div.step div.content div.themeBlocks label {
  cursor: pointer;
  float: left;
  position: relative;
  width: 50%;
}
@media screen and (max-width: 620px) {
  div.wizardHolder div.step div.content div.themeBlocks label {
    width: 50%;
  }
}
div.wizardHolder div.step div.content div.themeBlocks label > input[type=radio] {
  display: none;
}
div.wizardHolder div.step div.content div.themeBlocks label > input[type=radio]:checked + span.image + span.title:before {
  font-size: 24px;
  margin-left: -36px;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}
div.wizardHolder div.step div.content div.themeBlocks label span.image {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 37.3%;
  width: 100%;
}
div.wizardHolder div.step div.content div.themeBlocks label span.image img {
  image-rendering: optimizeQuality;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: transform 15s linear;
  -moz-transition: transform 15s linear;
  -ms-transition: transform 15s linear;
  -o-transition: transform 15s linear;
  transition: transform 15s linear;
  vertical-align: top;
  width: 100%;
}
div.wizardHolder div.step div.content div.themeBlocks label:hover span.image img {
  -moz-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  transform: scale(2);
  -moz-transform: rotate(0.1degdeg);
  -o-transform: rotate(0.1degdeg);
  -ms-transform: rotate(0.1degdeg);
  -webkit-transform: rotate(0.1degdeg);
  transform: rotate(0.1degdeg);
}
div.wizardHolder div.step div.content div.themeBlocks label span.title {
  color: #ffffff;
  display: block;
  font-family: "latobold";
  font-size: 24px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content div.themeBlocks label span.title {
    font-size: 20px;
  }
}
@media screen and (max-width: 620px) {
  div.wizardHolder div.step div.content div.themeBlocks label span.title {
    font-size: 18px;
  }
}
div.wizardHolder div.step div.content div.themeBlocks label span.title:before {
  content: "\e809";
  display: inline;
  font-family: "helioIcon";
  font-size: 0;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out 0.3s;
  -moz-transition: all 0.2s ease-in-out 0.3s;
  -ms-transition: all 0.2s ease-in-out 0.3s;
  -o-transition: all 0.2s ease-in-out 0.3s;
  transition: all 0.2s ease-in-out 0.3s;
}
div.wizardHolder div.step div.content.disabilities {
  border: 1px solid #E6DFDA;
  padding: 37px 67px 40px;
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content.disabilities {
    padding: 10px 15px 30px;
  }
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList {
  float: left;
  list-style: none;
  padding: 0 20px 0 0;
  width: 33%;
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content.disabilities ul.disabiltyList {
    float: none;
    margin-bottom: -10px;
    padding: 0;
    width: 100%;
  }
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList:after, div.wizardHolder div.step div.content.disabilities ul.disabiltyList:before {
  content: "";
  display: table;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList:after {
  clear: both;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li {
  display: block;
  margin: 10px 0;
  width: 100%;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li.letter {
  display: block;
  position: relative;
  width: 100%;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li.letter span {
  color: rgba(1, 62, 102, 0.5);
  font-family: "latobold";
  font-size: 18px;
  left: 0;
  line-height: 28px;
  position: absolute;
  top: 4px;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.content.disabilities ul.disabiltyList li.letter span {
    display: block;
    padding: 5px 12px 0;
    position: relative;
    top: 0;
  }
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li.letter:first-child + li a:before {
  display: none;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.content.disabilities ul.disabiltyList li.letter:first-child + li a:before {
    display: block;
  }
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label {
  cursor: pointer;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label:after, div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label:before {
  content: "";
  display: table;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label:after {
  clear: both;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label span {
  background: #D2E7F7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #187CCF;
  display: inline-block;
  font-family: "latobold";
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 0 38px;
  padding: 4px 12px 7px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label span {
    margin: 0;
  }
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label > input[type=radio] {
  display: none;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label > input[type=radio]:checked + span {
  background: #017CCB;
  color: #ffffff;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label:before {
  content: "\e809";
  display: inline;
  font-family: "helioIcon";
  font-size: 0;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out 0.3s;
  -moz-transition: all 0.2s ease-in-out 0.3s;
  -ms-transition: all 0.2s ease-in-out 0.3s;
  -o-transition: all 0.2s ease-in-out 0.3s;
  transition: all 0.2s ease-in-out 0.3s;
}
div.wizardHolder div.step div.content.disabilities ul.disabiltyList li label:hover span {
  background: #017CCB;
  color: #ffffff;
}
div.wizardHolder div.step div.content.ages {
  border: 1px solid #E6DFDA;
  text-align: center;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.content.ages {
    padding: 16px 0 10px;
  }
}
div.wizardHolder div.step div.content.ages > label {
  cursor: pointer;
  display: inline-block;
  margin: 119px 8px 0;
  max-width: 200px;
  width: 20%;
}
@media screen and (max-width: 1220px) {
  div.wizardHolder div.step div.content.ages > label {
    margin: 49px 8px 0;
  }
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content.ages > label {
    margin: 29px 8px;
  }
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.content.ages > label {
    margin: 8px;
    width: 40%;
  }
}
div.wizardHolder div.step div.content.ages > label > input[type=radio] {
  display: none;
}
div.wizardHolder div.step div.content.ages > label > input[type=radio]:checked + span {
  background: #187CCF;
  color: #ffffff;
}
div.wizardHolder div.step div.content.ages > label > span {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: #D2E7F7;
  color: #017CCB;
  display: block;
  font-family: "latobold";
  height: 0;
  max-height: 200px;
  padding-bottom: 100%;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.wizardHolder div.step div.content.ages > label > span > span.text {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
div.wizardHolder div.step div.content.ages > label > span > span.text span.num {
  display: block;
  font-size: 31px;
  line-height: 38px;
  margin-bottom: 12px;
}
div.wizardHolder div.step div.content.ages > label > span > span.text > span.year {
  opacity: 0.75;
  filter: alpha(opacity=75);
  pointer-events: all;
  font-size: 18px;
}
div.wizardHolder div.step div.content.ages > label:hover > span {
  background: #187CCF;
  color: #ffffff;
}
div.wizardHolder div.step div.content.result {
  border: 1px solid #E6DFDA;
}
div.wizardHolder div.step div.content.result div.serviceWidget {
  margin: 43px auto 44px;
  max-width: 780px;
}
@media screen and (max-width: 880px) {
  div.wizardHolder div.step div.content.result div.serviceWidget {
    margin: 20px 15px;
  }
}
div.wizardHolder div.step div.content.result div.serviceWidget div.resultsHolder {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
div.wizardHolder div.step div.content.result div.serviceWidget div.resultsHolder ul.resultList {
  margin-bottom: 23px;
  margin-top: 18px;
}
div.wizardHolder div.step div.footer {
  background: #F9F7F6;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  border-left: 1px solid #E6DFDA;
  border-right: 1px solid #E6DFDA;
  border-bottom: 1px solid #E6DFDA;
  padding: 30px 27px 23px;
  position: relative;
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.footer {
    padding: 15px 13px 13px;
  }
}
div.wizardHolder div.step div.footer:after, div.wizardHolder div.step div.footer:before {
  content: "";
  display: table;
}
div.wizardHolder div.step div.footer:after {
  clear: both;
}
div.wizardHolder div.step div.footer > button {
  background: transparent;
  border: none;
  color: #D54D12;
  cursor: pointer;
  float: right;
  font-size: 16px;
  margin-left: 40px;
}
div.wizardHolder div.step div.footer > button:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 25px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
div.wizardHolder div.step div.footer > button:hover:after {
  margin: 1px 5px 0 6px;
}
div.wizardHolder div.step div.footer > p.subResult {
  color: #734A95;
  display: block;
  float: right;
  font-family: "latobold";
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.footer > p.subResult {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  div.wizardHolder div.step div.footer a.backLink {
    bottom: -40px;
    position: absolute;
  }
}

div.line {
  background: #E6DFDA;
  float: left;
  height: 1px;
  margin: 33px 0;
  width: 100%;
}
div.line:after, div.line:before {
  content: "";
  display: table;
}
div.line:after {
  clear: both;
}

section.contributors {
  padding-top: 25px;
}
section.contributors span.title {
  color: #504D4C;
  font-family: "latobold";
  font-size: 24px;
}
section.contributors span.title > span {
  font-family: "latoregular";
  font-size: 21px;
  margin-left: 25px;
}
@media screen and (max-width: 1000px) {
  section.contributors span.title > span {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}
section.contributors ul.people {
  list-style: none;
  margin: 0 0 26px;
  padding: 0;
}
section.contributors ul.people:after, section.contributors ul.people:before {
  content: "";
  display: table;
}
section.contributors ul.people:after {
  clear: both;
}
section.contributors ul.people li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  float: left;
  margin-bottom: 4px;
  margin-right: 1.6949152542%;
  margin-top: 39px;
  max-height: 400px;
  overflow: hidden;
  width: 23.7288135593%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 1000px) {
  section.contributors ul.people li {
    width: 49.1525423729%;
  }
}
@media screen and (max-width: 767px) {
  section.contributors ul.people li {
    display: block;
    margin-top: 19px;
  }
}
@media screen and (min-width: 1000px) {
  section.contributors ul.people li:nth-child(4n+4) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1000px) {
  section.contributors ul.people li:nth-child(4n+5) {
    clear: left;
  }
}
@media screen and (max-width: 1000px) {
  section.contributors ul.people li:nth-child(2n+2) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  section.contributors ul.people li:nth-child(2n+3) {
    clear: left;
  }
}
section.contributors ul.people li.hidden {
  margin-top: 0;
  max-height: 0;
}
section.contributors ul.people li > div.image {
  float: left;
  margin-right: 6%;
  max-width: 100px;
  width: 36%;
}
@media screen and (max-width: 767px) {
  section.contributors ul.people li > div.image {
    float: none;
    width: 100%;
  }
}
section.contributors ul.people li > div.image img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin: 6px 0;
  max-width: 100%;
  vertical-align: top;
}
section.contributors ul.people li > div.content {
  float: left;
  font-size: 16px;
  line-height: 23px;
  position: relative;
  width: 58%;
}
@media screen and (max-width: 767px) {
  section.contributors ul.people li > div.content {
    float: none;
    padding-bottom: 37px;
    width: 100%;
  }
}
section.contributors ul.people li > div.content > div {
  margin-bottom: 37px;
}
@media screen and (max-width: 767px) {
  section.contributors ul.people li > div.content > div {
    margin-bottom: 0;
  }
}
section.contributors ul.people li > div.content > div > span.name {
  display: block;
  font-family: "latobold";
}
section.contributors ul.people li > div.content > a.moreLink {
  bottom: 0;
  left: 0;
  position: absolute;
}
section.contributors .showMore {
  color: #D54D12;
  cursor: pointer;
  float: left;
  font-size: 16px;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 767px) {
  section.contributors .showMore {
    float: none;
    margin-top: 25px;
    width: 100%;
  }
}
section.contributors .showMore:before {
  color: #EAA688;
  content: "\e807";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 7px;
  left: 0;
  margin: 2px 15px 0 0;
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
section.contributors .showMore:hover:before {
  margin: 4px 15px 2px 0;
}
@media screen and (max-width: 767px) {
  section.contributors .showMore:hover:before {
    margin: 6px 15px 4px 0;
  }
}
section.contributors .showMore .more {
  display: inline;
}
section.contributors .showMore .less {
  display: none;
}
section.contributors .showMore.showLess:before {
  content: "\e803";
}
section.contributors .showMore.showLess:hover:before {
  margin: -1px 15px 3px 0;
}
@media screen and (max-width: 767px) {
  section.contributors .showMore.showLess:hover:before {
    margin: -2px 15px 4px 0;
  }
}
section.contributors .showMore.showLess .less {
  display: inline;
}
section.contributors .showMore.showLess .more {
  display: none;
}

section.gallery {
  clear: left;
  margin: 100px 0 90px;
  position: relative;
}
section.gallery:after, section.gallery:before {
  content: "";
  display: table;
}
section.gallery:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  section.gallery {
    margin-top: 30px;
  }
}
section.gallery img {
  vertical-align: top;
}

section.locationContact {
  margin-top: 160px;
}
@media screen and (max-width: 767px) {
  section.locationContact {
    margin-top: 80px;
  }
}
section.locationContact div.blockHolder {
  background-image: url(/tempimg/maps.jpg);
  background-position: center center;
  background-size: cover;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  padding: 81px 0 75px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  section.locationContact div.blockHolder {
    padding: 10px 20px;
  }
}
section.locationContact div.blockHolder > div {
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: inline-block;
  margin: 15px 28px;
  max-width: 360px;
  min-height: 315px;
  text-align: left;
  vertical-align: top;
  width: 100%;
}
@media screen and (max-width: 767px) {
  section.locationContact div.blockHolder > div {
    margin: 10px 0;
    max-width: 100%;
    min-height: 0;
  }
}
section.locationContact div.blockHolder > div > span.title {
  border-bottom: 1px solid #E6DFDA;
  color: #9F9D9F;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  line-height: 24px;
  padding: 20px 25px 17px;
  text-transform: uppercase;
}
section.locationContact div.blockHolder > div.openingTimes > span.title {
  padding-bottom: 16px;
}
section.locationContact div.blockHolder > div.openingTimes > span.title:before {
  color: #999799;
  content: "\e80b";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 15px;
  margin-right: 12px;
}
section.locationContact div.blockHolder > div > div.content {
  padding: 11px 25px;
}
section.locationContact div.blockHolder > div > div.content > span.name {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  line-height: 23px;
  margin: 4px 0 6px;
  padding-left: 28px;
  position: relative;
}
section.locationContact div.blockHolder > div > div.content > span.name:before {
  color: #999799;
  content: "\e80a";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 12px;
  position: absolute;
  left: 0;
}
section.locationContact div.blockHolder > div > div.content > p {
  font-size: 15px;
  line-height: 21px;
  padding: 0 0 15px 28px;
}
section.locationContact div.blockHolder > div > div.content > a,
section.locationContact div.blockHolder > div > div.content .gmapsLink {
  color: #D54D12;
  display: block;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
  padding-left: 28px;
  position: relative;
  text-decoration: none;
}
section.locationContact div.blockHolder > div > div.content > a:before,
section.locationContact div.blockHolder > div > div.content .gmapsLink:before {
  color: #999799;
  display: inline-block;
  font-size: 15px;
  position: absolute;
  left: 0;
}
section.locationContact div.blockHolder > div > div.content > a:hover,
section.locationContact div.blockHolder > div > div.content .gmapsLink:hover {
  text-decoration: underline;
}
section.locationContact div.blockHolder > div > div.content a.gmapsLink {
  padding-left: 0;
}
section.locationContact div.blockHolder > div > div.content a.tel:before {
  content: "T";
}
section.locationContact div.blockHolder > div > div.content a.email:before {
  content: "E";
}
section.locationContact div.blockHolder > div > div.content > table {
  font-size: 15px;
  line-height: 31px;
  width: 100%;
}
section.locationContact div.blockHolder > div > div.content div.socials {
  margin: 30px 0 12px;
  text-align: left;
  padding-left: 28px;
}
section.locationContact div.blockHolder > div > div.content div.socials > a {
  display: inline-block;
  margin-right: 5px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
section.locationContact div.blockHolder > div > div.content div.socials > a:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
  pointer-events: all;
}

section.timeline {
  background: #431A66;
  margin: 38px 0 0;
  overflow: hidden;
  padding: 51px 0 109px;
  position: relative;
}
section.timeline h2 {
  color: #ffffff;
  line-height: 35px;
  font-size: 22px;
  margin-bottom: 67px;
  position: relative;
  text-align: center;
}
section.timeline h2:after {
  background: #56327B;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  top: 169px;
  width: 100%;
}
@media screen and (max-width: 930px) {
  section.timeline .container {
    padding: 0 35px;
  }
}
section.timeline .socialLine .owl-stage-outer {
  overflow: visible;
}
section.timeline .socialLine .owl-controls .owl-nav div {
  background: #FB59F4;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 5px #431A66;
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 5px #431A66;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 5px #431A66;
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  top: 68px;
}
section.timeline .socialLine .owl-controls .owl-nav div.owl-next {
  right: 0;
}
section.timeline .socialLine .owl-controls .owl-nav div.owl-next.disabled {
  right: -10px;
}
section.timeline .socialLine .owl-controls .owl-nav div.owl-prev {
  left: 0;
}
section.timeline .socialLine .owl-controls .owl-nav div.owl-prev.disabled {
  left: -10px;
}
section.timeline .socialLine .owl-controls .owl-nav div.disabled {
  display: block !important;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
}
section.timeline .socialLine .owl-controls .owl-nav div:hover {
  background: #DC5FD7;
}
section.timeline .socialLine .owl-dots {
  display: none !important;
}
section.timeline .socialLine a {
  position: relative;
  text-decoration: none;
}
section.timeline .socialLine a > span.date {
  color: rgba(243, 240, 246, 0.5);
  display: block;
  font-size: 16px;
  margin-bottom: 25px;
  text-align: center;
}
section.timeline .socialLine a > span.date:after {
  content: "";
  display: block;
  height: 50px;
  margin: 26px auto 0;
  width: 50px;
}
section.timeline .socialLine a > span.content {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #504D4C;
  position: relative;
}
section.timeline .socialLine a > span.content:before {
  background: #56327B;
  content: "";
  display: block;
  height: 25px;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: -25px;
  width: 2px;
}
section.timeline .socialLine a > span.content img {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
section.timeline .socialLine a > span.content img + p {
  font-size: 15px;
  line-height: 24px;
  padding: 16px 23px 22px;
}
section.timeline .socialLine a > span.content p {
  font-size: 21px;
  line-height: 31px;
  padding: 31px 31px 33px;
}
section.timeline .socialLine a > span.content > .overlay {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 100%;
}
section.timeline .socialLine a > span.content > .overlay > span {
  color: #ffffff;
  display: block;
  font-family: "latobold";
  font-size: 18px;
  position: relative;
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
section.timeline .socialLine a > span.content > .overlay > span > span {
  display: block;
  height: 40px;
  margin-bottom: 14px;
  width: 100%;
}
section.timeline .socialLine a > span.content:hover > .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
}
section.timeline .socialLine a.twitter > span.date:after {
  background: url(/css/img/icn_twitter.svg) no-repeat center center;
}
section.timeline .socialLine a.twitter > span.content > .overlay {
  background: rgba(46, 160, 246, 0.95);
}
section.timeline .socialLine a.twitter > span.content > .overlay > span > span {
  background: url(/css/img/icn_tw_wh.svg) no-repeat center center;
}
section.timeline .socialLine a.facebook > span.date:after {
  background: url(/css/img/icn_facebook.svg) no-repeat center center;
}
section.timeline .socialLine a.facebook > span.content > .overlay {
  background: rgba(94, 129, 213, 0.95);
}
section.timeline .socialLine a.facebook > span.content > .overlay > span > span {
  background: url(/css/img/icn_fb_wh.svg) no-repeat center center;
}
section.timeline .socialLine a.instagram > span.date:after {
  background: url(/css/img/icn_instagram.svg) no-repeat center center;
}
section.timeline .socialLine a.instagram > span.content > .overlay {
  background: rgba(217, 201, 185, 0.95);
}
section.timeline .socialLine a.instagram > span.content > .overlay > span > span {
  background: url(/css/img/icn_in_wh.svg) no-repeat center center;
}

section.overview {
  padding: 0 0 56px;
}

.overview ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.overview ul:after, .overview ul:before {
  content: "";
  display: table;
}
.overview ul:after {
  clear: both;
}
@media screen and (max-width: 1000px) {
  .overview ul {
    text-align: center;
  }
}
.overview ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 -4px 20px 1.6949152542%;
}
@media screen and (min-width: 930px) {
  .overview ul li {
    width: 23.7288135593%;
  }
}
@media screen and (max-width: 767px) {
  .overview ul li {
    display: inline-block;
    float: none;
  }
}
@media screen and (min-width: 930px) {
  .overview ul li:first-child, .overview ul li:nth-child(4n+1) {
    margin-left: 0;
  }
}
.overview a {
  background: #ffffff;
  border: 1px solid #E6DFDA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  height: 100%;
  max-width: 280px;
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 360px) {
  .overview a {
    max-width: 250px;
  }
}
.overview a > img {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  vertical-align: top;
}
.overview a span.content {
  display: block;
  min-height: 216px;
  max-height: 340px;
}
.overview a span.quote {
  color: #57257B;
  display: block;
  font-family: "latobold_italic";
  font-size: 21px;
  line-height: 26px;
  margin: 24px 20px 18px 30px;
  position: relative;
  padding: 0 0 0 7.9%;
  height: 140px;
}
.overview a span.quote:before {
  background: rgba(87, 37, 123, 0.5);
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 6.8%;
}
.overview a span.person {
  display: block;
  margin: 0 30px;
  min-height: 280px;
}
.overview a span.person > span.name {
  color: #504D4C;
  display: block;
  font-family: "latoblack";
  font-size: 16px;
  line-height: 22px;
  margin-top: 21px;
  margin-bottom: 5px;
}
.overview a span.person > span.function {
  color: #504D4C;
  display: block;
  font-size: 15px;
  line-height: 21px;
}
.overview a span.moreLink {
  color: #D54D12;
  display: inline-block;
  font-family: "latobold";
  font-size: 15px;
  margin: 0 0 26px 30px;
}
.overview a span.moreLink:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 19px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
.overview a:hover {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.overview a:hover > span.moreLink:after {
  margin: 1px 5px 0 6px;
}
.overview.locations {
  padding: 0 0 69px;
}
.overview.locations a > span.contentHolder {
  color: #504D4C;
  font-size: 15px;
  line-height: 21px;
  display: block;
  margin: 21px 30px 10px;
  min-height: 253px;
}
.overview.locations a > span.contentHolder > span.name {
  display: block;
  font-family: "latobold";
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
  position: relative;
}
.overview.locations a > span.contentHolder > span.name:before {
  color: #999799;
  content: "\e80a";
  display: inline-block;
  font-family: "helioIcon";
  font-size: 13px;
  left: -17px;
  position: absolute;
  top: 1px;
}
.overview.locations a > span.contentHolder > span.name > span {
  display: block;
}
.overview.locations a > span.contentHolder > span.telNum {
  display: block;
  font-size: 15px;
  line-height: 21px;
  margin: 12px 0 0;
  padding-left: 18px;
  position: relative;
  text-decoration: none;
}
.overview.locations a > span.contentHolder > span.telNum:before {
  color: #999799;
  content: "T";
  display: inline-block;
  font-size: 15px;
  position: absolute;
  left: 0;
}
.overview.locations a > span.contentHolder > span.areas {
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 33px 0 54px;
}
.overview.locations a > span.contentHolder > span.areas > strong {
  display: block;
}
.overview.locations a .imageContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 55.66%;
}
.overview.locations a .imageContainer img {
  background: #E6DFDA;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 930px) {
  .overview.actual li {
    width: 49.1525423729%;
  }
}
@media screen and (max-width: 767px) {
  .overview.actual li {
    display: inline-block;
    float: none;
  }
}
@media screen and (min-width: 930px) {
  .overview.actual li:first-child, .overview.actual li:nth-child(2n+1) {
    clear: left;
    margin-left: 0;
  }
}
.overview.actual li a.actualBlock {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 27px 28px 0;
}
.overview.actual li a.actualBlock > span.content {
  flex: 1;
  min-height: 0;
}
.overview.actual li a.actualBlock > span.content > span.image {
  width: 53.8%;
}
@media screen and (max-width: 767px) {
  .overview.actual li a.actualBlock > span.content > span.image {
    width: 100%;
  }
}
.overview.actual li a.actualBlock > span.content > span.text {
  width: 42.7%;
}
@media screen and (max-width: 767px) {
  .overview.actual li a.actualBlock > span.content > span.text {
    width: 100%;
  }
}
.overview.actual li a.actualBlock > span.moreLink {
  margin: 13px 0 24px;
}
.overview div.pagerHolder > ul > li {
  width: auto;
}

div.pagerHolder {
  padding: 42px 0 21px;
  width: 100%;
}
div.pagerHolder:after, div.pagerHolder:before {
  content: "";
  display: table;
}
div.pagerHolder:after {
  clear: both;
}
div.pagerHolder > ul {
  text-align: center;
  width: 100%;
}
div.pagerHolder > ul > li {
  display: inline-block;
  float: none;
  font-family: "latobold";
  font-size: 16px;
  width: auto;
}
div.pagerHolder > ul > li .pageItem, div.pagerHolder > ul > li span, div.pagerHolder > ul > li a {
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  min-width: 30px;
  padding: 6px 5px;
  text-align: center;
}
div.pagerHolder > ul > li a {
  color: #D54D12;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.pagerHolder > ul > li a:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #532078;
}
div.pagerHolder > ul > li a .next,
div.pagerHolder > ul > li a .prev {
  color: #D54D12;
}
div.pagerHolder > ul > li a .next:hover:after,
div.pagerHolder > ul > li a .prev:hover:after {
  margin: 1px 5px 0 6px;
}
div.pagerHolder > ul > li a .next:hover:before,
div.pagerHolder > ul > li a .prev:hover:before {
  margin: 1px 6px 0 5px;
}
div.pagerHolder > ul > li a .next:before, div.pagerHolder > ul > li a .next:after,
div.pagerHolder > ul > li a .prev:before,
div.pagerHolder > ul > li a .prev:after {
  color: #EAA688;
}
div.pagerHolder > ul > li span {
  color: #9F9D9F;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.pagerHolder > ul > li span.active {
  background: #DDD2E4;
  color: #532078;
}
div.pagerHolder > ul > li span .next,
div.pagerHolder > ul > li span .prev {
  color: #9F9D9F;
}
div.pagerHolder > ul > li span .next:before, div.pagerHolder > ul > li span .next:after,
div.pagerHolder > ul > li span .prev:before,
div.pagerHolder > ul > li span .prev:after {
  color: #9F9D9F;
}
div.pagerHolder > ul > li .next {
  text-decoration: none;
}
div.pagerHolder > ul > li .next:hover:after {
  margin: 1px 5px 0 6px;
}
div.pagerHolder > ul > li .next:after {
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 9px;
  line-height: 21px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
div.pagerHolder > ul > li .prev {
  text-decoration: none;
}
div.pagerHolder > ul > li .prev:hover:before {
  margin: 1px 6px 0 5px;
}
div.pagerHolder > ul > li .prev:before {
  content: "\e806";
  display: inline;
  font-family: "helioIcon";
  font-size: 10px;
  line-height: 22px;
  margin: 1px 11px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
div.pagerHolder > ul > li:first-child {
  float: left;
}
div.pagerHolder > ul > li:last-child {
  float: right;
}

section.searchHeader {
  background: url(/tempimg/searchHero.jpg) repeat center 23%;
  padding: 70px 0;
}
section.searchHeader div.searchBox {
  background: #F2EBE6;
  border: 1px solid #BFBAB6;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin: 0 auto;
  max-width: 780px;
  position: relative;
  width: 100%;
}
section.searchHeader div.searchBox input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  font-family: "latoregular";
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  padding: 13px 48px 13px 14px;
  width: 100%;
}
section.searchHeader div.searchBox input[type=search]:focus {
  outline: none;
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0;
}
section.searchHeader div.searchBox button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
}
section.searchHeader div.searchBox button:after {
  color: #999999;
  content: "\e804";
  cursor: pointer;
  font-family: "helioIcon";
  font-size: 20px;
}
section.searchHeader div.searchBox button:after:before {
  color: #532078;
}

@media screen and (max-width: 1000px) {
  html.no-js section.searchResults .col3.right div.searchFilters > .filterTitle {
    display: block;
  }
}
html.no-js section.searchResults .col3.right div.searchFilters form button {
  display: inline-block;
  margin: 0 16px 14px;
}

section.searchResults {
  padding: 45px 0 66px;
}
section.searchResults .col3.right {
  margin-left: 1.6949152542%;
  float: right;
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right {
    margin-left: 0;
    float: left;
    width: 100%;
  }
}
section.searchResults .col3.right div.searchFilters {
  background: #ffffff;
  border: 1px solid #E6DFDA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  line-height: 24px;
  margin: 0 0 40px;
  padding: 29px 30px 17px;
  width: 100%;
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters {
    padding: 0;
  }
}
section.searchResults .col3.right div.searchFilters > .filterTitle {
  color: #4D4D4D;
  display: block;
  font-family: "latobold";
  margin: 0 0 17px;
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters > .filterTitle {
    display: none;
  }
}
section.searchResults .col3.right div.searchFilters > span.openSub {
  display: none;
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters > span.openSub {
    color: #D54D12;
    cursor: pointer;
    display: block;
    font-family: "latoregular";
    font-size: 16px;
    margin: 0;
    padding: 12px 23px 12px 15px;
  }
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters > span.openSub:after {
    color: #EAA688;
    content: "\e807";
    display: inline-block;
    float: right;
    font-family: "helioIcon";
    font-size: 8px;
    margin: 2px 0 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
section.searchResults .col3.right div.searchFilters.showSub > span.openSub:after {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters.showSub ul {
    max-height: 600px;
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: all;
  }
}
section.searchResults .col3.right div.searchFilters button {
  display: none;
}
section.searchResults .col3.right div.searchFilters ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1000px) {
  section.searchResults .col3.right div.searchFilters ul {
    margin: 0 16px;
    max-height: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    pointer-events: none;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-size: 15px;
  }
}
section.searchResults .col3.right div.searchFilters ul li {
  padding-bottom: 14px;
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked),
section.searchResults .col3.right div.searchFilters [type=checkbox]:checked {
  position: absolute;
  left: -99999px;
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked) + label,
section.searchResults .col3.right div.searchFilters [type=checkbox]:checked + label {
  display: inline-block;
  cursor: pointer;
  height: 24px;
  padding: 0 0 0 31px;
  position: relative;
}
@media screen and (max-width: 767px) {
  section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked) + label,
  section.searchResults .col3.right div.searchFilters [type=checkbox]:checked + label {
    float: none;
  }
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked) + label:before,
section.searchResults .col3.right div.searchFilters [type=checkbox]:checked + label:before {
  background: rgba(242, 235, 230, 0.5);
  border: 1px solid #BFBAB6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  height: 20px;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  width: 20px;
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked) + label:after,
section.searchResults .col3.right div.searchFilters [type=checkbox]:checked + label:after {
  color: #504D4C;
  content: "\e809";
  position: absolute;
  top: 6px;
  left: 5px;
  font-family: "helioIcon";
  font-size: 10px;
  line-height: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:checked + label:after {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
section.searchResults .col3.right div.searchFilters [type=checkbox]:disabled + label {
  opacity: 0.45;
  filter: alpha(opacity=45);
  pointer-events: all;
}
section.searchResults .col9 {
  margin-left: 0;
}
section.searchResults .col9 > ul {
  list-style: none;
  margin: 49px 0 0;
  padding: 0;
}
section.searchResults .col9 > ul li a {
  background: #ffffff;
  border: 1px solid #E6DFDA;
  border-left: 6px solid #E7E1DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: block;
  margin-top: 28px;
  padding: 16px 39px 24px 25px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
section.searchResults .col9 > ul li a:hover {
  background: rgba(242, 239, 236, 0.5);
}
section.searchResults .col9 > ul li a span.title {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  font-size: 21px;
  line-height: 31px;
}
section.searchResults .col9 > ul li a span.url {
  color: #999799;
  display: block;
  font-size: 15px;
  line-height: 24px;
}
section.searchResults .col9 > ul li a span.summary {
  color: #504D4C;
  display: block;
  font-size: 18px;
  line-height: 30px;
  margin: 15px 0 6px;
}

div.wizardBanner {
  background: #E8F3FB;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  color: #003D65;
  margin: 28px 0 15px;
  padding: 21px 17px 17px;
}
div.wizardBanner span {
  display: inline-block;
  margin-right: 11px;
}
@media screen and (max-width: 767px) {
  div.wizardBanner span {
    margin-bottom: 5px;
    display: block;
  }
}

@media screen and (max-width: 1000px) {
  div.moreInfoCol {
    width: 100%;
  }
}

div.formHolder,
.col-md-12 > div > div.contour {
  background: #F5ECF3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  border: 1px solid rgba(156, 72, 156, 0.3);
  border-left: 6px solid #9C489C;
}
div.formHolder:after, div.formHolder:before,
.col-md-12 > div > div.contour:after,
.col-md-12 > div > div.contour:before {
  content: "";
  display: table;
}
div.formHolder:after,
.col-md-12 > div > div.contour:after {
  clear: both;
}
div.formHolder .formHolder,
.col-md-12 > div > div.contour .formHolder {
  border: none !important;
}
div.formHolder.normal div.title > h1,
.col-md-12 > div > div.contour.normal div.title > h1 {
  color: #003D65;
}
div.formHolder.normal div.form input[type=submit],
.col-md-12 > div > div.contour.normal div.form input[type=submit] {
  background: #017CCB;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "latobold";
  font-size: 16px;
  color: #FFFFFF;
  line-height: 29px;
  margin: 33px 30px 31px 72px;
  padding: 9px 19px 11px 17px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.formHolder.normal div.form input[type=submit]:hover,
.col-md-12 > div > div.contour.normal div.form input[type=submit]:hover {
  background: rgba(1, 124, 203, 0.35);
}
div.formHolder .help-block,
.col-md-12 > div > div.contour .help-block {
  font-style: italic;
  color: #999799;
}
div.formHolder div.title,
.col-md-12 > div > div.contour div.title {
  border-bottom: 43px solid #ffffff;
  padding: 18px 35px;
}
@media screen and (max-width: 1100px) {
  div.formHolder div.title,
  .col-md-12 > div > div.contour div.title {
    border-bottom: 17px solid #ffffff;
    padding: 14px 25px;
  }
}
div.formHolder div.title > svg,
.col-md-12 > div > div.contour div.title > svg {
  display: inline-block;
  margin-right: 22px;
  vertical-align: top;
}
div.formHolder div.title > h1,
.col-md-12 > div > div.contour div.title > h1 {
  display: inline-block;
  font-family: "latoregular";
  font-size: 24px;
  line-height: 22px;
  margin: 18px 0 22px;
  vertical-align: top;
}
div.formHolder div.title > h1 > strong,
.col-md-12 > div > div.contour div.title > h1 > strong {
  font-family: "latoblack";
}
div.formHolder div.requestTitle,
.col-md-12 > div > div.contour div.requestTitle {
  background: #ffffff;
  color: #504D4C;
  font-size: 18px;
  line-height: 30px;
  padding-left: 68px;
}
div.formHolder div.formIntro,
.col-md-12 > div > div.contour div.formIntro {
  background: #ffffff;
  color: #999799;
  font-size: 18px;
  padding: 5px 20px 0 35px;
}
@media screen and (max-width: 1100px) {
  div.formHolder div.formIntro,
  .col-md-12 > div > div.contour div.formIntro {
    padding: 5px 25px 0;
  }
}
div.formHolder div.formIntro p,
.col-md-12 > div > div.contour div.formIntro p {
  margin: 0;
  padding-bottom: 43px;
}
div.formHolder div.formIntro .moreLink,
div.formHolder div.formIntro .moreLink a,
.col-md-12 > div > div.contour div.formIntro .moreLink,
.col-md-12 > div > div.contour div.formIntro .moreLink a {
  color: #504D4C;
  margin-left: 4px;
}
div.formHolder div.formIntro .moreLink:after,
div.formHolder div.formIntro .moreLink a:after,
.col-md-12 > div > div.contour div.formIntro .moreLink:after,
.col-md-12 > div > div.contour div.formIntro .moreLink a:after {
  color: #C7BBD2;
}
div.formHolder > div.text,
.col-md-12 > div > div.contour > div.text {
  background: #ffffff;
  padding: 0 80px 50px 72px;
}
div.formHolder div.contourPage,
.col-md-12 > div > div.contour div.contourPage {
  border-top: 44px solid #ffffff;
}
@media screen and (max-width: 1100px) {
  div.formHolder div.contourPage,
  .col-md-12 > div > div.contour div.contourPage {
    border-top: 18px solid #ffffff;
  }
}
div.formHolder div.form,
div.formHolder div.contourPage,
.col-md-12 > div > div.contour div.form,
.col-md-12 > div > div.contour div.contourPage {
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
}
div.formHolder div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper,
div.formHolder div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper,
.col-md-12 > div > div.contour div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper {
  position: relative;
}
div.formHolder div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper svg.datePickerIcon,
div.formHolder div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper svg.datePickerIcon,
.col-md-12 > div > div.contour div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper svg.datePickerIcon,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper svg.datePickerIcon {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 10px;
  left: 10px;
  fill: #4F1E73;
}
div.formHolder div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper input,
div.formHolder div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper input,
.col-md-12 > div > div.contour div.form .umbraco-forms-field.date .umbraco-forms-field-wrapper input,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field.date .umbraco-forms-field-wrapper input {
  padding-left: 50px;
}
div.formHolder div.form fieldset,
div.formHolder div.contourPage fieldset,
.col-md-12 > div > div.contour div.form fieldset,
.col-md-12 > div > div.contour div.contourPage fieldset {
  background: #ffffff;
  padding: 0 80px 20px 72px;
}
@media screen and (max-width: 1100px) {
  div.formHolder div.form fieldset,
  div.formHolder div.contourPage fieldset,
  .col-md-12 > div > div.contour div.form fieldset,
  .col-md-12 > div > div.contour div.contourPage fieldset {
    padding: 0 40px 20px 32px;
  }
}
@media screen and (max-width: 767px) {
  div.formHolder div.form fieldset,
  div.formHolder div.contourPage fieldset,
  .col-md-12 > div > div.contour div.form fieldset,
  .col-md-12 > div > div.contour div.contourPage fieldset {
    padding: 0 20px 10px 17px;
  }
}
div.formHolder div.form div.umbraco-forms-field,
div.formHolder div.contourPage div.umbraco-forms-field,
.col-md-12 > div > div.contour div.form div.umbraco-forms-field,
.col-md-12 > div > div.contour div.contourPage div.umbraco-forms-field {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form div.umbraco-forms-field,
  div.formHolder div.contourPage div.umbraco-forms-field,
  .col-md-12 > div > div.contour div.form div.umbraco-forms-field,
  .col-md-12 > div > div.contour div.contourPage div.umbraco-forms-field {
    margin-bottom: 20px;
  }
}
div.formHolder div.form .form > .umbraco-forms-form,
div.formHolder div.contourPage .form > .umbraco-forms-form,
.col-md-12 > div > div.contour div.form .form > .umbraco-forms-form,
.col-md-12 > div > div.contour div.contourPage .form > .umbraco-forms-form {
  padding-top: 0;
}
div.formHolder div.form label.umbraco-forms-label,
div.formHolder div.contourPage label.umbraco-forms-label,
.col-md-12 > div > div.contour div.form label.umbraco-forms-label,
.col-md-12 > div > div.contour div.contourPage label.umbraco-forms-label {
  color: #999799;
  font-size: 18px;
  line-height: 30px;
  float: left;
  margin-right: 3%;
  padding: 7px 0 0;
  width: 30%;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form label.umbraco-forms-label,
  div.formHolder div.contourPage label.umbraco-forms-label,
  .col-md-12 > div > div.contour div.form label.umbraco-forms-label,
  .col-md-12 > div > div.contour div.contourPage label.umbraco-forms-label {
    float: none;
    margin-right: 0;
    padding: 7px 0 6px;
    width: 100%;
  }
}
div.formHolder div.form label.umbraco-forms-label + div,
div.formHolder div.contourPage label.umbraco-forms-label + div,
.col-md-12 > div > div.contour div.form label.umbraco-forms-label + div,
.col-md-12 > div > div.contour div.contourPage label.umbraco-forms-label + div {
  float: left;
  position: relative;
  width: 67%;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form label.umbraco-forms-label + div,
  div.formHolder div.contourPage label.umbraco-forms-label + div,
  .col-md-12 > div > div.contour div.form label.umbraco-forms-label + div,
  .col-md-12 > div > div.contour div.contourPage label.umbraco-forms-label + div {
    float: none;
    width: 100%;
  }
}
div.formHolder div.form input.text,
div.formHolder div.form input.datepickerfield,
div.formHolder div.form input.datepickerfieldshadow,
div.formHolder div.form input.title,
div.formHolder div.form textarea,
div.formHolder div.contourPage input.text,
div.formHolder div.contourPage input.datepickerfield,
div.formHolder div.contourPage input.datepickerfieldshadow,
div.formHolder div.contourPage input.title,
div.formHolder div.contourPage textarea,
.col-md-12 > div > div.contour div.form input.text,
.col-md-12 > div > div.contour div.form input.datepickerfield,
.col-md-12 > div > div.contour div.form input.datepickerfieldshadow,
.col-md-12 > div > div.contour div.form input.title,
.col-md-12 > div > div.contour div.form textarea,
.col-md-12 > div > div.contour div.contourPage input.text,
.col-md-12 > div > div.contour div.contourPage input.datepickerfield,
.col-md-12 > div > div.contour div.contourPage input.datepickerfieldshadow,
.col-md-12 > div > div.contour div.contourPage input.title,
.col-md-12 > div > div.contour div.contourPage textarea {
  background: rgba(242, 235, 230, 0.5);
  border: 1px solid #BFBAB6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-sizing: border-box;
  color: #504D4C;
  font-family: "latoregular";
  font-size: 18px;
  line-height: 30px;
  padding: 8px 13px 9px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
div.formHolder div.form input.text:hover,
div.formHolder div.form input.datepickerfield:hover,
div.formHolder div.form input.datepickerfieldshadow:hover,
div.formHolder div.form input.title:hover,
div.formHolder div.form textarea:hover,
div.formHolder div.contourPage input.text:hover,
div.formHolder div.contourPage input.datepickerfield:hover,
div.formHolder div.contourPage input.datepickerfieldshadow:hover,
div.formHolder div.contourPage input.title:hover,
div.formHolder div.contourPage textarea:hover,
.col-md-12 > div > div.contour div.form input.text:hover,
.col-md-12 > div > div.contour div.form input.datepickerfield:hover,
.col-md-12 > div > div.contour div.form input.datepickerfieldshadow:hover,
.col-md-12 > div > div.contour div.form input.title:hover,
.col-md-12 > div > div.contour div.form textarea:hover,
.col-md-12 > div > div.contour div.contourPage input.text:hover,
.col-md-12 > div > div.contour div.contourPage input.datepickerfield:hover,
.col-md-12 > div > div.contour div.contourPage input.datepickerfieldshadow:hover,
.col-md-12 > div > div.contour div.contourPage input.title:hover,
.col-md-12 > div > div.contour div.contourPage textarea:hover {
  border-color: #999799;
}
div.formHolder div.form input.text:focus,
div.formHolder div.form input.datepickerfield:focus,
div.formHolder div.form input.datepickerfieldshadow:focus,
div.formHolder div.form input.title:focus,
div.formHolder div.form textarea:focus,
div.formHolder div.contourPage input.text:focus,
div.formHolder div.contourPage input.datepickerfield:focus,
div.formHolder div.contourPage input.datepickerfieldshadow:focus,
div.formHolder div.contourPage input.title:focus,
div.formHolder div.contourPage textarea:focus,
.col-md-12 > div > div.contour div.form input.text:focus,
.col-md-12 > div > div.contour div.form input.datepickerfield:focus,
.col-md-12 > div > div.contour div.form input.datepickerfieldshadow:focus,
.col-md-12 > div > div.contour div.form input.title:focus,
.col-md-12 > div > div.contour div.form textarea:focus,
.col-md-12 > div > div.contour div.contourPage input.text:focus,
.col-md-12 > div > div.contour div.contourPage input.datepickerfield:focus,
.col-md-12 > div > div.contour div.contourPage input.datepickerfieldshadow:focus,
.col-md-12 > div > div.contour div.contourPage input.title:focus,
.col-md-12 > div > div.contour div.contourPage textarea:focus {
  -webkit-box-shadow: 0px 0px 5px 0px #BFBAB6, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px #BFBAB6, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: 0px 0px 5px 0px #BFBAB6, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  border-color: #999799;
}
div.formHolder div.form textarea,
div.formHolder div.contourPage textarea,
.col-md-12 > div > div.contour div.form textarea,
.col-md-12 > div > div.contour div.contourPage textarea {
  resize: none;
}
div.formHolder div.form .umbraco-forms-field:before,
div.formHolder div.form .umbraco-forms-field:after,
div.formHolder div.contourPage .umbraco-forms-field:before,
div.formHolder div.contourPage .umbraco-forms-field:after,
.col-md-12 > div > div.contour div.form .umbraco-forms-field:before,
.col-md-12 > div > div.contour div.form .umbraco-forms-field:after,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field:before,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field:after {
  content: "";
  display: table;
}
div.formHolder div.form .umbraco-forms-field:after,
div.formHolder div.contourPage .umbraco-forms-field:after,
.col-md-12 > div > div.contour div.form .umbraco-forms-field:after,
.col-md-12 > div > div.contour div.contourPage .umbraco-forms-field:after {
  clear: both;
}
div.formHolder div.form span.field-validation-error,
div.formHolder div.contourPage span.field-validation-error,
.col-md-12 > div > div.contour div.form span.field-validation-error,
.col-md-12 > div > div.contour div.contourPage span.field-validation-error {
  color: #D84E1E;
  font-family: "latoitalic";
  font-size: 15px;
}
div.formHolder div.form span.field-validation-error:after,
div.formHolder div.contourPage span.field-validation-error:after,
.col-md-12 > div > div.contour div.form span.field-validation-error:after,
.col-md-12 > div > div.contour div.contourPage span.field-validation-error:after {
  content: "";
  display: table;
  clear: left;
}
div.formHolder div.form label.singleBox,
div.formHolder div.contourPage label.singleBox,
.col-md-12 > div > div.contour div.form label.singleBox,
.col-md-12 > div > div.contour div.contourPage label.singleBox {
  display: inline-block;
  margin-top: 11px;
}
div.formHolder div.form div.checkboxlist span.checkboxlist,
div.formHolder div.contourPage div.checkboxlist span.checkboxlist,
.col-md-12 > div > div.contour div.form div.checkboxlist span.checkboxlist,
.col-md-12 > div > div.contour div.contourPage div.checkboxlist span.checkboxlist {
  display: block;
  padding: 8px 0 10px;
}
div.formHolder div.form div.checkboxlist > div label,
div.formHolder div.contourPage div.checkboxlist > div label,
.col-md-12 > div > div.contour div.form div.checkboxlist > div label,
.col-md-12 > div > div.contour div.contourPage div.checkboxlist > div label {
  display: inline;
}
div.formHolder div.form div.longanswer label,
div.formHolder div.contourPage div.longanswer label,
.col-md-12 > div > div.contour div.form div.longanswer label,
.col-md-12 > div > div.contour div.contourPage div.longanswer label {
  width: 100%;
}
div.formHolder div.form div.longanswer > div,
div.formHolder div.contourPage div.longanswer > div,
.col-md-12 > div > div.contour div.form div.longanswer > div,
.col-md-12 > div > div.contour div.contourPage div.longanswer > div {
  width: 100%;
}
div.formHolder div.form div.radiobuttonlist label,
div.formHolder div.contourPage div.radiobuttonlist label,
.col-md-12 > div > div.contour div.form div.radiobuttonlist label,
.col-md-12 > div > div.contour div.contourPage div.radiobuttonlist label {
  width: 100%;
}
div.formHolder div.form div.radiobuttonlist span.radiobuttonlist,
div.formHolder div.contourPage div.radiobuttonlist span.radiobuttonlist,
.col-md-12 > div > div.contour div.form div.radiobuttonlist span.radiobuttonlist,
.col-md-12 > div > div.contour div.contourPage div.radiobuttonlist span.radiobuttonlist {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 10px;
}
div.formHolder div.form div.radiobuttonlist > div,
div.formHolder div.contourPage div.radiobuttonlist > div,
.col-md-12 > div > div.contour div.form div.radiobuttonlist > div,
.col-md-12 > div > div.contour div.contourPage div.radiobuttonlist > div {
  width: 100%;
}
div.formHolder div.form div.radiobuttonlist > div label,
div.formHolder div.contourPage div.radiobuttonlist > div label,
.col-md-12 > div > div.contour div.form div.radiobuttonlist > div label,
.col-md-12 > div > div.contour div.contourPage div.radiobuttonlist > div label {
  display: inline;
  width: auto;
}
div.formHolder div.form [type=checkbox]:not(:checked),
div.formHolder div.form [type=checkbox]:checked,
div.formHolder div.contourPage [type=checkbox]:not(:checked),
div.formHolder div.contourPage [type=checkbox]:checked,
.col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked),
.col-md-12 > div > div.contour div.form [type=checkbox]:checked,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked),
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked {
  position: absolute;
  left: -99999px;
}
div.formHolder div.form [type=checkbox]:not(:checked) + label,
div.formHolder div.form [type=checkbox]:checked + label,
div.formHolder div.contourPage [type=checkbox]:not(:checked) + label,
div.formHolder div.contourPage [type=checkbox]:checked + label,
.col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked) + label,
.col-md-12 > div > div.contour div.form [type=checkbox]:checked + label,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked) + label,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked + label {
  min-height: 24px;
  line-height: normal;
  position: relative;
  padding: 0 0 0 31px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form [type=checkbox]:not(:checked) + label,
  div.formHolder div.form [type=checkbox]:checked + label,
  div.formHolder div.contourPage [type=checkbox]:not(:checked) + label,
  div.formHolder div.contourPage [type=checkbox]:checked + label,
  .col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked) + label,
  .col-md-12 > div > div.contour div.form [type=checkbox]:checked + label,
  .col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked) + label,
  .col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked + label {
    float: none;
  }
}
div.formHolder div.form [type=checkbox]:not(:checked) + label:before,
div.formHolder div.form [type=checkbox]:checked + label:before,
div.formHolder div.contourPage [type=checkbox]:not(:checked) + label:before,
div.formHolder div.contourPage [type=checkbox]:checked + label:before,
.col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked) + label:before,
.col-md-12 > div > div.contour div.form [type=checkbox]:checked + label:before,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked) + label:before,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked + label:before {
  background: rgba(242, 235, 230, 0.5);
  border: 1px solid #BFBAB6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  width: 20px;
}
div.formHolder div.form [type=checkbox]:not(:checked) + label:after,
div.formHolder div.form [type=checkbox]:checked + label:after,
div.formHolder div.contourPage [type=checkbox]:not(:checked) + label:after,
div.formHolder div.contourPage [type=checkbox]:checked + label:after,
.col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.form [type=checkbox]:checked + label:after,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked + label:after {
  color: #504D4C;
  content: "\e809";
  position: absolute;
  top: 3px;
  left: 5px;
  font-family: "helioIcon";
  font-size: 10px;
  line-height: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.formHolder div.form [type=checkbox]:not(:checked) + label:after,
div.formHolder div.contourPage [type=checkbox]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.form [type=checkbox]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
div.formHolder div.form [type=checkbox]:checked + label:after,
div.formHolder div.contourPage [type=checkbox]:checked + label:after,
.col-md-12 > div > div.contour div.form [type=checkbox]:checked + label:after,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:checked + label:after {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
div.formHolder div.form [type=checkbox]:disabled + label,
div.formHolder div.contourPage [type=checkbox]:disabled + label,
.col-md-12 > div > div.contour div.form [type=checkbox]:disabled + label,
.col-md-12 > div > div.contour div.contourPage [type=checkbox]:disabled + label {
  opacity: 0.45;
  filter: alpha(opacity=45);
  pointer-events: all;
}
div.formHolder div.form [type=radio]:not(:checked),
div.formHolder div.form [type=radio]:checked,
div.formHolder div.contourPage [type=radio]:not(:checked),
div.formHolder div.contourPage [type=radio]:checked,
.col-md-12 > div > div.contour div.form [type=radio]:not(:checked),
.col-md-12 > div > div.contour div.form [type=radio]:checked,
.col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked),
.col-md-12 > div > div.contour div.contourPage [type=radio]:checked {
  position: absolute;
  left: -99999px;
}
div.formHolder div.form [type=radio]:not(:checked) + label,
div.formHolder div.form [type=radio]:checked + label,
div.formHolder div.contourPage [type=radio]:not(:checked) + label,
div.formHolder div.contourPage [type=radio]:checked + label,
.col-md-12 > div > div.contour div.form [type=radio]:not(:checked) + label,
.col-md-12 > div > div.contour div.form [type=radio]:checked + label,
.col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked) + label,
.col-md-12 > div > div.contour div.contourPage [type=radio]:checked + label {
  height: 24px;
  position: relative;
  padding: 0 0 0 31px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form [type=radio]:not(:checked) + label,
  div.formHolder div.form [type=radio]:checked + label,
  div.formHolder div.contourPage [type=radio]:not(:checked) + label,
  div.formHolder div.contourPage [type=radio]:checked + label,
  .col-md-12 > div > div.contour div.form [type=radio]:not(:checked) + label,
  .col-md-12 > div > div.contour div.form [type=radio]:checked + label,
  .col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked) + label,
  .col-md-12 > div > div.contour div.contourPage [type=radio]:checked + label {
    float: none;
  }
}
div.formHolder div.form [type=radio]:not(:checked) + label:before,
div.formHolder div.form [type=radio]:checked + label:before,
div.formHolder div.contourPage [type=radio]:not(:checked) + label:before,
div.formHolder div.contourPage [type=radio]:checked + label:before,
.col-md-12 > div > div.contour div.form [type=radio]:not(:checked) + label:before,
.col-md-12 > div > div.contour div.form [type=radio]:checked + label:before,
.col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked) + label:before,
.col-md-12 > div > div.contour div.contourPage [type=radio]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  background: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #999799;
  -webkit-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  -moz-box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  box-shadow: inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.2);
  width: 20px;
}
div.formHolder div.form [type=radio]:not(:checked) + label:after,
div.formHolder div.form [type=radio]:checked + label:after,
div.formHolder div.contourPage [type=radio]:not(:checked) + label:after,
div.formHolder div.contourPage [type=radio]:checked + label:after,
.col-md-12 > div > div.contour div.form [type=radio]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.form [type=radio]:checked + label:after,
.col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.contourPage [type=radio]:checked + label:after {
  background: #504D4C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  left: 6px;
  position: absolute;
  top: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 8px;
}
div.formHolder div.form [type=radio]:not(:checked) + label:after,
div.formHolder div.contourPage [type=radio]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.form [type=radio]:not(:checked) + label:after,
.col-md-12 > div > div.contour div.contourPage [type=radio]:not(:checked) + label:after {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
div.formHolder div.form [type=radio]:checked + label:after,
div.formHolder div.contourPage [type=radio]:checked + label:after,
.col-md-12 > div > div.contour div.form [type=radio]:checked + label:after,
.col-md-12 > div > div.contour div.contourPage [type=radio]:checked + label:after {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
div.formHolder div.form select,
div.formHolder div.contourPage select,
.col-md-12 > div > div.contour div.form select,
.col-md-12 > div > div.contour div.contourPage select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #003D65;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  outline: none;
  padding: 8px 36px 8px 8px;
  vertical-align: top;
  width: 100%;
}
div.formHolder div.form select.disabled, div.formHolder div.form select:disabled,
div.formHolder div.contourPage select.disabled,
div.formHolder div.contourPage select:disabled,
.col-md-12 > div > div.contour div.form select.disabled,
.col-md-12 > div > div.contour div.form select:disabled,
.col-md-12 > div > div.contour div.contourPage select.disabled,
.col-md-12 > div > div.contour div.contourPage select:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
div.formHolder div.form select.disabled:hover:after, div.formHolder div.form select:disabled:hover:after,
div.formHolder div.contourPage select.disabled:hover:after,
div.formHolder div.contourPage select:disabled:hover:after,
.col-md-12 > div > div.contour div.form select.disabled:hover:after,
.col-md-12 > div > div.contour div.form select:disabled:hover:after,
.col-md-12 > div > div.contour div.contourPage select.disabled:hover:after,
.col-md-12 > div > div.contour div.contourPage select:disabled:hover:after {
  top: 9px;
}
div.formHolder div.form select.hide,
div.formHolder div.contourPage select.hide,
.col-md-12 > div > div.contour div.form select.hide,
.col-md-12 > div > div.contour div.contourPage select.hide {
  display: none;
}
div.formHolder div.form select:after, div.formHolder div.form select:before,
div.formHolder div.contourPage select:after,
div.formHolder div.contourPage select:before,
.col-md-12 > div > div.contour div.form select:after,
.col-md-12 > div > div.contour div.form select:before,
.col-md-12 > div > div.contour div.contourPage select:after,
.col-md-12 > div > div.contour div.contourPage select:before {
  content: "";
  display: table;
}
div.formHolder div.form select:after,
div.formHolder div.contourPage select:after,
.col-md-12 > div > div.contour div.form select:after,
.col-md-12 > div > div.contour div.contourPage select:after {
  clear: both;
}
div.formHolder div.form.select,
div.formHolder div.contourPage.select,
.col-md-12 > div > div.contour div.form.select,
.col-md-12 > div > div.contour div.contourPage.select {
  cursor: pointer;
  max-width: 49%;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form.select,
  div.formHolder div.contourPage.select,
  .col-md-12 > div > div.contour div.form.select,
  .col-md-12 > div > div.contour div.contourPage.select {
    float: none;
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
  }
}
div.formHolder div.form.select:after,
div.formHolder div.contourPage.select:after,
.col-md-12 > div > div.contour div.form.select:after,
.col-md-12 > div > div.contour div.contourPage.select:after {
  display: none;
}
div.formHolder div.form .styledSelect,
div.formHolder div.contourPage .styledSelect,
.col-md-12 > div > div.contour div.form .styledSelect,
.col-md-12 > div > div.contour div.contourPage .styledSelect {
  background: #ffffff;
  border: 1px solid #BFBAB6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #504D4C;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 18px;
  margin: 0;
  line-height: 30px;
  outline: none;
  padding: 7px 36px 9px 12px;
  vertical-align: top;
  width: 100%;
}
div.formHolder div.form .styledSelect:after,
div.formHolder div.contourPage .styledSelect:after,
.col-md-12 > div > div.contour div.form .styledSelect:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect:after {
  background: transparent;
  color: #504D4C;
  content: "\e807";
  font-family: "helioIcon";
  font-size: 8px;
  padding: 0 0 2px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 9px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.formHolder div.form .styledSelect:hover:after,
div.formHolder div.contourPage .styledSelect:hover:after,
.col-md-12 > div > div.contour div.form .styledSelect:hover:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect:hover:after {
  top: 12px;
}
div.formHolder div.form .styledSelect:active:hover:after, div.formHolder div.form .styledSelect.active:hover:after,
div.formHolder div.contourPage .styledSelect:active:hover:after,
div.formHolder div.contourPage .styledSelect.active:hover:after,
.col-md-12 > div > div.contour div.form .styledSelect:active:hover:after,
.col-md-12 > div > div.contour div.form .styledSelect.active:hover:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect:active:hover:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect.active:hover:after {
  top: 9px;
}
div.formHolder div.form .styledSelect.disabled, div.formHolder div.form .styledSelect:disabled,
div.formHolder div.contourPage .styledSelect.disabled,
div.formHolder div.contourPage .styledSelect:disabled,
.col-md-12 > div > div.contour div.form .styledSelect.disabled,
.col-md-12 > div > div.contour div.form .styledSelect:disabled,
.col-md-12 > div > div.contour div.contourPage .styledSelect.disabled,
.col-md-12 > div > div.contour div.contourPage .styledSelect:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
div.formHolder div.form .styledSelect.disabled:hover:after, div.formHolder div.form .styledSelect:disabled:hover:after,
div.formHolder div.contourPage .styledSelect.disabled:hover:after,
div.formHolder div.contourPage .styledSelect:disabled:hover:after,
.col-md-12 > div > div.contour div.form .styledSelect.disabled:hover:after,
.col-md-12 > div > div.contour div.form .styledSelect:disabled:hover:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect.disabled:hover:after,
.col-md-12 > div > div.contour div.contourPage .styledSelect:disabled:hover:after {
  top: 9px;
}
div.formHolder div.form .options,
div.formHolder div.contourPage .options,
.col-md-12 > div > div.contour div.form .options,
.col-md-12 > div > div.contour div.contourPage .options {
  background-color: white;
  border: 1px solid #BFBAB6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: none;
  font-size: 18px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 4px 0 11px;
  position: absolute;
  right: 0;
  top: 100%;
  top: calc(100% + 4px);
  z-index: 999;
}
div.formHolder div.form .options li,
div.formHolder div.contourPage .options li,
.col-md-12 > div > div.contour div.form .options li,
.col-md-12 > div > div.contour div.contourPage .options li {
  color: #504D4C;
  line-height: 30px;
  margin: 0;
  padding: 0 12px;
}
div.formHolder div.form .options li:hover, div.formHolder div.form .options li.selected,
div.formHolder div.contourPage .options li:hover,
div.formHolder div.contourPage .options li.selected,
.col-md-12 > div > div.contour div.form .options li:hover,
.col-md-12 > div > div.contour div.form .options li.selected,
.col-md-12 > div > div.contour div.contourPage .options li:hover,
.col-md-12 > div > div.contour div.contourPage .options li.selected {
  background-color: rgba(242, 235, 230, 0.5);
}
div.formHolder div.form input[type=submit],
div.formHolder div.form button.configure,
div.formHolder div.form button[id^=submit],
div.formHolder div.contourPage input[type=submit],
div.formHolder div.contourPage button.configure,
div.formHolder div.contourPage button[id^=submit],
.col-md-12 > div > div.contour div.form input[type=submit],
.col-md-12 > div > div.contour div.form button.configure,
.col-md-12 > div > div.contour div.form button[id^=submit],
.col-md-12 > div > div.contour div.contourPage input[type=submit],
.col-md-12 > div > div.contour div.contourPage button.configure,
.col-md-12 > div > div.contour div.contourPage button[id^=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #9E4798;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "latobold";
  font-size: 16px;
  color: #FFFFFF;
  line-height: 29px;
  margin: 33px 30px 31px 72px;
  padding: 9px 19px 11px 17px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  div.formHolder div.form input[type=submit],
  div.formHolder div.form button.configure,
  div.formHolder div.form button[id^=submit],
  div.formHolder div.contourPage input[type=submit],
  div.formHolder div.contourPage button.configure,
  div.formHolder div.contourPage button[id^=submit],
  .col-md-12 > div > div.contour div.form input[type=submit],
  .col-md-12 > div > div.contour div.form button.configure,
  .col-md-12 > div > div.contour div.form button[id^=submit],
  .col-md-12 > div > div.contour div.contourPage input[type=submit],
  .col-md-12 > div > div.contour div.contourPage button.configure,
  .col-md-12 > div > div.contour div.contourPage button[id^=submit] {
    margin: 17px;
  }
}
div.formHolder div.form input[type=submit]:hover,
div.formHolder div.form button.configure:hover,
div.formHolder div.form button[id^=submit]:hover,
div.formHolder div.contourPage input[type=submit]:hover,
div.formHolder div.contourPage button.configure:hover,
div.formHolder div.contourPage button[id^=submit]:hover,
.col-md-12 > div > div.contour div.form input[type=submit]:hover,
.col-md-12 > div > div.contour div.form button.configure:hover,
.col-md-12 > div > div.contour div.form button[id^=submit]:hover,
.col-md-12 > div > div.contour div.contourPage input[type=submit]:hover,
.col-md-12 > div > div.contour div.contourPage button.configure:hover,
.col-md-12 > div > div.contour div.contourPage button[id^=submit]:hover {
  background: #B05CAC;
}

h4.umbraco-forms-caption {
  color: #4F1E73;
  display: inline-block;
  font-family: "latoregular";
  font-weight: normal;
  font-size: 24px !important;
  line-height: 22px !important;
  margin: 18px 0 22px;
  padding: 18px 35px;
  vertical-align: top;
}
h4.umbraco-forms-caption + fieldset {
  padding-top: 38px !important;
}

.col-md-12 > div > div.contour div.contourPage {
  border-top: none;
}

div.highLight {
  border: 1px solid #E6DFDA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #504D4C;
  font-size: 15px;
  margin: 39px 0 0;
  padding: 12px 22px 16px;
}
div.highLight span.title {
  color: #9F9D9F;
  font-size: 13px;
  text-transform: uppercase;
}

.tel {
  color: rgb(208, 92, 0);
  display: block;
  font-family: "latobold";
  font-size: 18px;
  line-height: 18px;
  margin: 31px 0 9px;
  /*&:before {
  	color: $telIconColor;
  	content: '\E80C';
  	display: inline-block;
  	font-family: $icon;
  	margin-right: 21px;
  }*/
}

.locationFilter {
  background: #E8F3FB;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: right;
  margin: 82px 0 0;
  padding: 15px 25px 27px;
}
.locationFilter:after, .locationFilter:before {
  content: "";
  display: table;
}
.locationFilter:after {
  clear: both;
}
@media screen and (max-width: 1000px) {
  .locationFilter {
    width: 100%;
    margin-top: 0;
  }
}
.locationFilter label {
  color: #0D486E;
  display: block;
  font-family: "latobold";
  font-size: 13px;
  line-height: 29px;
  margin: 2px 0 11px;
  text-transform: uppercase;
}
.locationFilter div.selectBox {
  position: relative;
}
.locationFilter div.selectBox select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #017CCB;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  outline: none;
  padding: 8px 36px 8px 8px;
  vertical-align: top;
  width: 100%;
}
.locationFilter div.selectBox select.disabled, .locationFilter div.selectBox select:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
.locationFilter div.selectBox select.disabled:hover:after, .locationFilter div.selectBox select:disabled:hover:after {
  top: 9px;
}
.locationFilter div.selectBox select.hide {
  display: none;
}
.locationFilter div.selectBox select:after, .locationFilter div.selectBox select:before {
  content: "";
  display: table;
}
.locationFilter div.selectBox select:after {
  clear: both;
}
.locationFilter div.selectBox.select {
  cursor: pointer;
  max-width: 49%;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .locationFilter div.selectBox.select {
    float: none;
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
  }
}
.locationFilter div.selectBox.select:after {
  display: none;
}
.locationFilter div.selectBox .styledSelect {
  background: #ffffff;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(0, 60, 101, 0.05);
  color: #017CCB;
  cursor: pointer;
  display: inline-block;
  font-family: "latoregular";
  font-size: 16px;
  margin: 0;
  line-height: 30px;
  outline: none;
  padding: 7px 36px 9px 12px;
  vertical-align: top;
  width: 100%;
}
.locationFilter div.selectBox .styledSelect:after {
  background: transparent;
  color: #017CCB;
  content: "\e807";
  font-family: "helioIcon";
  font-size: 8px;
  padding: 0 0 2px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 9px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.locationFilter div.selectBox .styledSelect:hover:after {
  top: 12px;
}
.locationFilter div.selectBox .styledSelect:active:hover:after, .locationFilter div.selectBox .styledSelect.active:hover:after {
  top: 9px;
}
.locationFilter div.selectBox .styledSelect.disabled, .locationFilter div.selectBox .styledSelect:disabled {
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}
.locationFilter div.selectBox .styledSelect.disabled:hover:after, .locationFilter div.selectBox .styledSelect:disabled:hover:after {
  top: 9px;
}
.locationFilter div.selectBox .options {
  background-color: white;
  border: 1px solid rgba(0, 126, 204, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: none;
  font-size: 16px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 4px 0 11px;
  position: absolute;
  right: 0;
  top: 100%;
  top: calc(100% + 4px);
  z-index: 999;
}
.locationFilter div.selectBox .options li {
  color: #017CCB;
  line-height: 28px;
  margin: 0;
  padding: 0 12px;
}
.locationFilter div.selectBox .options li:hover, .locationFilter div.selectBox .options li.selected {
  background-color: rgba(0, 126, 204, 0.1);
}
.locationFilter button {
  background: #017CCB;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: "latobold";
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin: 10px 0 0;
  padding: 10px 11px 9px;
  vertical-align: top;
}

div.contactLink {
  font-size: 15px;
  margin: 38px 0 84px;
}
@media screen and (max-width: 767px) {
  div.contactLink {
    margin: 25px 0 32px;
  }
}
div.contactLink span {
  display: inline-block;
  margin-right: 18px;
}
@media screen and (max-width: 767px) {
  div.contactLink span {
    display: block;
  }
}
div.contactLink a.moreLink {
  font-family: "latobold";
}
div.contactLink a.moreLink:after {
  line-height: 30px;
}

ul.vacancies {
  list-style: none;
  margin: 0 -15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
}
ul.vacancies li {
  width: 50%;
  padding: 15px;
}
@media screen and (max-width: 767px) {
  ul.vacancies li {
    width: 100%;
  }
}
ul.vacancies li a {
  background: #ffffff;
  border: 1px solid #E6DFDA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  padding: 28px 28px 21px;
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
ul.vacancies li a:hover {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
ul.vacancies li a:hover > span.moreLink:after {
  margin: 1px 5px 0 6px;
}
ul.vacancies li a span.content {
  display: block;
  margin-bottom: 22px;
}
ul.vacancies li a span.content span.name {
  color: #504D4C;
  display: block;
  font-family: "latobold";
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 17px;
  min-height: 48px;
}
ul.vacancies li a span.content span.hours,
ul.vacancies li a span.content span.closing {
  color: #504D4C;
  display: block;
  font-size: 15px;
  line-height: 21px;
}
ul.vacancies li a span.moreLink {
  color: #D54D12;
  font-size: 15px;
  line-height: 29px;
  text-decoration: none;
}
ul.vacancies li a span.moreLink:hover:after {
  margin: 1px 5px 0 6px;
}
ul.vacancies li a span.moreLink:after {
  color: #EAA688;
  content: "\e802";
  display: inline;
  font-family: "helioIcon";
  font-size: 6px;
  line-height: 32px;
  margin: 1px 0 0 11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  ul.vacancies li a span.moreLink:after {
    line-height: 25px;
  }
}

.cf-turnstile {
  margin-left: 72px;
}

table.bordered {
  border-right: 1px solid #E6DFDA;
  border-bottom: 1px solid #E6DFDA;
}
table.bordered tr td {
  border-left: 1px solid #E6DFDA;
  border-top: 1px solid #E6DFDA;
}

table tr td {
  padding: 5px 10px;
}