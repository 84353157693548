@import 'tools.scss';
@import 'cookiebar.scss';

@font-face {
	font-family: 'helioIcon';
	src: url('/fonts/fontello.eot?28043464');
	src: url('/fonts/fontello.eot?28043464#iefix') format('embedded-opentype'), url('/fonts/fontello.woff?28043464') format('woff'), url('/fonts/fontello.ttf?28043464') format('truetype'), url('/fonts/fontello.svg?28043464#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latoblack';
	src: url('/fonts/lato-bla-webfont.eot');
	src: url('/fonts/lato-bla-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-bla-webfont.woff2') format('woff2'), url('/fonts/lato-bla-webfont.woff') format('woff'), url('/fonts/lato-bla-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latobold';
	src: url('/fonts/lato-bol-webfont.eot');
	src: url('/fonts/lato-bol-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-bol-webfont.woff2') format('woff2'), url('/fonts/lato-bol-webfont.woff') format('woff'), url('/fonts/lato-bol-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latobold_italic';
	src: url('/fonts/lato-bolita-webfont.eot');
	src: url('/fonts/lato-bolita-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-bolita-webfont.woff2') format('woff2'), url('/fonts/lato-bolita-webfont.woff') format('woff'), url('/fonts/lato-bolita-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latolight';
	src: url('/fonts/lato-lig-webfont.eot');
	src: url('/fonts/lato-lig-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-lig-webfont.woff2') format('woff2'), url('/fonts/lato-lig-webfont.woff') format('woff'), url('/fonts/lato-lig-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latoregular';
	src: url('/fonts/lato-reg-webfont.eot');
	src: url('/fonts/lato-reg-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-reg-webfont.woff2') format('woff2'), url('/fonts/lato-reg-webfont.woff') format('woff'), url('/fonts/lato-reg-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latoitalic';
	src: url('/fonts/lato-regita-webfont.eot');
	src: url('/fonts/lato-regita-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-regita-webfont.woff2') format('woff2'), url('/fonts/lato-regita-webfont.woff') format('woff'), url('/fonts/lato-regita-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@import url(//fonts.googleapis.com/earlyaccess/nanumpenscript.css);

$fontLatoLight: 'latolight';
$fontLato: 'latoregular';
$fontLatoItalic: 'latoitalic';
$fontLatoBold: 'latobold';
$fontLatoBoldItalic: 'latobold_italic';
$fontLatoBlack: 'latoblack';
$fontNanumPen: 'Nanum Pen Script';

$icon: 'helioIcon';

$homeGreen: #8EBD02;
$homeBlue: #017CCB;
$homeOrange: #F19600;
$homeRed: #E03501;
$homePurple: #C343B6;

$homeGreen35: rgba(142, 189, 2, 0.35);
$homeBlue35: rgba(1, 124, 203, 0.35);
$homeOrange35: rgba(241, 150, 0, 0.35);
$homeRed35: rgba(213, 77, 18, 0.35);
$homePurple35: rgba(83, 32, 120, 0.35);
$homeRose35: rgba(195, 67, 182, 0.35);

$homeRed50: rgba(213, 77, 18, 0.5);
$homeOrange50: rgba(241, 150, 0, 0.5);
$homeOrange20: rgba(241, 150, 0, 0.2);

$purpleTopTasks: rgba(100, 54, 133, 1);
$purpleTopTasks50: rgba(120, 76, 151, 0.5);
$purpleTopTasks90: rgba(142, 53, 149, 0.9);

$blockQuoteGreen: #C7DF81;

$mainMenuPurple: #532078;
$metaMenuGrey: #999799;
$metaMenuActiveGrey: #504D4C;
$mainMenuBorder: #D8D8D8;
$mainMenuSearchGrey: #999999;
$mainMenuBackground: #E6DFDA;
$mainMenuDivider: #DCD9D7;

$breadcrumbGrey: #F2EFEC;
$breadcrumbGreyText: #636263;
$breadcrumbArrowPurple: #C7BBD2;

$topicalGrey: #9F9D9F;

$submenuTitle: #4D4D4D;
$submenuActiveTitle: #8A310B;
$submenuHover: #AA3D0E;

$mainTitleColor: #4F1E73;

$footerBorderColor: #E1E8ED;
$footerDivider: rgba(153, 151, 153, 0.25);
$footerTextColor: #4D4C4D;

$orangeTextColor: #D54D12;
$moreLinkIconColor: #EAA688;

$moreLinkBlue: #0E92C7;
$moreLinkBlue75: rgba(14, 146, 199, 0.75);

$highlightedTextColor: #A66600;

$sWColor: rgba(209, 231, 246, 1);
$sWTitleColor: #003D65;
$sWTopColor: rgba(209, 231, 246, 0.5);

$topTasksBackground: #9A49A0;
$topTasksTitle: #EAD1EB;

$selectDropdown: #E8F3FB;

$timeLinePurple: #431A66;
$timeLine: #56327B;
$timeLineRose: #FB59F4;
$timeLineRosehover: #DC5FD7;

$ambassadorHomepagePurple: #57257B;
$ambassadorHomepagePurple50: rgba(87, 37, 123, 0.5);

$formPurple: #9C489C;
$formHolderBackground: #F5ECF3;
$formBorderTop: rgba(156, 72, 156, 0.3);

$formInputBorder: #BFBAB6;
$formSubmit: #9E4798;
$formSubmitHover: #B05CAC;

$telColor: rgba(208, 92, 0, 1);
$telIconColor: rgba(208, 92, 0, 0.5);

$resultBlockLeftBorder: #E7E1DC;
$blueGraph: #38B3FF;

$prefooterBackground: #441A63;
$prefooterTitle: #F3F0F6;
$prefooterTitle50: rgba(243, 40, 246, 0.5);
$prefooterSubTitle: #9B84AB;
$prefooterText: #DDD2E4;
$prefooterLegend: #B6A4C4;
$prefooterMoreLink: #FF57EE;
$prefooterMoreLinkArrow: rgba(255, 87, 238, 0.5);


$colorGrey: #F2EFEC;

html,
body {
	height: 100%;
	width: 100%;
}

body {
	color: #504D4C;
	font-family: $fontLato;
	font-size: 18px;
	margin: 0;
	min-width: 320px;
	overflow-y: scroll !important;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	@include transition(transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s);
}

body.showMenu {
	@include max-screen($menuBreakpointRes) {
		overflow-y: hidden !important;
		position: fixed;
		@include translateX(-304px);
	}

	@include max-screen(360px) {
		@include translateX(-264px);
	}

	.openMenu {
		&:before {
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
		}
	}
}

h1 {
	color: $mainTitleColor;
	font-size: 40px;
	font-family: $fontLatoBold;
	font-weight: normal;
	line-height: 46px;
	margin: 3px 0 19px;

	@include max-screen($menuBreakpointRes) {
		font-size: 35px;
		line-height: 40px;
	}

	@include max-screen($breadcrumBreakpoint) {
		font-size: 24px;
		line-height: 30px;
	}

	&+span.address {
		color: $metaMenuGrey;
		display: block;
		margin: -8px 0 47px;
	}
}

h2 {
	font-size: 21px;
	font-family: $fontLatoBold;
	font-weight: normal;

	@include max-screen($breadcrumBreakpoint) {
		font-size: 18px;
	}
}

h3 {
	font-size: 18px;
	font-family: $fontLatoBold;
	font-weight: normal;
}

div.serviceBar a.solliciteren,
a.solliciteren {
	background: $orangeTextColor;
	@include border-radius(3px);
	color: #ffffff;
	display: inline-block;
	font-family: $fontLatoBold;
	font-size: 16px;
	line-height: 29px;
	margin: 25px 0 73px;
	@include opacity(1);
	padding: 3px 14px 7px 15px;
	text-decoration: none;
	@include transition(all 0.2s ease-in-out);

	&:hover {
		@include opacity(0.8);
	}

	&:after,
	&:before {
		display: none;
	}
}

div.serviceBar a.solliciteren {
	display: block;
	margin: 32px 0 0;
	text-align: center;
}

strong {
	font-family: $fontLatoBold;
	font-weight: normal;
}

.button {
	background: $orangeTextColor;
	@include border-radius(3px);
	color: #ffffff;
	display: inline-block;
	font-family: $fontLatoBold;
	font-size: 16px;
	line-height: 30px;
	padding: 4px 12px 6px 15px;
	text-decoration: none;
	@include transition(all 0.2s ease-in-out);

	&:hover {
		@include opacity(0.8);
	}
}

a.moreLink,
span.moreLink a {
	color: $orangeTextColor;
	text-decoration: none;

	&:after {
		color: $moreLinkIconColor;
		content: '\e802';
		display: inline;
		font-family: $icon;
		font-size: 6px;
		line-height: 25px;
		margin: 1px 0 0 11px;
		@include transition(all 0.2s ease-in-out);
		vertical-align: top;
	}

	&.blue {
		color: $moreLinkBlue;

		&:after {
			color: $moreLinkBlue75;
		}
	}
}

span.moreLink a {
	&:after {
		line-height: 32px;

		@include max-screen($breadcrumBreakpoint) {
			line-height: 25px;
		}
	}
}

a.moreLink:hover,
span.moreLink a:hover {
	&:after {
		margin: 1px 5px 0 6px;
	}
}

a.backLink,
span.backLink a {
	color: $orangeTextColor;
	text-decoration: none;

	&:before {
		color: $moreLinkIconColor;
		content: '\E806';
		display: inline;
		font-family: $icon;
		font-size: 6px;
		line-height: 25px;
		margin: 1px 11px 0 0;
		@include transition(all 0.2s ease-in-out);
		vertical-align: top;
	}
}

span.backLink a {
	&:before {
		line-height: 32px;

		@include max-screen($breadcrumBreakpoint) {
			line-height: 25px;
		}
	}
}

a.backLink:hover,
span.backLink a:hover {
	&:before {
		margin: 1px 6px 0 5px;
	}
}

div.homeColors {
	position: relative;
	width: 100%;

	&.top {
		left: 0;
		position: fixed;
		top: 0;
		z-index: 999;
	}

	@include clear;

	span {
		float: left;
		height: 5px;
		width: 20%;
	}

	span:nth-child(1) {
		background: $homeGreen;
	}

	span:nth-child(2) {
		background: $homeBlue;
	}

	span:nth-child(3) {
		background: $homeOrange;
	}

	span:nth-child(4) {
		background: $homeRed;
	}

	span:nth-child(5) {
		background: $homePurple;
	}
}

a.logo {
	display: inline-block;
	margin: 45px 0;

	@include max-screen($menuBreakpointRes) {
		margin: 25px 0 17px;
		@include transition(transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s);
	}

	svg {
		max-height: 72px;
		max-width: 151px;
		width: 100%;

		@include max-screen($menuBreakpointRes) {
			max-height: 49px;
			max-width: 105px;
		}

		path.o {
			fill: #C9502A;
		}

		path.pL {
			fill: #B4A5C2;
		}

		path.pD {
			fill: #4A266D;
		}
	}
}

header {
	border-bottom: 1px solid $mainMenuBorder;
	padding-top: 5px;
	position: relative;
	max-width: 100%;

	div.container {
		max-width: 1460px;
	}

	div.nav {
		float: right;

		@include max-screen($menuBreakpointRes) {
			background: $mainMenuBackground;
			bottom: 0;
			height: 100%;
			min-height: 100%;
			overflow: auto;
			position: fixed;
			right: 0;
			top: 0;
			@include translateX(304px);
			width: 304px;
		}

		@include max-screen(360px) {
			@include translateX(264px);
			width: 264px;
		}

		nav.main {
			text-align: right;
			margin-top: 82px;
			width: auto;

			@include max-screen($menuBreakpointRes) {
				margin: 0;
			}

			ul {
				display: inline-block;
				vertical-align: top;

				@include max-screen($menuBreakpointRes) {
					display: block;
					margin-top: 22px;
				}

				li {
					margin: 0 0 0 -4px;
					position: relative;

					@include max-screen($menuBreakpointRes) {
						margin: 0;
						width: 100%;
					}

					a {
						border: 1px solid transparent;
						@include border-radius(6px);
						color: $mainMenuPurple;
						display: inline-block;
						font-family: $fontLatoBold;
						line-height: 24px;
						padding: 11px 41px 14px 39px;
						text-align: left;
						text-decoration: none;
						@include transition(all 0.2s ease-in-out);

						@include max-screen($menuBreakpointRes) {
							padding: 13px 25px 16px 42px;
							width: 100%;
						}

						&:before {
							background: $mainMenuBorder;
							content: '';
							display: block;
							height: 41px;
							left: 0;
							position: absolute;
							top: 5px;
							width: 1px;

							@include max-screen($menuBreakpointRes) {
								display: none;
							}
						}

						span {
							display: block;
						}

						&:last-child {
							&:after {
								background: $mainMenuBorder;
								content: '';
								display: block;
								height: 41px;
								right: 0;
								position: absolute;
								top: 5px;
								width: 1px;

								@include max-screen($menuBreakpointRes) {
									display: none;
								}
							}
						}
					}

					a:hover {
						border: 1px solid $mainMenuBorder;
						color: $homePurple;

						@include max-screen($menuBreakpointRes) {
							border: 1px solid transparent;
						}
					}

					&.active {
						a {
							border: 1px solid $mainMenuBorder;
							color: $homePurple;

							@include max-screen($menuBreakpointRes) {
								border: 1px solid transparent;
							}
						}
					}
				}
			}
		}

		nav.meta {
			position: absolute;
			right: 10px;
			top: 16px;
			width: auto;

			@include max-screen($menuBreakpointRes) {
				position: relative;
				right: auto;
				top: auto;
			}

			ul {
				@include max-screen($menuBreakpointRes) {
					padding: 16px 20px 10px 43px;
				}

				li {
					margin-right: 25px;

					@include max-screen($menuBreakpoint) {
						margin-right: 15px;
					}

					@include max-screen($menuBreakpointRes) {
						display: block;
						margin: 17px 0 0;
						width: 100%;
					}

					a {
						color: $metaMenuGrey;
						font-size: 15px;
						text-decoration: none;
					}

					a.locations {
						color: $orangeTextColor;
					}

					a:hover {
						text-decoration: underline;
					}
				}

				li.active {
					a {
						color: $metaMenuActiveGrey;
						font-family: $fontLatoBold;
					}

					a.locations {
						color: $orangeTextColor;
					}
				}

				li.readMe {
					margin: 0 0 0 10px;

					@include max-screen($menuBreakpointRes) {
						display: none;
					}

					a {
						&:before {
							content: '\e801';
							display: inline-block;
							font-family: $icon;
							font-size: 12px;
							margin-right: 5px;
						}
					}
				}

				li.dossier {
					background-color: #4a266d;
					transition: background-color 400ms ease-in-out;
					margin-top: -20px;
					border-radius: 0 0 5px 5px;


					@include max-screen($menuBreakpointRes) {
						margin-top: 20px;
					}

					a {
						color: white;
						text-decoration: none;
						display: flex;
						align-items: center;
						padding: 17px 22px 14px 15px;
						transform: translateY(3px);

						svg {
							width: 24px;
							height: 20px;
							margin-right: 7px;
						}
					}

					&:hover {
						background-color: $mainMenuPurple;
					}
				}
			}
		}

		nav.main,
		nav.meta {
			display: block;

			@include max-screen($menuBreakpointRes) {
				position: relative;
			}

			ul {
				display: block;
				text-align: right;

				@include max-screen($menuBreakpointRes) {
					text-align: left;
				}

				li {
					display: inline-block;
				}
			}
		}

		div.telHolder {
			display: none;

			@include max-screen($menuBreakpointRes) {
				display: block;
				float: left;
				font-size: 14px;
				line-height: 18px;
				margin: 26px 0 0;
				padding: 0 30px 0 43px;
				width: 100%;
				position: relative;
				z-index: 1;
			}

			div.telNum {
				margin: 10px 0 15px;
				padding-bottom: 26px;
				position: relative;

				&:before {
					@include max-screen($menuBreakpointRes) {
						background: $metaMenuGrey;
						content: '';
						display: block;
						height: 1px;
						left: 0;
						position: absolute;
						bottom: 0;
						width: 100%;
					}
				}

				span.title {
					color: $metaMenuGrey;
					display: block;
					font-family: $fontLato;
					margin: -3px 0 18px;
				}

				p,
				a,
				a:hover {
					color: $metaMenuActiveGrey;
					font-family: $fontLatoBold;
					font-size: 18px;
				}
			}

			div.address {
				line-height: 24px;
				margin: 0 0 18px;
				padding-top: 26px;
				position: relative;

				&:before {
					@include max-screen($menuBreakpointRes) {
						background: $metaMenuGrey;
						content: '';
						display: block;
						height: 1px;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}
				}

				p {
					color: $footerTextColor;
					margin-bottom: 24px;

					a {
						color: $mainMenuPurple;
						text-decoration: none;
					}

					a:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	div.hamburgerSearch {
		float: right;

		@include max-screen($menuBreakpointRes) {
			background: #ffffff;
			margin-right: -10px;
			padding-right: 10px;
			padding: 20px 10px 12px 0;
			position: relative;
			@include transition(transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s);
			z-index: 10;
		}
	}

	div.search {
		height: 48px;
		float: right;
		margin: 80px 0 0 -1px;
		position: relative;
		width: 50px;
		z-index: 1;

		@include max-screen($menuBreakpointRes) {
			margin-top: 1px;
		}

		@include max-screen(750px) {
			margin-top: 3px;
		}

		div.searchForm {
			border: 1px solid transparent;
			@include border-radius(3px);
			color: $mainMenuPurple;
			display: none;
			float: right;
			line-height: 24px;
			padding: 10px 27px 12px 8px;
			position: absolute;
			right: 0;
			@include transition(all 0.2s ease-in-out);
			vertical-align: top;

			&.js {
				display: inline-block;

				&+a {
					display: none;
				}
			}

			&:before {
				color: $mainMenuSearchGrey;
				content: '\e804';
				cursor: pointer;
				font-family: $icon;
				font-size: 20px;
				left: 12px;
				position: absolute;
				top: 14px;
				@include transition(all 0.2s ease-in-out);
			}

			&:hover {
				&:before {
					color: $mainMenuPurple;
				}
			}

			form {
				input[type=search] {
					@include appearance(none);
					background: transparent;
					border: none;
					@include border-radius(0px);
					color: $submenuActiveTitle;
					font-family: $fontLato;
					font-size: 18px;
					line-height: 21px;
					min-width: 0;
					max-width: 0;
					padding-left: 0;
					@include transition(all 0.2s ease-in-out);
					width: 100%;

					&:focus {
						outline: none;
						outline-color: transparent;
						outline-style: auto;
						outline-width: 0;
					}
				}

				@include placeholder {
					color: $metaMenuGrey;
					font-family: $fontLato;
					font-size: 18px;
				}

				button {
					position: absolute;
					top: -2000px;
				}
			}

			div.closeBox {
				@include opacity(0);
				position: absolute;
				right: 16px;
				top: 11px;
				@include transition(all 0.2s ease-in-out);

				&:before {
					color: $mainMenuSearchGrey;
					content: '\e808';
					cursor: pointer;
					display: inline-block;
					font-family: $icon;
					font-size: 14px;
				}
			}

			&.open {
				background: #F2EBE6;
				border: 1px solid $formInputBorder;
				@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));

				&:after {
					background: #ffffff;
					content: '';
					display: block;
					height: 100px;
					position: absolute;
					top: -20px;
					width: 100%;
					z-index: -1;

					@include max-screen($menuBreakpointRes) {
						display: none;
					}
				}

				form {
					input[type=search] {
						min-width: 630px;
						max-width: 630px;
						padding-left: 39px;
						padding-right: 19px;

						@include max-screen(950px) {
							min-width: 430px;
							max-width: 430px;
						}

						@include max-screen(750px) {
							max-width: 215px;
							min-width: 215px;
						}

						@include max-screen(479px) {
							max-width: 160px;
							min-width: 160px;
						}
					}
				}

				div.closeBox {
					@include opacity(1);
				}

				&:hover {
					&:before {
						color: $mainMenuSearchGrey;
					}
				}
			}
		}

		a {
			color: $mainMenuPurple;
			cursor: pointer;
			display: none;
			float: right;
			line-height: 24px;
			margin: 69px 0 0 -1px;
			padding: 23px 18px 26px;
			position: relative;
			@include transition(all 0.2s ease-in-out);
			vertical-align: top;

			&:after {
				color: $mainMenuSearchGrey;
				content: '\e804';
				font-family: $icon;
				font-size: 20px;
				@include transition(all 0.2s ease-in-out);
			}
		}
	}

	div.openMenu {
		display: none;

		@include max-screen($menuBreakpointRes) {
			color: $homePurple;
			cursor: pointer;
			display: block;
			float: right;
			font-family: $fontLatoBold;
			font-size: 13px;
			margin: 8px 0 0;
			padding: 10px 0 10px 17px;
			text-transform: uppercase;
		}

		&:after {
			color: $mainMenuPurple;
			content: '\e805';
			font-family: $icon;
			font-size: 17px;
			margin-left: 10px;
		}
	}

	.slideOutMenu {
		background: $breadcrumbGrey;
		/* border-top: 1px solid $mainMenuBorder; */
		@include box-shadow(0px 2px 4px -3px rgba(0, 0, 0, 0.50));
		max-height: 0;
		overflow: hidden;
		position: absolute;
		margin-top: 1px;
		@include transition(max-height 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s);
		width: 100%;
		z-index: 1000;

		@include max-screen($menuBreakpointRes) {
			bottom: 0;
			height: 100%;
			max-height: auto;
			min-height: 100%;
			overflow: auto;
			position: fixed;
			right: -304px;
			top: 0;
			@include translateX(304px);
			@include transition(right 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s);
			width: 304px;
		}

		@include max-screen(360px) {
			right: -264px;
			@include translateX(264px);
			width: 264px;
		}

		div.wrapper {
			box-sizing: border-box;
			margin: 0 auto;
			max-width: $containerWidth + 40px;
			padding: 54px 20px;
			position: relative;
			width: 100%;

			@include max-screen($menuBreakpointRes) {
				padding: 36px 30px;
			}

			@include clear;

			div.actions {
				float: left;
				width: column(8);

				@include max-screen($menuBreakpointRes) {
					width: 100%;
				}

				>span.mobileTitle {
					color: $homePurple;
					cursor: pointer;
					display: none;
					font-family: $fontLatoBlack;
					line-height: 24px;
					margin-bottom: 15px;
					max-width: 190px;
					padding: 0 41px 14px 30px;
					position: relative;
					text-align: left;
					text-decoration: none;

					@include max-screen($menuBreakpointRes) {
						display: inline-block;
					}

					&:before {
						color: $metaMenuGrey;
						content: '\e806';
						display: block;
						font-family: $icon;
						font-size: 11px;
						left: 0;
						position: absolute;
						top: 12px;
					}
				}

				>span.title {
					color: $metaMenuGrey;
					display: block;
					font-size: 13px;
					font-family: $fontLatoBold;
					margin-top: 1px;

					@include max-screen($menuBreakpointRes) {
						display: none;
					}
				}

				.colLeft {
					margin-right: 6%;
				}

				.colLeft,
				.colRight {
					float: left;
					width: 47%;

					@include max-screen($menuBreakpointRes) {
						float: none;
						margin-right: 0;
						width: 100%;
					}

					span.title {
						color: $metaMenuActiveGrey;
						display: block;
						font-size: 15px;
						line-height: 21px;
						margin: 21px 0 29px;
					}

					ul {
						line-height: 18px;
						list-style: none;
						margin: 19px 0 29px;
						padding: 0;

						li {
							margin-top: 3px;

							&:first-child {
								margin-top: 4px;
							}

							a {
								color: $orangeTextColor;
								display: block;
								font-size: 15px;
								line-height: 21px;
								position: relative;
								padding: 0 0 0 19px;
								text-decoration: none;

								&:before {
									color: $homeRed50;
									content: '\e802';
									font-family: $icon;
									font-size: 7px;
									left: 0;
									line-height: 24px;
									position: absolute;
									top: 0;
									@include transition(all 0.2s ease-in-out);
								}

								&:hover {
									&:before {
										left: 3px;
									}
								}
							}
						}
					}

					a.moreLink {
						font-family: $fontLatoBold;
						font-size: 15px;
					}
				}

				div.themesHolder {
					margin-top: 20px;
					max-width: 325px;

					@include clear;

					>a {
						border: 2px solid transparent;
						@include border-radius(3px);
						float: left;
						font-family: $fontLatoBold;
						font-size: 16px;
						line-height: 18px;
						margin-bottom: 20px;
						margin-left: 6%;
						min-height: 65px;
						padding: 5px 10px;
						position: relative;
						text-align: center;
						text-decoration: none;
						@include transition(all 0.2s ease-in-out);
						width: 47%;

						@include max-screen($menuBreakpointRes) {
							margin-left: 0;
							width: 100%;
						}

						&:nth-child(odd) {
							margin-left: 0;
						}

						>span {
							display: inline-block;
							left: 0;
							padding: 0 10px;
							position: absolute;
							top: 50%;
							@include translateY(-50%);
							width: 100%;
						}

						&:first-child {
							margin-left: 0;
						}

						&:hover {
							border-color: transparent;
							color: #ffffff;
						}

						&.blue {
							border-color: $homeBlue35;
							color: $homeBlue;

							&:hover {
								background: $homeBlue;
								border-color: transparent;
								color: #ffffff;
							}
						}

						&.orange {
							border-color: $homeOrange35;
							color: $homeOrange;

							&:hover {
								background: $homeOrange;
								border-color: transparent;
								color: #ffffff;
							}
						}

						&.purple {
							border-color: $homePurple35;
							color: $mainMenuPurple;

							&:hover {
								background: $mainMenuPurple;
								border-color: transparent;
								color: #ffffff;
							}
						}

						&.red {
							border-color: $homeRed35;
							color: $homeRed;

							&:hover {
								background: $homeRed;
								border-color: transparent;
								color: #ffffff;
							}
						}

						&.rose {
							border-color: $homeRose35;
							color: $homePurple;

							&:hover {
								background: $homePurple;
								border-color: transparent;
								color: #ffffff;
							}
						}

						&.green {
							border-color: $homeGreen35;
							color: $homeGreen;

							&:hover {
								background: $homeGreen;
								border-color: transparent;
								color: #ffffff;
							}
						}
					}
				}

				div.persons {
					margin: 13px 0 15px;
					@include clear;

					a.person {
						border-bottom: 1px solid #E5DFDA;
						display: inline-block;
						margin-right: -4px;
						vertical-align: top;
						margin-left: 6%;
						max-width: 332px;
						padding: 20px 0 27px;
						width: 47%;

						@include max-screen($menuBreakpointRes) {
							margin-left: 0;
							width: 100%;
						}

						&:nth-child(odd) {
							margin-left: 0;
						}

						&:last-child {
							border-bottom: none;
						}

						span.img {
							display: block;
							float: left;
							padding-top: 4px;
							width: 19%;

							img {
								@include border-radius(3px);
								max-width: 100%;
								vertical-align: top;
							}
						}

						span.personText {
							display: block;
							float: left;
							margin-left: 6%;
							width: 75%;

							span.name {
								color: $metaMenuActiveGrey;
								display: block;
								font-family: $fontLatoBlack;
								font-size: 16px;
								margin-bottom: 5px;
								@include transition(all 0.2s ease-in-out);
							}

							span.text {
								color: $metaMenuActiveGrey;
								font-size: 15px;
								line-height: 21px;
								width: 100%;
							}
						}

						&:hover {
							span.personText {
								span.name {
									color: $mainMenuPurple;
								}
							}
						}
					}
				}
			}

			div.textHolder {
				float: left;
				margin-left: $gutterWidth;
				width: column(4);

				@include max-screen($menuBreakpointRes) {
					display: none;
				}

				>span.title {
					color: $metaMenuActiveGrey;
					display: block;
					font-family: $fontLatoBold;
					font-size: 24px;
					line-height: 30px;
					margin: 0 0 35px;
				}

				>p {
					color: $metaMenuActiveGrey;
					font-size: 18px;
					line-height: 30px;
				}
			}
		}
	}

	.slideOutMenu.open {
		max-height: 900px;

		@include max-screen($menuBreakpointRes) {
			right: 0;
		}
	}

	&.fixedBreadcrumb {
		margin-bottom: 65px;

		@include max-screen($breadcrumBreakpoint) {
			margin-bottom: 43px;
		}

		&+section.breadcrumb {
			left: 0;
			position: fixed;
			top: 5px;
			width: 100%;
			z-index: 999;

			div.container {
				div.controls {
					margin-right: 0;
					@include opacity(1);
					pointer-events: all;
				}
			}

			&+.topTasks.four.slideOut {
				left: 0;
				pointer-events: all;
				position: fixed;
				top: 69px;

				@include max-screen($breadcrumBreakpoint) {
					top: 48px;
				}
			}
		}
	}
}

section.breadcrumb {
	background: $breadcrumbGrey;
	padding: 20px 0 23px;

	@include max-screen($breadcrumBreakpoint) {
		padding: 10px 0 12px;
	}

	div.container {
		max-width: 1460px;

		ul {
			float: left;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: left;

			@include max-screen($breadcrumBreakpoint) {
				display: none;
			}

			li {
				display: inline-block;
				font-size: 15px;
				padding: 0 24px 0 0;
				position: relative;

				&:after {
					color: $breadcrumbArrowPurple;
					content: '\e802';
					display: inline-block;
					font-family: $icon;
					font-size: 7px;
					position: absolute;
					right: 8px;
					top: 5px;
					vertical-align: top;
				}

				a {
					color: $mainMenuPurple;
					display: block;
					text-decoration: none;
					vertical-align: top;
				}

				a:hover {
					text-decoration: underline;
				}

				a.home {
					color: $metaMenuGrey;
					@include transition(all 0.2s ease-in-out);

					&:before {
						content: '\e800';
						font-family: $icon;
						font-size: 15px;
						margin-right: 6px;
						vertical-align: top;
					}
				}

				a.home:hover {
					color: $mainMenuPurple;
					text-decoration: none;
				}

				span {
					color: $breadcrumbGreyText;
				}
			}

			li:last-child {
				&:after {
					display: none;
				}
			}
		}

		a.backLink {
			display: none;

			@include max-screen($breadcrumBreakpoint) {
				color: $metaMenuActiveGrey;
				display: inline;
				font-size: 15px;
				text-decoration: none;
			}

			&:before {
				color: $metaMenuGrey;
				content: '\e806';
				display: inline-block;
				font-family: $icon;
				font-size: 11px;
				margin: 5px 19px 0 0;
				@include transition(all 0.2s ease-in-out);
				vertical-align: top;

				@include max-screen($breadcrumBreakpoint) {
					line-height: 16px;
				}
			}
		}

		div.controls {
			float: right;
			margin-right: -10px;
			@include opacity(0);
			pointer-events: none;
			position: relative;
			text-align: right;
			@include transition(all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s);
			width: 165px;

			@include max-screen($breadcrumBreakpoint) {
				margin: -2px 0 0;
				top: 4px;
			}

			span {
				color: $homeRed;
				cursor: pointer;
				display: inline-block;
				font-family: $fontLatoBold;
				font-size: 17px;
				@include opacity(1);
				padding: 0 0 0 15px;
				position: absolute;
				right: 0;
				@include transition(all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s);

				@include max-screen($breadcrumBreakpoint) {
					font-size: 15px;
				}

				&:hover {
					&:before {
						left: 4px;
					}
				}

				&:before {
					color: $homeRed50;
					content: '\e802';
					display: inline-block;
					font-family: $icon;
					font-size: 11px;
					left: 0;
					position: absolute;
					top: 5px;
					@include transition(all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s);
					vertical-align: top;

					@include max-screen($breadcrumBreakpoint) {
						top: 4px;
					}
				}

				&.close {
					color: $purpleTopTasks50;
					@include opacity(0);
					padding: 0 26px 0 0;

					&:before {
						border-bottom: 7px solid $topTasksBackground;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						bottom: -25px;
						content: '';
						left: auto;
						right: 2px;
						position: absolute;

						@include max-screen($breadcrumBreakpoint) {
							bottom: -14px;
						}
					}

					&:hover {
						color: $purpleTopTasks;
					}

					&:after {
						color: $purpleTopTasks;
						content: '\e808';
						display: inline-block;
						font-family: $icon;
						font-size: 14px;
						right: 0;
						position: absolute;
						top: 3px;
						vertical-align: top;
					}
				}
			}

			&.opened {
				margin-right: 0;
				@include opacity(1);
				pointer-events: all;

				span.open {
					@include opacity(0);
				}

				span.close {
					@include opacity(1);
				}
			}
		}
	}
}

div.backTopHolder {
	position: relative;

	div.backToTop {
		background-color: $orangeTextColor;
		@include border-radius(3px);
		bottom: 30px;
		@include box-shadow(0px 1px 3px 0px rgba(138, 49, 11, 0.24));
		box-sizing: border-box;
		height: 40px;
		@include opacity(0);
		padding: 7px;
		pointer-events: none;
		position: fixed;
		right: 30px;
		text-align: center;
		@include transition(all 0.2s ease-out);
		width: 40px;
		z-index: 999999;

		@include max-screen($menuBreakpointRes) {
			bottom: 10px;
			height: 20px;
			line-height: 12px;
			padding: 0;
			right: 10px;
			width: 20px;
		}

		&:after {
			content: '\e803';
			color: #ffffff;
			display: inline;
			font-family: $icon;
			font-size: 10px;

			@include max-screen($menuBreakpointRes) {
				font-size: 7px;
				line-height: 12px;
			}
		}

		&.show {
			cursor: pointer;
			@include opacity(0.8);
			pointer-events: all;
		}

		&.stopped {
			bottom: 30px;
			position: absolute;

			@include max-screen($menuBreakpointRes) {
				bottom: 10px;
			}
		}

		&:hover {
			@include opacity(1);
		}
	}
}

section.prefooter {
	background: $prefooterBackground;
	padding: 59px 0 64px;

	@include max-screen($tabletPortrait) {
		padding: 20px 0 30px;
	}

	.col6 {
		&:first-child {
			@include max-screen($tabletPortrait) {
				margin-bottom: 30px;
			}
		}
	}

	p {
		color: $prefooterText;
	}

	span.title {
		color: $prefooterTitle;
		font-family: $fontLatoLight;
		font-size: 22px;
		line-height: 35px;

		p {
			font-size: 22px;
			margin: 0;
			font-family: $fontLatoLight;
			color: $prefooterTitle;
		}
	}

	$paddingRight: column(2)+($gutterWidth * 2);

	div.resultHighlight {
		background: transparent;
		border: none;
		margin-top: 34px;
		padding: 0 $paddingRight 0 0;
		@include clear;

		@include max-screen($tabletPortrait) {
			margin-top: 14px;
			padding-right: 0;
		}

		p {
			color: $prefooterText;
		}

		div.barGraphs {
			div.chartlineHolder {
				background: rgba(255, 255, 255, 0.1);

				div.chartline {
					@include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.50));
				}
			}
		}

		div.legend {
			span {
				color: $prefooterLegend;
			}
		}

		div.chartTitle {
			color: $prefooterSubTitle;

			&+.barGraphs {
				margin-top: 9px;
			}
		}
	}

	.moreLink {
		color: $prefooterMoreLink;
		display: inline-block;
		font-size: 16px;
		margin-top: 19px;

		&:after {
			color: $prefooterMoreLinkArrow;
			line-height: 22px;
		}
	}

	div.ambassador {
		margin: 43px 0 0;
		position: relative;
		width: 100%;

		@include max-screen($tabletPortrait) {
			margin-top: 23px;
		}

		blockquote {
			color: #ffffff;
			font-family: $fontLatoBoldItalic;
			font-size: 40px;
			line-height: 49px;
			margin: 0 0 0 18.1%;
			position: relative;
			padding: 0 0 0 16.5%;

			@include max-screen($tabletPortrait) {
				font-size: 28px;
				line-height: 34px;
				margin: 0 0 20px 0;
			}

			&:before {
				background: #ffffff;
				content: '';
				display: block;
				height: 2px;
				left: 0;
				@include opacity(0.2);
				position: absolute;
				top: 29px;
				width: 13.5%;

				@include max-screen($tabletPortrait) {
					top: 17px;
				}
			}
		}

		div.person {
			left: 0;
			position: absolute;
			top: 0;
			width: column(4);

			@include max-screen($tabletPortrait) {
				@include clear;
				left: auto;
				position: relative;
				top: auto;
				width: 100%;
			}

			div.image {
				margin-top: 7px;

				@include max-screen($tabletPortrait) {
					float: left;
					width: column(3);
				}

				img {
					@include border-radius(4px);
					vertical-align: top;
					width: 43%;

					@include max-screen($tabletPortrait) {
						max-width: 80px;
						width: 100%;
					}
				}
			}

			span.name {
				color: $prefooterTitle;
				display: block;
				font-family: $fontLatoBold;
				font-size: 15px;
				line-height: 24px;
				margin: 20px 0 2px;
				@include opacity(0.5);

				@include max-screen($tabletPortrait) {
					float: left;
					margin-top: 0;
					margin-left: $gutterWidth;
					width: column(9);
				}
			}

			span.function {
				color: $prefooterTitle;
				display: block;
				font-size: 15px;
				line-height: 24px;
				@include opacity(0.5);

				@include max-screen($tabletPortrait) {
					float: left;
					margin-left: $gutterWidth;
					width: column(9);
				}
			}
		}

		p {
			color: $prefooterText;
			font-size: 18px;
			line-height: 30px;
			margin: 32px 0 0 34.6%;

			@include max-screen($tabletPortrait) {
				margin: 16px 0 0 0;
			}
		}

		&+.moreLink {
			margin-left: 34.6%;

			@include max-screen($tabletPortrait) {
				margin-left: 0;
			}
		}
	}

	div.content {
		margin-top: 36px;

		div.images {
			@include clear;

			>img {
				@include border-radius(3px);
				float: left;
				margin: 0 0 21px 3.5%;
				width: 31%;

				&:first-child {
					margin-left: 0;
				}

				&:nth-child(3n + 4) {
					margin-left: 0;
				}
			}

			>a {
				float: left;
				margin: 0 0 21px 3.5%;
				width: 31%;

				&:first-child {
					margin-left: 0;
				}

				&:nth-child(3n + 4) {
					margin-left: 0;
				}

				>img {
					@include border-radius(3px);
					max-width: 100%;
					vertical-align: top;
				}
			}
		}

		p {
			font-size: 15px;
			line-height: 24px;
			margin: 15px 0 12px;
		}
	}
}

footer {
	background: $breadcrumbGrey;
	border-top: 1px solid $footerBorderColor;
	font-size: 14px;
	padding: 50px 0 109px;

	@include max-screen($footerBreakpoint) {
		padding: 30px 0 39px;
	}

	div.container {
		@include flex;

		@include max-screen($menuBreakpointRes) {
			display: block;
		}
	}

	div.logoHolder {
		float: left;
		order: 1;
		position: relative;
		width: column(2);

		@include max-screen($menuBreakpointRes) {
			float: left;
			max-width: 151px;
			width: 100%;
		}

		@include max-screen($footerBreakpoint) {
			max-width: 100%;
		}

		a.logo {
			margin: 0;

			@include max-screen($menuBreakpointRes) {
				max-width: 151px;
			}

			svg {
				@include max-screen($menuBreakpointRes) {
					max-width: 151px;
				}
			}
		}

		div.socials {
			bottom: 26px;
			position: absolute;
			right: 29px;
			text-align: right;

			@include max-screen($menuBreakpointRes) {
				bottom: auto;
				margin-top: 20px;
				position: relative;
				right: auto;
				text-align: left;
			}

			>a {
				display: inline-block;
				margin-left: 5px;
				@include transition(all 0.2s ease-out);

				@include max-screen($menuBreakpointRes) {
					margin-left: 0;
					margin-right: 5px;
				}

				&:hover {
					@include opacity(0.6);
				}
			}
		}
	}

	ul.doormat {
		@extend .column;
		@include flex;
		list-style: none;
		margin-top: 26px;
		order: 2;
		padding: 0;
		width: column(8);

		@include max-screen($menuBreakpointRes) {
			float: none;
			margin-left: 0;
			width: 100%;
		}

		@include max-screen($footerBreakpoint) {
			display: none;
		}

		li:first-child {
			margin-left: 0;
		}

		>li {
			border-left: 1px solid $footerDivider;
			display: block;
			line-height: 18px;
			padding: 0 5px 0 18px;
			@extend .column;
			width: column(3);

			span {
				color: $metaMenuActiveGrey;
				display: block;
				font-family: $fontLatoBold;
				margin: -3px 0 33px;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					margin: 6px 0 0;

					a {
						color: $footerTextColor;
						text-decoration: none;
					}

					a:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	div.telHolder {
		border-left: 1px solid $footerDivider;
		float: right;
		line-height: 18px;
		margin: 26px 0 0 $gutterWidth;
		order: 3;
		padding: 0 0 0 18px;
		width: column(2);

		@include max-screen($menuBreakpointRes) {
			margin-bottom: 73px;
			width: column(6);
		}

		@include max-screen($footerBreakpoint) {
			border-left: none;
			margin: 26px 0 0;
			padding: 0;
			width: 100%;
		}

		div.telNum {
			margin: 0 0 18px;

			@include max-screen($menuBreakpointRes) {
				float: left;
				width: 50%;
			}

			@include max-screen(360px) {
				float: none;
				width: 100%;
			}

			span.title {
				color: $metaMenuGrey;
				display: block;
				font-family: $fontLato;
				margin: -3px 0 18px;

				@include max-screen($menuBreakpointRes) {
					margin: -3px 0 13px;
				}

				@include max-screen($footerBreakpoint) {
					margin-left: 0;
				}
			}

			p,
			a,
			a:hover {
				color: $metaMenuActiveGrey;
				font-family: $fontLatoBold;
				font-size: 18px;
			}
		}

		div.address {
			line-height: 24px;

			@include max-screen($menuBreakpointRes) {
				float: right;
				margin: -6px 0 0;
				padding: 0 5px 0 16px;
				width: 50%;
			}

			@include max-screen(360px) {
				float: none;
				margin: 10px 0 0;
				padding: 0;
				width: 100%;
			}

			p {
				color: $footerTextColor;
				margin-bottom: 24px;

				a {
					color: $mainMenuPurple;
					text-decoration: none;
				}

				a:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

section.content {
	line-height: 29px;
	padding: 30px 0 40px;

	@include max-screen($menuBreakpointRes) {
		padding: 30px 0 18px;

		& > .container {
			& > .row {
				margin: 0;
			}
		}
	}

	div.hero {

		&.hasImage {
			position: relative;
			margin: 30px 0 50px;

			h1 {
				position: absolute;
				bottom: 40px;
				color: white;
				z-index: 1;
			}

			img {
				margin: 0;
			}

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
				background: linear-gradient(rgba(83, 32, 120, 0.15) 50%, rgba(42, 16, 60, 1));
				top: 0;
			}

			@include max-screen($menuBreakpointRes) {
				margin: 30px -20px 50px;
			}
		}
	}

	div.submenu {
		background: $breadcrumbGrey;
		@include border-radius(3px);
		float: right;
		line-height: 24px;
		margin-left: $gutterWidth;
		padding: 29px 30px 17px;

		@include max-screen($menuBreakpointRes) {
			background: #ffffff;
			border: 1px solid $mainMenuBackground;
			float: left;
			margin: 0 0 40px;
			max-width: 340px;
			padding: 0;
			width: 100%;
		}

		>span {
			color: $submenuTitle;
			display: block;
			font-family: $fontLatoBold;
			margin: 0 0 17px;

			@include max-screen($menuBreakpointRes) {
				display: none;
			}
		}

		>span.openSub {
			display: none;

			@include max-screen($menuBreakpointRes) {
				color: $orangeTextColor;
				cursor: pointer;
				display: block;
				font-family: $fontLato;
				font-size: 16px;
				margin: 0;
				padding: 12px 23px 12px 15px;
			}

			&:after {
				@include max-screen($menuBreakpointRes) {
					color: $moreLinkIconColor;
					content: '\e807';
					display: inline-block;
					float: right;
					font-family: $icon;
					font-size: 8px;
					margin: 2px 0 0;
					@include transition(all 0.2s ease-in-out);
				}
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			@include max-screen($menuBreakpointRes) {
				font-size: 15px;
				margin: 0 16px;
				max-height: 0;
				@include opacity(0);
				overflow: hidden;
				@include transition(all 0.2s ease-in-out);
			}

			li {
				padding-bottom: 14px;

				a {
					color: $orangeTextColor;
					display: inline-block;
					font-family: $fontLatoBold;
					text-decoration: none;
					@include transition(all 0.2s ease-in-out);
				}

				a.active {
					color: $submenuActiveTitle;
					padding-left: 19px;
					position: relative;

					&:before {
						color: $moreLinkIconColor;
						content: '\e802';
						display: block;
						font-family: $icon;
						font-size: 6px;
						left: 0;
						line-height: 25px;
						position: absolute;
						top: 0;
						@include transition(all 0.2s ease-in-out);
						vertical-align: top;
					}
				}

				a:hover {
					color: $submenuActiveTitle;
				}
			}
		}
	}

	div.submenu.showSub {
		>span.openSub {
			&:after {
				@include rotate(180);
			}
		}

		ul {
			@include max-screen($menuBreakpointRes) {
				max-height: 600px;
				@include opacity(1);
			}
		}
	}

	div.col8 {
		margin-left: 0;

		@include max-screen($menuBreakpointRes) {
			float: left;
			width: 100%;
		}
	}

	h1 {
		&+img {
			margin: 33px 0 42px;

			@include max-screen($breadcrumBreakpoint) {
				@include border-radius(0);
				margin-left: -20px;
				max-width: 100%;
				width: 100%;
			}

			@include max-screen($tabletPortrait) {
				margin: 10px 0 10px -20px;
			}
		}
	}

	>div>div>img {
		@include border-radius(4px);
	}

	.intro {
		display: block;
		font-size: 24px;
		line-height: 38px;
		margin-bottom: 51px;

		@include max-screen($breadcrumBreakpoint) {
			font-size: 18px;
			line-height: 29px;
		}
	}

	div.umb-grid {
		h2 {
			margin: 45px 0 9px;
		}

		p+h3 {
			margin-top: 44px;
		}

		section.blCTA {
			h2{
				margin:0;
			}
			
			background: $colorGrey;
			border-radius: 3px;
			padding: 30px;
		
			@include min-screen($tabletPortrait) {
				padding: 30px 100px;
			}
		}

		blockquote {
			color: $homeGreen;
			font-family: $fontLatoItalic;
			font-size: 24px;
			line-height: 38px;
			margin: 45px 0 37px;
			position: relative;
			padding: 0 12.5%;

			@include max-screen($breadcrumBreakpoint) {
				margin: 35px 0 17px;
				padding: 20px 0 0;
			}

			&:before {
				background: $blockQuoteGreen;
				content: '';
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 19px;
				width: 9.8%;

				@include max-screen($breadcrumBreakpoint) {
					top: 0;
					width: 74px;
				}
			}
		}

		blockquote+p {
			span.quotedPerson {
				color: $metaMenuGrey;
				display: block;
				font-family: $fontLatoBold;
				font-size: 13px;
				margin: -20px 12.5% 43px;
				text-transform: uppercase;

				@include max-screen($breadcrumBreakpoint) {
					margin: 0;
				}
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 6px;
				padding-left: 30px;
				position: relative;

				&:before {
					color: $moreLinkIconColor;
					content: '•';
					font-size: 14px;
					left: 0;
					position: absolute;
					top: 2px;
					vertical-align: top;
				}

				a {
					color: $orangeTextColor;
					text-decoration: none;
				}

				a:hover {
					text-decoration: underline;
				}

				p {
					margin: 0;
				}
			}
		}

		ol {
			counter-reset: number;
			list-style: none;
			margin: 11px 0 40px;
			padding: 0;

			li {
				margin: 0 0 6px;
				padding-left: 30px;
				position: relative;

				&:before {
					counter-increment: number;
					content: counter(number) '.';
					color: $moreLinkIconColor;
					font-size: 18px;
					left: 0;
					position: absolute;
					top: 0;
					vertical-align: top;
				}

				a {
					color: $orangeTextColor;
					text-decoration: none;
				}

				a:hover {
					text-decoration: underline;
				}
			}
		}

		.iframeRatio {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56%;

			iframe.autoScaling {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.intro {
			display: block;
			font-size: 24px;
			line-height: 38px;
			margin-bottom: 51px;

			@include max-screen($breadcrumBreakpoint) {
				font-size: 18px;
				line-height: 29px;
			}
		}

		.sup {
			display: block;
			font-size: 15px;
			line-height: 24px;
			margin: 48px 0 0;

			@include max-screen($breadcrumBreakpoint) {
				font-size: 13px;
				line-height: 22px;
				margin: 38px 0 0;
			}
		}

		h1+.umb-grid p+img {
			margin-top: 0;
		}

		img {
			@include border-radius(3px);
			margin-top: 38px;

			@include max-screen($breadcrumBreakpoint) {
				@include border-radius(0);
				margin-left: -20px;
				max-width: calc(100% + 40px);
				width: calc(100% + 40px);
			}
		}

		.video {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
			margin-top: 38px;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}

		p {
			margin-bottom: 26px;

			@include max-screen($breadcrumBreakpoint) {
				font-size: 15px;
				line-height: 24px;
			}

			>a,
			>span>a {
				color: $orangeTextColor;
				text-decoration: none;
			}

			>a:hover,
			>span>a:hover {
				text-decoration: underline;
			}

			>a.morelink:hover,
			>span.morelink a:hover {
				text-decoration: none;
			}
		}

		audio {
			margin-bottom: 25px;
		}
	}

	p {
		margin-bottom: 26px;

		@include max-screen($breadcrumBreakpoint) {
			font-size: 15px;
			line-height: 24px;
		}
	}

	ul.disabilityList {
		list-style: none;
		margin: 0;
		padding: 15px 0;

		>li {
			>a {
				color: $orangeTextColor;
				display: block;
				font-size: 18px;
				line-height: 29px;
				position: relative;
				padding: 0 0 0 19px;
				text-decoration: none;

				&:before {
					color: $homeRed50;
					content: '\e802';
					font-family: $icon;
					font-size: 9px;
					left: 0;
					line-height: 28px;
					position: absolute;
					top: 0;
					@include transition(all 0.2s ease-in-out);
				}

				&:hover {
					&:before {
						left: 3px;
					}
				}
			}
		}
	}

	span.newsDate {
		color: $topicalGrey;
		display: block;
		font-size: 13px;
		margin-bottom: 18px;
		margin-top: -30px;
		text-transform: uppercase;
	}
}

.resultHighlight {
	background: #ffffff;
	border: 1px solid $mainMenuBackground;
	border-left: 6px solid $resultBlockLeftBorder;
	@include border-radius(3px);
	margin-top: 43px;
	padding: 29px 39px 21px 34px;
	position: relative;

	@include clear;

	@include max-screen(520px) {
		margin: 70px auto 0;
	}

	div.content {
		float: left;
		width: 47.2%;

		@include clear;

		@include max-screen($tabletPortrait) {
			float: none;
			width: 100%;
		}

		p.source {
			margin-top: 5px;
		}
	}

	span.title {
		color: $metaMenuActiveGrey;
		font-family: $fontLatoBold;
		font-size: 24px;
		line-height: 30px;
		margin: 0 0 12px;
	}

	span.subTitle {
		display: block;
		color: $metaMenuGrey;
		font-family: $fontLatoBold;
		font-size: 16px;
		margin: 0 0 20px;
	}

	p {
		color: $metaMenuActiveGrey;
		font-size: 15px;
		line-height: 24px;
	}

	p.source {
		color: $metaMenuActiveGrey;
		display: inline-block;
		font-size: 15px;
		line-height: 24px;
		margin: 30px 0 0;

		a {
			padding-left: 6px;
		}
	}

	div.articleHolder {
		margin: 0 0 44px;

		@include clear;

		div.image {
			float: left;
			margin-right: 4.5%;
			width: 17.2%;

			@include max-screen(520px) {
				float: none;
				margin: 0 0 20px;
				max-width: 120px;
				width: 100%;
			}

			img {
				@include border-radius(3px);
				margin: 6px 0 0;
				max-width: 100%;
				vertical-align: top;
			}
		}

		div.content {
			float: left;
			width: 75%;

			@include max-screen(520px) {
				float: none;
				width: 100%;
			}

			span.title {
				color: $metaMenuActiveGrey;
				font-family: $fontLatoBold;
				font-size: 21px;
				line-height: 26px;
			}
		}
	}
}

div.chartTitle {
	color: $metaMenuActiveGrey;
	font-family: $fontLatoBold;
	font-size: 16px;
	line-height: 30px;
	margin: 40px 0 5px;

	&:first-child {
		margin-top: 0;
	}
}

div.graphsHolder {
	float: left;
	margin-left: 5.6%;
	padding-bottom: 20px;
	width: 47.2%;

	@include max-screen($tabletPortrait) {
		float: none;
		margin-left: 0;
		width: 100%;
	}

	div.barGraphs {
		margin-top: 0;
	}
}

div.barGraphs {
	margin-top: 31px;

	div.chartlineHolder {
		position: relative;
		background: $breadcrumbGrey;
		@include border-radius(2px);
		width: 100%;

		>div.chartline {
			position: relative;
			background: $prefooterBackground;
			@include border-radius(2px);
			color: #ffffff;
			font-family: $fontLatoBold;
			font-size: 21px;
			line-height: 30px;
			padding: 6px 10px 8px;
			margin-top: 10px;
			overflow: hidden;

			&.shadow {
				position: absolute;
				margin: 0;
				top: 0;
				left: 0;
				color: $prefooterBackground;
				background-color: transparent;
				width: 100%;
				overflow: visible;
				box-shadow: none !important;
			}
		}

		&:nth-child(2),
		&.big {

			>div.chartline {
				background: $homePurple;

				&.shadow {
					color: $homePurple;
					background: transparent;
				}
			}
		}

		&:nth-child(3),
		&.big {

			>div.chartline {
				background: $blueGraph;

				&.shadow {
					color: $blueGraph;
					background: transparent;
				}
			}
		}

		&:nth-child(4),
		&.big {

			>div.chartline {
				background: $homeOrange;

				&.shadow {
					color: $homeOrange;
					background: transparent;
				}
			}
		}
	}

	&.big {
		padding-top: 3px;

		div.chartlineHolder {
			>div.chartline {
				background: $blueGraph;
				font-family: $fontLatoLight;
				font-size: 60px;
				line-height: 72px;
				padding: 41px 32px 36px;

				strong {
					font-family: $fontLatoBold;
				}
			}
		}

		div.number {
			background: $blueGraph;
			@include border-radius(2px);
			color: #ffffff;
			font-family: $fontLatoBold;
			font-size: 60px;
			line-height: 72px;
			padding: 41px 20px 36px;
			text-align: center;
		}

		&+div.legend {
			span {
				&:before {
					background: $blueGraph;
				}
			}
		}
	}
}

div.legend {
	margin: 23px 0 0;
	display: flex;
	flex-direction: column;

	span {
		color: $mainMenuPurple;
		display: inline-block;
		font-size: 16px;
		line-height: 30px;
		margin-right: 28px;
		padding: 0 0 0 21px;
		position: relative;

		&:before {
			background: $prefooterBackground;
			@include border-radius(50%);
			content: '';
			display: inline-block;
			height: 12px;
			left: 0;
			position: absolute;
			top: 9px;
			width: 12px;
		}

		&:nth-child(2) {
			&:before {
				background: $homePurple;
			}
		}

		&:nth-child(3) {
			&:before {
				background: $blueGraph;
			}
		}

		&:nth-child(4) {
			&:before {
				background: $homeOrange;
			}
		}
	}
}

.highlighted {
	background: $homeOrange20;
	border-left: 6px solid $homeOrange;
	@include border-radius(3px);
	margin-top: 100px;
	position: relative;

	@include clear;

	@include max-screen(520px) {
		margin: 70px auto 0;
		max-width: 335px;
	}

	span.blockTitle {
		display: block;
		margin: 0 0 0 11px;
		position: absolute;
		top: -66px;

		svg {
			margin: 13px 19px 0 0;
			vertical-align: top;

			.dot {
				fill: $homeOrange;
			}

			.link {
				fill: $homeOrange50;
			}
		}

		span.title {
			color: $homeOrange;
			font-family: $fontNanumPen;
			font-size: 25px;
			vertical-align: top;
		}
	}

	div.image {
		float: left;
		width: 32.82%;

		@include max-screen(520px) {
			float: none;
			width: 100%;
		}

		img {
			@include border-radius(0);
			margin-top: 0;
			vertical-align: top;
		}

		&+div.content {
			float: left;
			padding: 31px 30px 10px 39px;
			width: 67.18%;

			@include max-screen(520px) {
				float: none;
				padding: 24px 30px 21px;
				width: 100%;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	div.content {
		color: $highlightedTextColor;
		font-size: 18px;
		padding: 34px 30px 1px;

		p {
			margin-bottom: 12px;
		}

		.title {
			font-family: $fontLatoBold;
			font-size: 21px;
		}

		.moreLink {
			display: inline-block;
			font-size: 16px;
			margin: 12px 0 0;
		}

		ul {
			list-style: none;
			margin: 11px 0 11px;
			padding: 0;

			li {
				margin: 0 0 6px;
				padding-left: 30px;
				position: relative;

				&:before {
					color: $homeOrange;
					content: '•';
					font-size: 14px;
					left: 0;
					position: absolute;
					top: 2px;
					vertical-align: top;
				}

				a {
					color: $orangeTextColor;
					text-decoration: none;
				}

				a:hover {
					text-decoration: underline;
				}

				p {
					margin: 0;
				}
			}
		}
	}

	&+.additionalContent {
		margin-top: 156px;
	}
}

.additionalContent {
	background: #ffffff;
	border: 1px solid $moreLinkIconColor;
	border-left: 6px solid $moreLinkIconColor;
	@include border-radius(3px);
	margin: 100px 0 0;
	position: relative;

	@include clear;

	span.blockTitle {
		display: block;
		margin: 0 0 0 11px;
		position: absolute;
		top: -66px;

		svg {
			margin: 13px 19px 0 0;
			vertical-align: top;

			.dot {
				fill: $homeRed;
			}

			.link {
				fill: $homeRed50;
			}
		}

		span.title {
			color: $homeRed;
			font-family: $fontNanumPen;
			font-size: 25px;
			vertical-align: top;
		}
	}

	div.content {
		color: $metaMenuActiveGrey;
		font-size: 15px;
		padding: 34px 30px 33px;

		p {
			line-height: 24px;
			margin-bottom: 12px;

			a {
				color: $orangeTextColor;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.title {
			color: $metaMenuGrey;
			display: block;
			font-family: $fontLatoBold;
			font-size: 13px;
			line-height: 25px;
			text-transform: uppercase;
			margin-top: 42px;
		}

		p:first-child {
			margin-top: 0;

			>.title {
				margin-top: 0;
			}
		}

		.moreLink {
			display: inline-block;
			font-size: 16px;
			margin: 12px 0 0;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-top: 0 0 6px;
				padding-left: 30px;
				position: relative;

				&:before {
					color: $homeRed;
					content: '•';
					font-size: 14px;
					left: 0;
					position: absolute;
					top: 2px;
					vertical-align: top;
				}

				&:first-child {
					margin-top: 4px;
				}

				a {
					color: $orangeTextColor;
					display: block;
					font-size: 15px;
					line-height: 21px;
					position: relative;
					padding: 0 0 0 19px;
					text-decoration: none;

					&:before {
						color: $homeRed50;
						content: '\e802';
						font-family: $icon;
						font-size: 7px;
						left: 0;
						line-height: 24px;
						position: absolute;
						top: 0;
						@include transition(all 0.2s ease-in-out);
					}

					&:hover {
						&:before {
							left: 3px;
						}
					}
				}
			}
		}
	}
}

div.pageTools {
	@extend .clearfix;
	border-top: 1px solid $mainMenuBackground;
	float: left;
	margin: 27px 0 0;
	padding: 20px 0;
	width: 100%;

	div.toTop {
		color: $orangeTextColor;
		cursor: pointer;
		float: left;
		font-size: 16px;

		@include max-screen($breadcrumBreakpoint) {
			float: none;
			margin-top: 25px;
			width: 100%;
		}

		&:before {
			color: $moreLinkIconColor;
			content: '\e803';
			display: inline-block;
			font-family: $icon;
			font-size: 7px;
			margin: 2px 15px 0 0;
			@include transition(all 0.2s ease-in-out);
			vertical-align: top;
		}
	}

	div.toTop:hover {
		&:before {
			margin: -1px 15px 0 0;

			@include max-screen($breadcrumBreakpoint) {
				margin: 2px 15px 0 0;
			}
		}
	}

	div.shareTools {
		@include clear;
		color: $metaMenuGrey;
		float: right;
		font-family: $fontLatoBold;
		font-size: 13px;
		line-height: 34px;
		text-transform: uppercase;

		@include max-screen($breadcrumBreakpoint) {
			float: none;
			width: 100%;
		}

		div.dcShare {
			float: right;
			margin-left: 20px;

			@include max-screen(360px) {
				float: none;
				margin-left: 0;
				width: 100%;
			}

			>a {
				@include border-radius(3px);
				margin-left: 9px;
				max-height: 30px;
				max-width: 30px;
				overflow: hidden;
				vertical-align: top;

				&:first-child {
					@include max-screen(360px) {
						margin-left: 0;
					}
				}

				svg {
					max-height: 30px;
					max-width: 30px;
				}
			}
		}
	}
}

@media print {
	body {
		font-size: 15px;
		line-height: 24px;

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 18px;
		}
	}

	header {
		a.logo {
			margin: 5px 0;
		}

		div.hamburgerSearch,
		div.nav {
			display: none;
		}
	}

	section.breadcrumb {
		display: none;
	}

	section.content {
		color: #000000;
		line-height: 24px;
		padding: 20px 0 15px;

		h1,
		div.umb-grid blockquote,
		div.umb-grid ol li:before,
		div.umb-grid ul li:before {
			color: #000000;
		}

		div.umb-grid blockquote {
			margin-top: 25px;
			padding: 0 20px;
		}

		div.umb-grid blockquote:before {
			display: none;
		}

		div.umb-grid .sup {
			font-family: $fontLatoItalic;
			font-size: 13px;
			margin-top: 25px;
		}

		div.umb-grid .intro {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 15px;
		}

		div.umb-grid .video {
			display: none;
		}

		div.umb-grid p {
			margin-bottom: 16px;
		}

		div.umb-grid a,
		div.umb-grid p a {
			color: #000000;
			text-decoration: underline;
		}

		div.umb-grid a:after,
		div.umb-grid a:before {
			display: none;
		}

		div.umb-grid h2,
		div.umb-grid h3,
		div.umb-grid p+h3 {
			margin: 25px 0 8px;
		}

		img {
			max-width: 75%;
		}

		.submenu,
		.pageTools {
			display: none;
		}

		.col8 {
			width: 100%;
		}
	}

	footer {
		background: #ffffff;
		padding: 20px 0 30px;

		div.logoHolder,
		ul.doormat {
			display: none;
		}

		div.telHolder {
			width: 100%;

			div.telNum,
			div.address {
				float: left;
				width: 50%;

				.moreLink {
					display: none;
				}
			}
		}
	}
}

$cookieBackground: #2C1140;
$cookieText: #AA8FB9;
$cookieLink: #D94B1B;
$cookieClose: #7F5796;

#cookieOptIn {
	background: $cookieBackground;
	padding: 18px 0 20px;
	pointer-events: all;
	position: relative;
	width: 100%;

	.cookieOptInInner {
		max-width: 1440px;
		margin: 0 auto;
		position: relative;
		min-height: 20px;
		padding: 0 60px 0 10px;
		line-height: 22px;
		color: $cookieText;

		&:after {
			content: " ";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
		}

		>span {
			display: inline-block;
			margin-right: 25px;

			@include max-screen(750px) {
				display: block;
				margin: 0 0 7px;
			}
		}

		a {
			color: $cookieLink;
			text-decoration: none;

			&:after {
				color: $moreLinkIconColor;
				content: '\e802';
				display: inline;
				font-family: $icon;
				font-size: 6px;
				line-height: 25px;
				margin: 1px 0 0 11px;
				@include transition(all 0.2s ease-in-out);
				vertical-align: top;
			}
		}

		a:hover {
			&:after {
				margin: 1px 5px 0 6px;
			}
		}

		.closeCookie {
			color: #ffffff;
			font-family: $fontLatoBold;
			font-size: 18px;
			position: absolute;
			right: 10px;
			top: 2px;

			&:after {
				color: $cookieClose;
				content: '\e808';
				display: inline-block;
				font-family: $icon;
				font-size: 14px;
				margin: 0 0 0 15px;
				text-decoration: none;
				@include transition(all 0.2s ease-in-out);
				vertical-align: top;
			}
		}

		.closeCookie:hover {
			&:after {
				color: $cookieText;
			}
		}
	}
}

$alphabet: a,
b,
c,
d,
e,
f,
g,
h,
i,
j,
k,
l,
m,
n,
o,
p,
q,
r,
s,
t,
u,
v,
w,
x,
y,
z;

@each $letter in $alphabet {
	li.#{$letter} {
		&:after {
			content: '#{$letter}';
		}
	}
}

ul.resultList li {
	&:before {
		content: '';
	}

	padding: 0;
	position: relative;

	&:after {
		font-size: 30px;
		position: absolute;
		right: 0;
	}
}

.serviceWidget {
	background: $sWTopColor;
	@include border-radius(3px);
	margin-top: 53px;
	position: relative;

	@include max-screen($tabletPortrait) {
		margin-top: 20px;
	}

	&.load {
		pointer-events: none;

		div.widgetFooter {
			&:after {
				@include opacity(1);
				z-index: 9999;
			}
		}
	}

	span.title {
		color: $sWTitleColor;
		display: block;
		font-family: $fontLatoBold;
		font-size: 24px;
		line-height: 38px;
		padding: 20px 20px 18px;
	}

	form {
		display: block;
		padding: 0 20px 20px;

		@include clear;

		label {
			display: block;
			float: left;
			margin-left: 2%;
			max-width: 32.37%;
			position: relative;
			width: 100%;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-left: 0;
				margin-top: 10px;
				max-width: 100%;
			}

			&:first-child {
				margin-left: 0;

				@include max-screen($tabletPortrait) {
					margin-top: 0;
				}
			}

			&:after {
				background: transparent;
				color: #3A9AD7;
				content: '\E807';
				font-family: $icon;
				font-size: 8px;
				padding: 0 0 2px;
				pointer-events: none;
				position: absolute;
				right: 12px;
				top: 5px;
				@include transition(all 0.2s ease-in-out);
			}

			&:hover {
				&:after {
					/*top: 8px;*/
				}
			}

			select {
				@include appearance(none);
				background: #ffffff;
				border: 1px solid rgba(0, 126, 204, 0.30);
				@include border-radius(4px);
				@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
				color: $sWTitleColor;
				cursor: pointer;
				display: inline-block;
				font-family: $fontLato;
				font-size: 16px;
				margin: 0;
				line-height: 20px;
				outline: none;
				padding: 8px 36px 8px 8px;
				vertical-align: top;
				width: 100%;

				&.disabled,
				&:disabled {
					cursor: default;
					@include opacity(0.5);

					&:hover {
						&:after {
							top: 9px;
						}
					}
				}

				&.hide {
					display: none;
				}

				@include clear;
			}

			&.select {
				cursor: pointer;
				max-width: 49%;
				position: relative;
				width: 100%;

				@include max-screen($tabletPortrait) {
					float: none;
					margin-left: 0;
					margin-top: 10px;
					max-width: 100%;
				}

				&:after {
					display: none;
				}
			}

			.styledSelect {
				background: #ffffff;
				border: 1px solid rgba(0, 126, 204, 0.30);
				@include border-radius(4px);
				@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
				color: $sWTitleColor;
				cursor: pointer;
				display: inline-block;
				font-family: $fontLato;
				font-size: 16px;
				margin: 0;
				line-height: 20px;
				outline: none;
				padding: 8px 36px 8px 12px;
				vertical-align: top;
				width: 100%;

				&:after {
					background: transparent;
					color: #3A9AD7;
					content: '\E807';
					font-family: $icon;
					font-size: 8px;
					padding: 0 0 2px;
					pointer-events: none;
					position: absolute;
					right: 12px;
					top: 9px;
					@include transition(all 0.2s ease-in-out);
				}

				&:hover {
					&:after {
						top: 12px;
					}
				}

				&:active,
				&.active {
					&:hover {
						&:after {
							top: 9px;
						}
					}
				}

				&.disabled,
				&:disabled {
					cursor: default;
					@include opacity(0.5);

					&:hover {
						&:after {
							top: 9px;
						}
					}
				}
			}

			.options {
				background-color: white;
				border: 1px solid rgba(0, 126, 204, 0.30);
				@include border-radius(3px);
				@include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.15));
				display: none;
				font-size: 16px;
				left: 0;
				list-style: none;
				margin: 0;
				padding: 4px 0 11px;
				position: absolute;
				right: 0;
				top: 100%;
				top: calc(100% + 4px);
				z-index: 999;

				li {
					color: $sWTitleColor;
					line-height: 30px;
					margin: 0;
					padding: 0 12px;

					&:hover,
					&.selected {
						background-color: $selectDropdown;
					}
				}
			}
		}

		button {
			background: $homeBlue;
			border: none;
			@include border-radius(4px);
			color: #ffffff;
			cursor: pointer;
			font-family: $fontLatoBold;
			font-size: 16px;
			font-weight: normal;
			line-height: 18px;
			margin-left: 2%;
			padding: 10px 11px 9px;
			vertical-align: top;
			width: 31%;
		}
	}

	&.threeSelects {
		label {
			width: 23.5%;

			&.select {
				width: 32%;

				@include max-screen($tabletPortrait) {
					max-width: 100%;
					width: 100%;
				}
			}
		}

		button {
			width: 22.5%;
		}
	}

	button.showResults {
		border-radius: 5px;
		background-color: $homeOrange;
		padding: 9px 20px;
		border: none;
		margin: 0 0 30px 20px;
		color: white;
		font-size: 18px;
		line-height: 18px;
	}

	div.resultsHolder {
		background: $sWColor;

		@include clear;

		a.tipBlock {
			background: #ffffff;
			border-left: 6px solid $homeOrange;
			@include border-radius(3px);
			@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.10));
			color: $highlightedTextColor;
			display: block;
			margin: 21px 20px 8px;
			padding: 16px 15px 15px 61px;
			position: relative;
			text-decoration: none;
			@include transition(all 0.2s ease-in-out);
			vertical-align: top;

			>span.tip {
				color: $homeOrange;
				font-family: $fontNanumPen;
				font-size: 25px;
				left: 18px;
				position: absolute;
				top: 16px;
			}

			>span {
				display: block;
				font-size: 18px;
				font-family: $fontLatoBold;
				margin-top: 1px;
			}

			&:after {
				color: transparent;
				content: '\E802';
				font-family: $icon;
				font-size: 16px;
				height: 17px;
				line-height: 18px;
				margin-top: -10px;
				position: absolute;
				right: 23px;
				top: 50%;
				@include transition(all 0.2s ease-in-out);
				vertical-align: top;
				width: 12px;
			}

			&:hover {
				color: $homeOrange;

				&:after {
					color: $homeOrange;
					right: 20px;
				}
			}
		}

		ul.resultList {
			list-style: none;
			margin: 4px 20px 28px;
			padding: 0;

			@include max-screen($tabletPortrait) {
				margin-left: 10px;
				margin-right: 10px;
			}

			@include clear;

			li {
				display: block;
				width: 100%;

				&.letter {
					display: block;
					position: relative;
					width: 100%;

					span {
						color: rgba(1, 62, 102, 0.5);
						font-family: $fontLatoBold;
						font-size: 18px;
						left: 0;
						line-height: 28px;
						position: absolute;
						top: 9px;

						@include max-screen($tabletPortrait) {
							display: block;
							padding: 5px 15px;
							position: relative;
							top: 0;
						}
					}

					&:first-child {
						&+li {
							a {
								&:before {
									display: none;

									@include max-screen($tabletPortrait) {
										display: block;
									}
								}
							}
						}
					}
				}

				a {
					background: transparent;
					@include border-radius(3px);
					@include box-shadow(0 0 0 0 rgba(0, 60, 101, 0.10));
					color: $homeBlue;
					display: block;
					font-family: $fontLatoBold;
					font-size: 18px;
					line-height: 28px;
					margin: 0 0 0 38px;
					padding: 9px 15px 11px;
					position: relative;
					text-decoration: none;
					@include transition(all 0.2s ease-in-out);

					@include max-screen($tabletPortrait) {
						margin: 0;
					}

					@include clear;

					&:before {
						border-top: 1px solid rgba(255, 255, 255, 0.75);
						content: '';
						display: block;
						position: absolute;
						top: -1px;
						width: calc(100% - 15px);
					}

					&:after {
						color: transparent;
						content: '\E802';
						font-family: $icon;
						font-size: 8px;
						position: absolute;
						right: 13px;
						top: 10px;
						@include transition(all 0.2s ease-in-out);
					}

					&:hover {
						background: #ffffff;
						color: $sWTitleColor;
						@include box-shadow(0 1px 1px 0 rgba(0, 60, 101, 0.10));

						&:after {
							color: $homeBlue;
							right: 10px;
						}

						&:before {
							top: 0;
							width: calc(100% - 17px);
						}
					}
				}
			}
		}
	}

	div.widgetFooter {
		background: #ffffff;
		border: 1px solid $sWColor;
		border-top: none;
		@include border-radius(0 0 3px 3px);
		color: $sWTitleColor;
		padding: 11px 17px 16px;

		&:after {
			background: url(/css/img/load.svg) rgba(0, 61, 101, 0.4) no-repeat center center;
			@include border-radius(3px);
			content: '';
			display: block;
			height: 100%;
			opacity: 0;
			left: 0;
			position: absolute;
			top: 0;
			@include transition(opacity 0.2s ease-in-out);
			@include transition-delay(1s);
			width: 100%;
			z-index: -1;
		}

		>a {
			color: $orangeTextColor;
			margin: 0 0 0 11px;
			text-decoration: none;
			@include transition(all 0.2s ease-in-out);

			@include max-screen($tabletPortrait) {
				display: block;
				margin: 0;
			}

			&:after {
				color: $moreLinkIconColor;
				content: '\e802';
				display: inline;
				font-family: $icon;
				font-size: 8px;
				line-height: 26px;
				margin: 0 0 0 11px;
				@include transition(all 0.2s ease-in-out);
				vertical-align: middle;
			}

			&:hover {
				color: $submenuActiveTitle;

				&:after {
					margin: 0 5px 0 6px;
				}
			}
		}
	}
}

div.pageWidgets {
	@include clear;
}

div.topTasks {
	background: $topTasksBackground;
	@include border-radius(3px);
	margin-top: 51px;
	padding: 0 20px 20px;
	pointer-events: none;
	position: relative;

	&.slideOut {
		@include border-radius(0);
		left: 0;
		margin-bottom: 0;
		margin-top: 0;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		@include transition(max-height 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s);
		top: 233px;
		width: 100%;
		z-index: 999;

		@include max-screen($breadcrumBreakpoint) {
			top: 144px;
		}

		>div.container {
			max-width: 940px;

			@include max-screen(825px) {
				padding: 0 10px;
			}
		}

		a {
			&:hover {
				>span.text {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	&.four {
		a {
			@include box-shadow(0px 0px 20px 0px rgba(0, 0, 0, 0.30));
			padding: 13px 5px;
			margin: 31px 0 29px 1.6%;
			width: 23.8%;

			@include max-screen($breadcrumBreakpoint) {
				margin-left: 0;
				width: 100%;
			}

			>svg {
				height: 55px;
				width: 52px;
			}

			>span.text {
				display: inline-block;
				min-width: 62%;
				padding: 5px 0 0 7px;
				text-align: left;

				@include max-screen(976px) {
					font-size: 15px;
					padding-left: 4px;
				}

				@include max-screen(825px) {
					font-size: 14px;
					line-height: 19px;
					padding-top: 7px;
				}

				@include max-screen($tabletPortrait) {
					font-size: 18px;
					line-height: 21px;
					padding-top: 5px;
				}

				&:after {
					bottom: -79px;

					@include max-screen(976px) {
						bottom: -99px;
					}
				}
			}

			&:first-child {
				margin-left: 0;
			}

			&:nth-child(2) {
				margin-left: 1.6%;

				@include max-screen($breadcrumBreakpoint) {
					margin-left: 0;
				}
			}

			&:hover {
				>span.text {
					&:after {
						bottom: -81px;

						@include max-screen(976px) {
							bottom: -101px;
						}
					}
				}
			}
		}

		a:nth-child(1) {
			margin-left: 0;

			@include max-screen($breadcrumBreakpoint) {
				margin-top: 25px;
			}
		}
	}

	&.slideOut.opened {
		max-height: 200px;

		@include max-screen($breadcrumBreakpoint) {
			max-height: 900px;
		}

		@include max-screen-height(530px) {
			height: calc(100% - 43px);
			overflow-y: scroll;
		}
	}

	@include max-screen($tabletPortrait) {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	@include clear;

	&:hover {
		a {
			background: rgba(255, 255, 255, 0.9);
		}
	}

	>span.title {
		color: $topTasksTitle;
		display: block;
		font-family: $fontLatoBold;
		font-size: 24px;
		padding: 16px 0 20px;
	}

	a {
		background: rgba(255, 255, 255, 1);
		@include border-radius(3px);
		color: $homePurple;
		display: block;
		float: left;
		margin-left: 2%;
		padding: 35px 20px 23px;
		pointer-events: all;
		text-align: center;
		text-decoration: none;
		@include transition(all 0.3s ease-in-out);
		width: 32%;

		@include min-screen($tabletPortrait) {
			@include clear;
		}

		@include max-screen($tabletPortrait) {
			float: none;
			margin-left: 0;
			margin-top: 10px;
			padding: 23px 20px 23px;
			position: relative;
			width: 100%;
		}

		@include clear;

		>svg {
			display: inline-block;
			vertical-align: top;
		}

		>span.text {
			display: block;
			line-height: 21px;
			padding: 20px 0 0;
			position: relative;

			@include max-screen($tabletPortrait) {
				display: inline-block;
				padding: 10px 0 0 20px;
				text-align: left;
			}

			>strong {
				font-family: $fontLatoBold;
				font-weight: normal;
			}
		}

		>span.subText {
			bottom: -58px;
			color: $mainMenuPurple;
			display: block;
			font-family: $fontLatoBold;
			left: 0;
			@include opacity(0);
			padding: 30px 20px 0;
			pointer-events: none;
			position: absolute;
			@include transition(all 0.3s ease-in-out);
			width: 100%;

			@include max-screen($tabletPortrait) {
				display: none;
			}
		}

		&:hover {
			background: rgba(255, 255, 255, 1);
			color: $mainMenuPurple;

			>span.text {
				&:after {
					bottom: -70px;
					@include opacity(1);
				}
			}

			>span.subText {
				bottom: -62px;
				@include opacity(1);
			}
		}
	}

	a:nth-child(2) {
		margin-left: 0;

		@include max-screen($tabletPortrait) {
			margin-top: 0;
		}
	}

	&.tasks2 {
		a {
			margin-left: 0;
			margin-right: 2.75%;
			width: 48.625%;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.tasks1 {
		text-align: center;

		a {
			display: inline-block;
			float: none;
			margin-left: 0;
			margin-right: 0;
			width: 48.625%;

			@include max-screen($tabletPortrait) {
				width: 100%;
			}

			span.text {
				&:after {
					left: -2px;
					right: auto;
				}
			}
		}
	}
}

.serviceBar div.topTasks {
	margin-top: -10px;
	margin-left: -20px;
	margin-right: -20px;
	margin-bottom: 40px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	>span.title {
		display: none;
	}

	a {
		width: 100%;
		margin: 10px 0;

		@include clear;
		float: none;
		margin: 0;
		margin-top: 20px;
		padding: 10px 18px;
		position: relative;
		width: 100%;

		@include flex;
		justify-content: space-between;
		align-items: center;

		>svg {
			@include transform(scale(.9));
		}


		>span.text {
			display: inline-block;
			padding: 0 0 0 10px;
			text-align: left;

			&:after {
				display: none;
			}
		}

		>span.subText {
			display: none;
		}
	}
}

div.themes {
	margin: 93px 0 75px;

	@include max-screen($tabletPortrait) {
		margin: 23px 0 0;
	}

	>span.title {
		color: $metaMenuActiveGrey;
		display: block;
		font-family: $fontLatoBold;
		font-size: 21px;
		line-height: 34px;
		margin-bottom: 2px;
	}

	>p {
		line-height: 25px;
		margin-bottom: 15px;

		@include clear;
	}

	div.themesHolder {
		margin-top: 20px;

		@include clear;

		>a {
			border: 2px solid transparent;
			@include border-radius(3px);
			float: left;
			font-family: $fontLatoBold;
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 20px;
			margin-left: 3.5%;
			min-height: 65px;
			padding: 5px 10px;
			position: relative;
			text-align: center;
			text-decoration: none;
			@include transition(all 0.2s ease-in-out);
			width: 31%;

			&:nth-child(3n-2) {
				margin-left: 0;
			}

			@include max-screen($tabletPortrait) {
				margin-left: 6%;
				width: 47%;

				&:nth-child(3n-2) {
					margin-left: 6%;
				}

				&:nth-child(odd) {
					margin-left: 0;
				}
			}

			@include max-screen($mobile) {
				width: 100%;
				margin-left: 0;

				&:nth-child(3n-2) {
					margin-left: 0;
				}
			}

			>span {
				display: inline-block;
				left: 0;
				padding: 0 10px;
				position: absolute;
				top: 50%;
				@include translateY(-50%);
				width: 100%;
			}

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				border-color: transparent;
				color: #ffffff;
			}

			&.blue {
				border-color: $homeBlue35;
				color: $homeBlue;

				&:hover {
					background: $homeBlue;
					border-color: transparent;
					color: #ffffff;
				}
			}

			&.orange {
				border-color: $homeOrange35;
				color: $homeOrange;

				&:hover {
					background: $homeOrange;
					border-color: transparent;
					color: #ffffff;
				}
			}

			&.purple {
				border-color: $homePurple35;
				color: $mainMenuPurple;

				&:hover {
					background: $mainMenuPurple;
					border-color: transparent;
					color: #ffffff;
				}
			}

			&.red {
				border-color: $homeRed35;
				color: $homeRed;

				&:hover {
					background: $homeRed;
					border-color: transparent;
					color: #ffffff;
				}
			}

			&.rose {
				border-color: $homeRose35;
				color: $homePurple;

				&:hover {
					background: $homePurple;
					border-color: transparent;
					color: #ffffff;
				}
			}

			&.green {
				border-color: $homeGreen35;
				color: $homeGreen;

				&:hover {
					background: $homeGreen;
					border-color: transparent;
					color: #ffffff;
				}
			}
		}

		&.all {
			margin-top: 0;

			>a {
				margin-top: 20px;
				width: 31.65%;

				&:nth-child(odd) {
					@include max-screen($tabletPortrait) {
						margin-left: 0;
					}
				}

				@include max-screen($tabletPortrait) {
					margin-bottom: 20px;
					margin-left: 6%;
					width: 47%;
				}
			}

			>a:nth-child(3n + 4) {
				@include min-screen($tabletPortrait) {
					margin-left: 0;
				}
			}
		}
	}
}

.serviceBar {
	border: 1px solid $mainMenuBackground;
	@include border-radius(3px);
	float: right;
	margin: 6px 0 0;
	padding: 10px 19px 29px;

	>span.title {
		color: $metaMenuGrey;
		display: block;
		font-family: $fontLatoBold;
		font-size: 13px;
		margin: 45px 0 0;
		text-transform: uppercase;

		&:first-child {
			margin-top: 0;
		}
	}

	&.moBet {
		position: absolute;
		right: 20px;
		top: 0;

		@include max-screen($menuBreakpointRes) {
			float: none;
			position: relative;
			right: auto;
			top: auto;
			width: 100%;
		}
	}

	a {
		color: $orangeTextColor;
		display: block;
		font-size: 15px;
		line-height: 21px;
		position: relative;
		padding: 0 0 0 19px;
		text-decoration: none;

		&:before {
			color: $homeRed50;
			content: '\e802';
			font-family: $icon;
			font-size: 7px;
			left: 0;
			line-height: 21px;
			position: absolute;
			top: 0;
			@include transition(all 0.2s ease-in-out);
		}

		&:hover {
			&:before {
				left: 3px;
			}
		}

		&.location {
			margin: 2px 0 13px;
			padding: 0;

			&:last-child {
				margin-bottom: 5px;
			}

			&:before {
				display: none;
			}

			>span.title {
				font-family: $fontLatoBold;
				display: block;
				font-size: 18px;
				font-weight: normal;
				margin: 0 0 7px;

				>span {
					display: block;
				}
			}

			>span.address {
				color: $metaMenuActiveGrey;
				font-size: 15px;
			}
		}
	}

	>p {
		font-size: 15px;
		line-height: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul.links {
		line-height: 23px;
		list-style: none;
		margin: 2px 0 0;
		padding: 0;

		li {
			display: inline-block;
			font-size: 15px;

			&:after {
				color: $metaMenuActiveGrey;
				content: '/';
			}

			&:last-child {
				padding-right: 3px;

				&:after {
					content: '';
				}
			}

			a {
				display: inline;
				padding: 0;

				&:before {
					display: none;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	ul.locationLinks {
		line-height: 18px;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin-top: 10px;

			&:first-child {
				margin-top: 4px;
			}

			a {
				>span {
					display: block;
				}
			}
		}
	}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
	div.heroImage {
		max-height: 233px;
	}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
	div.heroImage {
		max-height: 155px;
	}
}

div.heroImage {
	height: 47vh;
	min-height: 460px;
	overflow: hidden;
	position: relative;

	&.detail {
		margin-bottom: 23px;
		max-height: 430px;
	}

	@include max-screen($tabletPortrait) {
		min-height: 130px;
	}


	.container {
		height: 100%;

		@include max-screen(1220px) {
			padding: 0;
		}
	}

	img {
		width: 100%;
		height: 100%;
	}

	svg {
		bottom: -1px;
		fill: #ffffff;
		left: 0;
		max-height: 110px;
		overflow: visible;
		position: absolute;
		vertical-align: bottom;
		width: 100%;
	}

	>span {
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bf000000', GradientType=0);
		bottom: 0;
		display: block;
		height: 55%;
		position: absolute;
		width: 100%;
	}

	.videoContainer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: none;

		@include min-screen(768px) {
			display: block;
		}

		#headerVideo {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

section.topTasksHero {
	margin-bottom: 100px;
	margin-top: -91px;

	@include max-screen($tabletPortrait) {
		margin-bottom: 0;
	}

	>div.container {
		max-width: 980px;
		padding: 0;

		@include max-screen($tabletPortrait) {
			padding: 0 20px;
		}
	}

	div.topTasks {
		background: $purpleTopTasks90;
		margin-top: 0;


		@include max-screen(825px) {
			padding: 0 10px 20px;
		}

		@include max-screen($tabletPortrait) {
			padding: 20px;
		}

		>a {
			float: left;
			margin: 20px 0 0 1.6%;

			@include max-screen(825px) {
				padding: 13px 2px;
			}

			@include max-screen($tabletPortrait) {
				float: none;
				margin: 20px 0 0 0;
				padding: 13px 5px;
			}

			>span.subText {
				background: #ffffff;
				font-size: 24px;
				padding: 73px 20px 116px;
				top: 121px;
				z-index: 2;
			}

			>span.text {
				&:after {
					z-index: 3;
				}
			}

			&:nth-child(1) {
				@include max-screen($tabletPortrait) {
					margin-top: 0;
				}
			}
		}

		&:hover {
			&+div.defaultQuoteHolder {
				@include opacity(0);
			}
		}
	}

	div.defaultQuoteHolder {
		@include opacity(1);
		text-align: center;
		@include transition(all 0.2s ease-in-out 0.3s);

		h1 {
			display: block;
			font-size: 24px;
			font-weight: normal;
			line-height: 31px;
			margin-bottom: 12px;
		}
	}
}

section.ambassadorHeroInfo {
	margin-bottom: 53px;
	margin-top: -150px;
	position: relative;
	z-index: 1;

	@include max-screen($menuBreakpointRes) {
		margin-bottom: 0;
	}

	@include max-screen($breadcrumBreakpoint) {
		margin: 35px 0 0;
	}

	h1.quote {
		color: #ffffff;
		font-family: $fontLatoBoldItalic;
		font-size: 40px;
		line-height: 49px;
		margin: 0;
		position: relative;
		padding: 0 0 0 12.5%;
		text-shadow: 1px 1px #999;

		@include max-screen($breadcrumBreakpoint) {
			color: $mainMenuPurple;
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 20px;
		}

		&:before {
			background: rgba(255, 255, 255, 0.5);
			content: '';
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			top: 27px;
			width: 9.8%;

			@include max-screen($breadcrumBreakpoint) {
				background: $homePurple35;
				top: 17px;
			}
		}
	}

	div.person {
		left: 20px;
		position: absolute;
		top: 49px;
		width: column(2);

		@include max-screen($menuBreakpointRes) {
			left: auto;
			position: relative;
			top: auto;
			width: 100%;
		}

		img {
			@include border-radius(4px);
			max-width: 80px;
			vertical-align: top;

			@include max-screen($breadcrumBreakpoint) {
				float: left;
				width: column(3);
			}
		}

		span.name {
			display: block;
			font-family: $fontLatoBold;
			font-size: 15px;
			margin: 37px 0 8px;

			@include max-screen($breadcrumBreakpoint) {
				float: left;
				margin-top: 0;
				margin-left: $gutterWidth;
				width: column(9);
			}
		}

		span.function {
			display: block;
			font-size: 15px;

			@include max-screen($breadcrumBreakpoint) {
				float: left;
				margin-left: $gutterWidth;
				width: column(9);
			}
		}

		a.emailAddress {
			font-size: 15px;
			color: $orangeTextColor;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&+section.content {
		div.container {
			div.col8 {
				float: none;
				margin: 0 auto;
			}
		}
	}
}

section.wizardEntree {
	margin: 120px 0 120px;

	@include max-screen($tabletPortrait) {
		margin: 27px 0 30px;
	}

	div.greyBlock {
		background: $breadcrumbGrey;
		@include border-radius(4px);

		@include clear;

		h2 {
			color: $mainMenuPurple;
			font-size: 34px;
			line-height: 41px;
			margin-bottom: 25px;

			@include max-screen($tabletPortrait) {
				font-size: 24px;
				line-height: 31px;
				margin-bottom: 15px;
			}
		}

		>.col4 {
			box-sizing: border-box;
			padding: 38px 30px 30px 46px;

			@include max-screen($tabletPortrait) {
				padding: 23px 15px 27px;
			}

			>p {
				font-size: 21px;
				line-height: 31px;
				margin-bottom: 42px;

				@include max-screen($tabletPortrait) {
					font-size: 18px;
					line-height: 27px;
					margin-bottom: 12px;
				}
			}
		}

		div.imageHolder {
			box-sizing: border-box;
			margin-bottom: -52px;
			margin-top: -52px;
			padding-right: 46px;

			@include max-screen($tabletLandscape) {
				margin: 0;
				padding: 10px;
			}

			img {
				@include border-radius(3px);
				@include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.15));
				max-width: 100%;
				vertical-align: top;
			}

			a {
				display: block;
			}
		}
	}
}

section.ambassadors {
	margin: 120px 0 103px;
	max-width: 100%;
	overflow: hidden;

	@include max-screen($tabletPortrait) {
		margin: 30px 0;
	}



	div.ambassadorsBlock {
		background: #def0fc;
		@include border-radius(4px);
		position: relative;

		div.backgroundImage {
			top: 0;
			position: absolute;

			@include max-screen(800px) {
				display: none;
			}

			img {
				@include border-radius(4px);
				vertical-align: top;
			}
		}

		a.button {
			background: $sWTitleColor;
			margin-bottom: 10px;
		}

		ul.ambassadors {
			li {
				list-style: none;
			}
		}

		.owl-carousel .owl-stage-outer {
			@include max-screen(600px) {
				overflow: visible;
			}
		}

		.owl-controls {
			.previous {}

			.next {}
		}

		.owl-dots {
			.owl-dot {
				background: transparent;
				border: 1px solid $orangeTextColor;
				margin: 0 20px 0 21px;

				&.active {
					background: $orangeTextColor;
				}
			}
		}

		div.content {
			box-sizing: border-box;
			color: $sWTitleColor;
			float: left;
			max-width: 100%;
			padding: 45px;
			position: relative;

			@include max-screen($menuBreakpointRes) {
				max-width: 100%;
				padding: 25px 25px 0;
			}

			>h2 {
				font-family: $fontLato;
				font-size: 21px;
				line-height: 25px;
				margin: 0 0 17px;
			}

			>p {
				font-family: $fontLatoLight;
				font-size: 34px;
				line-height: 43px;
				margin: 0 0 19px;

				@include max-screen($breadcrumBreakpoint) {
					font-size: 29px;
					line-height: 37px;
				}
			}

			>a.moreLink.blue {
				color: $homeBlue;
				font-family: $fontLatoBold;
			}
		}

		div.ambassadorHolder {
			float: right;
			position: relative;
			width: 100% !important;
			padding: 20px;

			>a {
				display: inline-block;
				border: none;
				@include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.40));
				/*margin: 20px;*/
				width: 100% !important;
				max-width: 100%;
				position: relative;

				@include max-screen(1175px) {
					/*margin: 25px;*/
				}

				@include max-screen($menuBreakpointRes) {
					/*margin: 30px auto 25px;*/
					/*margin: 30px;*/
					@include clear;
				}

				.imageContainer {
					position: relative;
					height: 0;
					padding-bottom: 54.16%;

					img {
						position: absolute;
						height: 100%;
						width: 100%;
						background: $mainMenuBackground;
						object-fit: cover;
					}
				}
			}

			@include max-screen($menuBreakpointRes) {
				width: 100%;
			}
		}
	}
}

section.topical {
	margin: 100px 0;

	@include max-screen($tabletPortrait) {
		margin: 20px 0;
	}

	span.title {
		color: $mainMenuPurple;
		font-size: 34px;
		line-height: 43px;
		margin-bottom: 30px;
		font-family: $fontLatoLight;
	}

	.col6 {
		padding-left: 35px;

		@include max-screen($tabletPortrait) {
			padding-left: 0;
		}

		&:first-child {
			padding-left: 0;
			padding-right: 35px;

			@include max-screen($tabletPortrait) {
				margin-bottom: 20px;
				padding-right: 0;
			}
		}
	}

	a.moreLink {
		display: inline-block;
		margin-top: 19px;
	}

	ul {
		margin-top: 20px;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			border-top: 1px solid $prefooterText;

			&:last-child {
				border-bottom: 1px solid $prefooterText;
			}

			a {
				display: inline-block;
				padding: 10px 0;
				font-size: 18px;
				line-height: 26px;
				text-decoration: none;
				color: $mainMenuPurple;
				@include transition(color 0.2s ease-in-out);

				&:active,
				&:hover {
					color: $orangeTextColor;
				}
			}
		}
	}
}

a.actualBlock {
	display: block;
	text-decoration: none;

	>span.title {
		color: $metaMenuActiveGrey;
		display: block;
		font-family: $fontLatoBold;
		font-size: 21px;
		line-height: 26px;
		margin-bottom: 13px;
		@include transition(all 0.2s ease-in-out);
	}

	>span.date {
		color: $topicalGrey;
		display: block;
		font-family: $fontLatoBold;
		font-size: 13px;
		margin-bottom: 13px;
	}

	>span.content {
		display: block;
		@include clear;

		>span.image {
			display: block;
			float: left;
			margin-right: 3.5%;
			padding-top: 5px;
			width: 51.5%;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-bottom: 15px;
				margin-right: 0;
				width: 100%;
			}

			img {
				max-width: 100%;
				vertical-align: top;
			}
		}

		>span.text {
			color: $metaMenuActiveGrey;
			display: block;
			float: left;
			font-size: 15px;
			line-height: 21px;
			width: 45%;

			@include max-screen($tabletPortrait) {
				float: none;
				width: 100%;
			}
		}
	}

	&:hover {
		>span.title {
			color: $orangeTextColor;
		}
	}
}

section.proposition {
	margin-bottom: 60px;

	p {
		font-size: 24px;
		line-height: 30px;
		text-align: center;
		color: $mainMenuPurple;
		margin-bottom: 20px;
	}

	.container>div {
		text-align: center;
		margin-left: (column(1) + $gutterWidth);

		@include max-screen($tabletPortrait) {
			margin-left: 0;
		}
	}
}

section.expertises {

	h2 {
		font-size: 34px;
		line-height: 43px;
		font-family: $fontLatoLight;
		margin-bottom: 30px;
		color: $mainMenuPurple;
	}

	ul {
		@include flex;
		flex-wrap: wrap;
		margin: -1px;

		li {
			flex: 1 1 50%;
			padding: 1px;
			list-style: none;

			@include max-screen(1080px) {
				flex-basis: 100%;
			}
		}
	}
}

a.expertiseBlock {
	display: block;
	height: 265px;
	position: relative;
	color: white;
	text-decoration: none;
	padding: 100px 50px 20px;

	@include max-screen(1080px) {
		min-height: 265px;
		height: auto;
		padding: 26px 20px;
	}

	.backgroundImage {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		z-index: 1;
		@include transition(all 0.1s ease-out);

		img {
			position: absolute;
			left: 0;
			top: 0;
			min-height: 100%;
			min-width: 100%;
			object-fit: cover;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: grey;
			z-index: 1;
			opacity: 0.8;
		}
	}

	.content {
		position: relative;
		z-index: 1;
		padding-left: 50px;

		@include max-screen(1080px) {
			padding-left: 0;
			position: absolute;
			bottom: 26px;
			width: calc(100% - 40px);
		}

		&:before {
			content: '';
			position: absolute;
			top: 18px;
			width: 30px;
			height: 2px;
			background: white;
			left: 0;

			@include max-screen(1080px) {
				display: none;
			}
		}

		h3 {
			font-size: 30px;
			line-height: 36px;
			margin-bottom: 10px;
			font-family: $fontLato;
		}

		p {
			margin: 0;
			font-size: 18px;
			line-height: 21px;
		}
	}

	&:hover,
	&:active {
		z-index: 2;

		.backgroundImage {
			@include min-screen(1081px) {
				@include transform(scale(1.1));
				@include transition(all 0.4s ease-out);
			}
		}
	}

	&.blue {
		.backgroundImage:after {
			background: $homeBlue;
		}
	}

	&.red {
		.backgroundImage:after {
			background: $homeRed;
		}
	}

	&.orange {
		.backgroundImage:after {
			background: $homeOrange;
		}
	}

	&.green {
		.backgroundImage:after {
			background: $homeGreen;
		}
	}

	&.lastActive {
		z-index: 2;
	}
}

.serviceHeader {
	position: relative;

	@include max-screen(1000px) {
		margin-left: -20px;
		margin-right: -20px;
		margin-top: -30px;
	}

	.image {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgba(83, 32, 120, .15);
			background: linear-gradient(to bottom, rgba(83, 32, 120, .15) 0%, rgba(83, 32, 120, .15) 50%, #2A103C 100%);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	h1 {
		position: absolute;
		bottom: 0;
		margin: 0;
		padding: 0 48px 40px;
		color: white;
		z-index: 2;


		@include max-screen(1000px) {
			padding: 0 40px 30px;
		}
	}
}

header.wizard {
	border-bottom: none;

	a.logo {
		margin: 0;
		position: absolute;
		top: 20px;
		width: 100px;

		@include max-screen(880px) {
			top: 10px;
		}

		@include max-screen($tabletPortrait) {
			float: left;
			width: 30%;
		}
	}

	ul.steps {
		list-style: none;
		margin: 41px auto;
		max-width: 980px;
		padding: 0;
		width: 100%;

		@include max-screen(1220px) {
			float: right;
		}

		@include max-screen(1100px) {
			margin-top: 100px;
		}

		@include max-screen(980px) {
			float: none;
		}

		@include max-screen(880px) {
			margin-bottom: 0;
			margin-top: 80px;
		}

		@include max-screen($tabletPortrait) {
			float: right;
			margin-top: 27px;
			width: 60%;
		}

		li {
			float: left;
			position: relative;
			text-align: center;
			width: 25%;

			&:after {
				background: url(/css/img/binding.svg) no-repeat scroll center center / 100% auto;
				content: "";
				display: block;
				height: 20px;
				left: calc(50% + 15px);
				position: absolute;
				top: -15px;
				width: calc(100% - 30px);

				@include max-screen($tabletPortrait) {
					display: none;
				}
			}

			&.active {
				a {
					color: #67338B;

					@include max-screen($tabletPortrait) {
						color: transparent;
					}

					&:before {
						background: $homePurple;
					}
				}
			}

			&.done {
				a {
					color: $homeGreen;

					@include max-screen($tabletPortrait) {
						color: transparent;
					}

					&:before {
						background: $homeGreen35;
					}

					&:after {
						color: #739600;
						content: '\E809';
						display: block;
						font-family: $icon;
						font-size: 15px;
						height: 15px;
						left: calc(50% - 4px);
						margin: 0 auto 12px;
						position: absolute;
						top: -5px;
						width: 15px;
					}
				}
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			a {
				color: $metaMenuGrey;
				display: block;
				font-family: $fontLatoBold;
				text-decoration: none;

				@include max-screen(880px) {
					font-size: 16px;
				}

				@include max-screen($tabletPortrait) {
					color: transparent;
					height: 15px;
				}

				&:before {
					background: $mainMenuBackground;
					@include border-radius(50%);
					content: '';
					display: block;
					height: 15px;
					margin: 0 auto 12px;
					width: 15px;
				}
			}
		}
	}
}

div.wizardHolder {
	margin-bottom: 20px;
	margin-top: 36px;

	@include max-screen($tabletPortrait) {
		margin-bottom: 80px;
		margin-top: 28px;
	}

	div.step {
		div.header {
			background: #F9F7F6;
			@include border-radius(3px 3px 0 0);
			border-left: 1px solid $mainMenuBackground;
			border-right: 1px solid $mainMenuBackground;
			border-top: 1px solid $mainMenuBackground;
			padding: 21px 15px 5px;

			@include max-screen($tabletPortrait) {
				padding-top: 10px;
			}

			h1 {
				color: $mainMenuPurple;
				font-size: 24px;
				line-height: 31px;
				text-align: center;

				@include max-screen(880px) {
					font-size: 20px;
					line-height: 27px;
				}

				@include max-screen($tabletPortrait) {
					margin-bottom: 10px;
				}
			}
		}

		div.content {
			min-height: 440px;

			@include max-screen(1220px) {
				min-height: 300px;
			}

			@include max-screen(880px) {
				min-height: 0;
			}

			@include clear;

			div.themeBlocks {
				background: $mainMenuBackground;
				width: 100%;

				@include clear;

				label {
					cursor: pointer;
					float: left;
					position: relative;
					width: (100% * 0.5);

					@include max-screen(620px) {
						width: (100% * 0.5);
					}

					>input[type=radio] {
						display: none;

						&:checked {
							&+span.image {
								&+span.title {
									&:before {
										font-size: 24px;
										margin-left: -36px;
										@include opacity(1);
									}
								}
							}
						}
					}

					span.image {
						display: block;
						height: 0;
						overflow: hidden;
						padding-bottom: 37.3%;
						width: 100%;

						img {
							image-rendering: optimizeQuality;
							@include scale(1);
							@include rotate(0);
							@include transform-origin(50% 50%);
							@include transition(transform 15s linear);
							vertical-align: top;
							width: 100%;
						}
					}

					&:hover {
						span.image {
							img {
								@include scale(2);
								@include rotate(0.1deg);
							}
						}
					}

					span.title {
						color: #ffffff;
						display: block;
						font-family: $fontLatoBold;
						font-size: 24px;
						line-height: 24px;
						position: absolute;
						text-align: center;
						top: 50%;
						@include translateY(-50%);
						width: 100%;

						@include max-screen(880px) {
							font-size: 20px;
						}

						@include max-screen(620px) {
							font-size: 18px;
						}

						&:before {
							content: '\E809';
							display: inline;
							font-family: $icon;
							font-size: 0;
							margin-left: 0;
							@include opacity(0);
							position: absolute;
							@include transition(all 0.2s ease-in-out 0.3s);
						}
					}
				}
			}

			&.disabilities {
				border: 1px solid $mainMenuBackground;
				padding: 37px 67px 40px;

				@include max-screen(880px) {
					padding: 10px 15px 30px;
				}

				ul.disabiltyList {
					float: left;
					list-style: none;
					padding: 0 20px 0 0;
					width: 33%;

					@include max-screen(880px) {
						float: none;
						margin-bottom: -10px;
						padding: 0;
						width: 100%;
					}

					@include clear;

					li {
						display: block;
						margin: 10px 0;
						width: 100%;

						&.letter {
							display: block;
							position: relative;
							width: 100%;

							span {
								color: rgba(1, 62, 102, 0.5);
								font-family: $fontLatoBold;
								font-size: 18px;
								left: 0;
								line-height: 28px;
								position: absolute;
								top: 4px;

								@include max-screen($tabletPortrait) {
									display: block;
									padding: 5px 12px 0;
									position: relative;
									top: 0;
								}
							}

							&:first-child {
								&+li {
									a {
										&:before {
											display: none;

											@include max-screen($tabletPortrait) {
												display: block;
											}
										}
									}
								}
							}
						}

						label {
							cursor: pointer;

							@include clear;

							span {
								background: #D2E7F7;
								@include border-radius(3px);
								color: #187CCF;
								display: inline-block;
								font-family: $fontLatoBold;
								font-size: 18px;
								line-height: 28px;
								margin: 0 0 0 38px;
								padding: 4px 12px 7px;
								position: relative;
								text-decoration: none;
								@include transition(all 0.2s ease-in-out);

								@include max-screen($tabletPortrait) {
									margin: 0;
								}
							}

							>input[type=radio] {
								display: none;

								&:checked {
									&+span {
										background: $homeBlue;
										color: #ffffff;
									}
								}
							}

							&:before {
								content: '\E809';
								display: inline;
								font-family: $icon;
								font-size: 0;
								margin-left: 0;
								@include opacity(0);
								position: absolute;
								@include transition(all 0.2s ease-in-out 0.3s);
							}

							&:hover {
								span {
									background: $homeBlue;
									color: #ffffff;
								}
							}
						}
					}
				}
			}

			&.ages {
				border: 1px solid $mainMenuBackground;
				text-align: center;

				@include max-screen($tabletPortrait) {
					padding: 16px 0 10px;
				}

				>label {
					cursor: pointer;
					display: inline-block;
					margin: 119px 8px 0;
					max-width: 200px;
					width: 20%;

					@include max-screen(1220px) {
						margin: 49px 8px 0;
					}

					@include max-screen(880px) {
						margin: 29px 8px;
					}

					@include max-screen($tabletPortrait) {
						margin: 8px;
						width: 40%;
					}

					>input[type=radio] {
						display: none;

						&:checked {
							&+span {
								background: #187CCF;
								color: #ffffff;
							}
						}
					}

					>span {
						@include border-radius(3px);
						background: #D2E7F7;
						color: $homeBlue;
						display: block;
						font-family: $fontLatoBold;
						height: 0;
						max-height: 200px;
						padding-bottom: 100%;
						position: relative;
						@include transition(all 0.2s ease-in-out);

						>span.text {
							display: block;
							position: absolute;
							top: 50%;
							@include translateY(-50%);
							width: 100%;

							span.num {
								display: block;
								font-size: 31px;
								line-height: 38px;
								margin-bottom: 12px;
							}

							>span.year {
								@include opacity(0.75);
								font-size: 18px;
							}
						}
					}

					&:hover {
						>span {
							background: #187CCF;
							color: #ffffff;
						}
					}
				}
			}

			&.result {
				border: 1px solid $mainMenuBackground;

				div.serviceWidget {
					margin: 43px auto 44px;
					max-width: 780px;

					@include max-screen(880px) {
						margin: 20px 15px;
					}

					div.resultsHolder {
						@include border-radius(3px);

						ul.resultList {
							margin-bottom: 23px;
							margin-top: 18px;
						}
					}
				}
			}
		}

		div.footer {
			background: #F9F7F6;
			@include border-radius(0 0 3px 3px);
			border-left: 1px solid $mainMenuBackground;
			border-right: 1px solid $mainMenuBackground;
			border-bottom: 1px solid $mainMenuBackground;
			padding: 30px 27px 23px;
			position: relative;

			@include max-screen($tabletPortrait) {
				padding: 15px 13px 13px;
			}

			@include clear;

			>button {
				background: transparent;
				border: none;
				color: $orangeTextColor;
				cursor: pointer;
				float: right;
				font-size: 16px;
				margin-left: 40px;

				&:after {
					color: $moreLinkIconColor;
					content: '\e802';
					display: inline;
					font-family: $icon;
					font-size: 6px;
					line-height: 25px;
					margin: 1px 0 0 11px;
					@include transition(all 0.2s ease-in-out);
					vertical-align: top;
				}
			}

			>button:hover {
				&:after {
					margin: 1px 5px 0 6px;
				}
			}

			>p.subResult {
				color: #734A95;
				display: block;
				float: right;
				font-family: $fontLatoBold;

				@include max-screen($tabletPortrait) {
					font-size: 16px;
				}
			}

			a.backLink {
				@include max-screen($tabletPortrait) {
					bottom: -40px;
					position: absolute;
				}
			}
		}
	}
}

div.line {
	background: $mainMenuBackground;
	float: left;
	height: 1px;
	margin: 33px 0;
	width: 100%;

	@include clear;
}

section.contributors {
	padding-top: 25px;

	span.title {
		color: $metaMenuActiveGrey;
		font-family: $fontLatoBold;
		font-size: 24px;

		>span {
			font-family: $fontLato;
			font-size: 21px;
			margin-left: 25px;

			@include max-screen($menuBreakpointRes) {
				display: block;
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}

	ul.people {
		list-style: none;
		margin: 0 0 26px;
		padding: 0;

		@include clear;

		li {
			@include flex;
			float: left;
			margin-bottom: 4px;
			margin-right: $gutterWidth;
			margin-top: 39px;
			max-height: 400px;
			overflow: hidden;
			width: column(3);
			@include transition(all 0.2s ease-in-out);

			@include max-screen($menuBreakpointRes) {
				width: column(6);
			}

			@include max-screen($breadcrumBreakpoint) {
				display: block;
				margin-top: 19px;
			}

			&:nth-child(4n+4) {
				@include min-screen($menuBreakpointRes) {
					margin-right: 0;
				}
			}

			&:nth-child(4n+5) {
				@include min-screen($menuBreakpointRes) {
					clear: left;
				}
			}

			&:nth-child(2n+2) {
				@include max-screen($menuBreakpointRes) {
					margin-right: 0;
				}
			}

			&:nth-child(2n+3) {
				@include max-screen($menuBreakpointRes) {
					clear: left;
				}
			}

			&.hidden {
				margin-top: 0;
				max-height: 0;
			}

			>div.image {
				float: left;
				margin-right: 6%;
				max-width: 100px;
				width: 36%;

				@include max-screen($breadcrumBreakpoint) {
					float: none;
					width: 100%;
				}

				img {
					@include border-radius(3px);
					margin: 6px 0;
					max-width: 100%;
					vertical-align: top;
				}
			}

			>div.content {
				float: left;
				font-size: 16px;
				line-height: 23px;
				position: relative;
				width: 58%;

				@include max-screen($breadcrumBreakpoint) {
					float: none;
					padding-bottom: 37px;
					width: 100%;
				}

				>div {
					margin-bottom: 37px;

					@include max-screen($breadcrumBreakpoint) {
						margin-bottom: 0;
					}

					>span.name {
						display: block;
						font-family: $fontLatoBold;
					}
				}

				>a.moreLink {
					bottom: 0;
					left: 0;
					position: absolute;
				}
			}
		}
	}

	.showMore {
		color: $orangeTextColor;
		cursor: pointer;
		float: left;
		font-size: 16px;
		padding-left: 20px;
		position: relative;

		@include max-screen($breadcrumBreakpoint) {
			float: none;
			margin-top: 25px;
			width: 100%;
		}

		&:before {
			color: $moreLinkIconColor;
			content: '\e807';
			display: inline-block;
			font-family: $icon;
			font-size: 7px;
			left: 0;
			margin: 2px 15px 0 0;
			position: absolute;
			@include transition(all 0.2s ease-in-out);
			vertical-align: top;
		}

		&:hover {
			&:before {
				margin: 4px 15px 2px 0;

				@include max-screen($breadcrumBreakpoint) {
					margin: 6px 15px 4px 0;
				}
			}
		}

		.more {
			display: inline;
		}

		.less {
			display: none;
		}
	}

	.showMore.showLess {
		&:before {
			content: '\e803';
		}

		&:hover {
			&:before {
				margin: -1px 15px 3px 0;

				@include max-screen($breadcrumBreakpoint) {
					margin: -2px 15px 4px 0;
				}
			}
		}

		.less {
			display: inline;
		}

		.more {
			display: none;
		}
	}
}

section.gallery {
	clear: left;
	margin: 100px 0 90px;
	position: relative;

	@include clear;

	@include max-screen($breadcrumBreakpoint) {
		margin-top: 30px;
	}

	img {
		vertical-align: top;
	}
}

section.locationContact {
	margin-top: 160px;

	@include max-screen($breadcrumBreakpoint) {
		margin-top: 80px;
	}

	div.blockHolder {
		background-image: url(/tempimg/maps.jpg);
		background-position: center center;
		background-size: cover;
		@include border-radius(3px);
		padding: 81px 0 75px;
		text-align: center;

		@include max-screen($breadcrumBreakpoint) {
			padding: 10px 20px;
		}

		>div {
			background: #ffffff;
			@include border-radius(3px);
			@include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.20));
			box-sizing: border-box;
			display: inline-block;
			margin: 15px 28px;
			max-width: 360px;
			min-height: 315px;
			text-align: left;
			vertical-align: top;
			width: 100%;

			@include max-screen($breadcrumBreakpoint) {
				margin: 10px 0;
				max-width: 100%;
				min-height: 0;
			}

			>span.title {
				border-bottom: 1px solid $mainMenuBackground;
				color: $topicalGrey;
				display: block;
				font-family: $fontLatoBold;
				font-size: 13px;
				line-height: 24px;
				padding: 20px 25px 17px;
				text-transform: uppercase;
			}

			&.openingTimes {
				>span.title {
					padding-bottom: 16px;

					&:before {
						color: $metaMenuGrey;
						content: '\E80B';
						display: inline-block;
						font-family: $icon;
						font-size: 15px;
						margin-right: 12px;
					}
				}
			}

			>div.content {
				padding: 11px 25px;

				>span.name {
					color: $metaMenuActiveGrey;
					display: block;
					font-family: $fontLatoBold;
					line-height: 23px;
					margin: 4px 0 6px;
					padding-left: 28px;
					position: relative;

					&:before {
						color: $metaMenuGrey;
						content: '\E80A';
						display: inline-block;
						font-family: $icon;
						font-size: 12px;
						position: absolute;
						left: 0;
					}
				}

				>p {
					font-size: 15px;
					line-height: 21px;
					padding: 0 0 15px 28px;
				}

				>a,
				.gmapsLink {
					color: $orangeTextColor;
					display: block;
					font-size: 15px;
					line-height: 21px;
					margin: 0;
					padding-left: 28px;
					position: relative;
					text-decoration: none;

					&:before {
						color: $metaMenuGrey;
						display: inline-block;
						font-size: 15px;
						position: absolute;
						left: 0;
					}

					&:hover {
						text-decoration: underline;
					}
				}

				a.gmapsLink {
					padding-left: 0;
				}

				a.tel {
					&:before {
						content: 'T';
					}
				}

				a.email {
					&:before {
						content: 'E';
					}
				}

				>table {
					font-size: 15px;
					line-height: 31px;
					width: 100%;
				}

				div.socials {
					margin: 30px 0 12px;
					text-align: left;
					padding-left: 28px;

					>a {
						display: inline-block;
						margin-right: 5px;
						@include transition(all 0.2s ease-out);

						&:hover {
							@include opacity(0.6);
						}
					}
				}
			}
		}
	}
}

section.timeline {
	background: $timeLinePurple;
	margin: 38px 0 0;
	overflow: hidden;
	padding: 51px 0 109px;
	position: relative;

	h2 {
		color: #ffffff;
		line-height: 35px;
		font-size: 22px;
		margin-bottom: 67px;
		position: relative;
		text-align: center;

		&:after {
			background: $timeLine;
			content: '';
			display: block;
			height: 3px;
			position: absolute;
			top: 169px;
			width: 100%;
		}
	}

	.container {
		@include max-screen(930px) {
			padding: 0 35px;
		}
	}

	.socialLine {
		.owl-stage-outer {
			overflow: visible;
		}

		.owl-controls .owl-nav div {
			background: $timeLineRose;
			@include box-shadow-double(0px 0px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 5px $timeLinePurple);
			@include opacity(1);
			@include transition(all 0.2s ease-out);
			top: 68px;

			&.owl-next {
				right: 0;

				&.disabled {
					right: -10px;
				}
			}

			&.owl-prev {
				left: 0;

				&.disabled {
					left: -10px;
				}
			}

			&.disabled {
				display: block !important;
				pointer-events: none;
				@include opacity(0);
			}
		}

		.owl-controls .owl-nav div:hover {
			background: $timeLineRosehover;
		}

		.owl-dots {
			display: none !important;
		}

		a {
			position: relative;
			text-decoration: none;

			>span.date {
				color: rgba(243, 240, 246, 0.5);
				display: block;
				font-size: 16px;
				margin-bottom: 25px;
				text-align: center;

				&:after {
					content: '';
					display: block;
					height: 50px;
					margin: 26px auto 0;
					width: 50px;
				}
			}

			>span.content {
				background: #ffffff;
				@include box-shadow(0px 0px 20px 0px rgba(0, 0, 0, 0.4));
				display: block;
				@include border-radius(4px);
				color: $metaMenuActiveGrey;
				position: relative;

				&:before {
					background: $timeLine;
					content: '';
					display: block;
					height: 25px;
					left: 50%;
					margin-left: -1px;
					position: absolute;
					top: -25px;
					width: 2px;
				}

				img {
					@include border-radius(4px 4px 0 0);

					&+p {
						font-size: 15px;
						line-height: 24px;
						padding: 16px 23px 22px;
					}
				}

				p {
					font-size: 21px;
					line-height: 31px;
					padding: 31px 31px 33px;
				}

				>.overlay {
					@include border-radius(4px);
					height: 100%;
					@include opacity(0);
					position: absolute;
					top: 0;
					@include transition(all 0.2s ease-out);
					width: 100%;

					>span {
						color: #ffffff;
						display: block;
						font-family: $fontLatoBold;
						font-size: 18px;
						position: relative;
						top: 50%;
						text-align: center;
						@include translateY(-50%);

						>span {
							display: block;
							height: 40px;
							margin-bottom: 14px;
							width: 100%;
						}
					}
				}

				&:hover {
					>.overlay {
						@include opacity(1);
					}
				}
			}

			&.twitter {
				>span.date {
					&:after {
						background: url(/css/img/icn_twitter.svg) no-repeat center center;
					}
				}

				>span.content {
					>.overlay {
						background: rgba(46, 160, 246, 0.95);

						>span>span {
							background: url(/css/img/icn_tw_wh.svg) no-repeat center center;
						}
					}
				}
			}

			&.facebook {
				>span.date {
					&:after {
						background: url(/css/img/icn_facebook.svg) no-repeat center center;
					}
				}

				>span.content {
					>.overlay {
						background: rgba(94, 129, 213, 0.95);

						>span>span {
							background: url(/css/img/icn_fb_wh.svg) no-repeat center center;
						}
					}
				}
			}

			&.instagram {
				>span.date {
					&:after {
						background: url(/css/img/icn_instagram.svg) no-repeat center center;
					}
				}

				>span.content {
					>.overlay {
						background: rgba(217, 201, 185, 0.95);

						>span>span {
							background: url(/css/img/icn_in_wh.svg) no-repeat center center;
						}
					}
				}
			}
		}
	}
}

section.overview {
	padding: 0 0 56px;
}

.overview {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;

		@include clear;

		@include max-screen($menuBreakpointRes) {
			text-align: center;
		}

		li {
			display: inline-block;
			vertical-align: top;
			margin: 0 -4px 20px $gutterWidth;

			@include min-screen(930px) {
				width: column(3);
			}

			@include max-screen($breadcrumBreakpoint) {
				display: inline-block;
				float: none;
			}


			&:first-child,
			&:nth-child(4n + 1) {
				@include min-screen(930px) {
					margin-left: 0;
				}
			}
		}
	}

	a {
		background: #ffffff;
		border: 1px solid $mainMenuBackground;
		@include border-radius(3px);
		box-sizing: border-box;
		display: block;
		height: 100%;
		max-width: 280px;
		text-align: left;
		text-decoration: none;
		@include transition(all 0.2s ease-in-out);
		width: 100%;

		@include max-screen(360px) {
			max-width: 250px;
		}

		>img {
			@include border-radius(3px 3px 0 0);
			vertical-align: top;
		}

		span.content {
			display: block;
			min-height: 216px;
			max-height: 340px;
		}

		span.quote {
			color: $ambassadorHomepagePurple;
			display: block;
			font-family: $fontLatoBoldItalic;
			font-size: 21px;
			line-height: 26px;
			margin: 24px 20px 18px 30px;
			position: relative;
			padding: 0 0 0 7.9%;
			height: 140px;

			&:before {
				background: $ambassadorHomepagePurple50;
				content: '';
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 13px;
				width: 6.8%;
			}
		}

		span.person {
			display: block;
			margin: 0 30px;
			min-height: 280px;

			>span.name {
				color: $metaMenuActiveGrey;
				display: block;
				font-family: $fontLatoBlack;
				font-size: 16px;
				line-height: 22px;
				margin-top: 21px;
				margin-bottom: 5px;
			}

			>span.function {
				color: $metaMenuActiveGrey;
				display: block;
				font-size: 15px;
				line-height: 21px;
			}
		}

		span.moreLink {
			color: $orangeTextColor;
			display: inline-block;
			font-family: $fontLatoBold;
			font-size: 15px;
			margin: 0 0 26px 30px;

			&:after {
				color: $moreLinkIconColor;
				content: '\e802';
				display: inline;
				font-family: $icon;
				font-size: 6px;
				line-height: 19px;
				margin: 1px 0 0 11px;
				@include transition(all 0.2s ease-in-out);
				vertical-align: top;
			}
		}

		&:hover {
			@include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.1));

			>span.moreLink {
				&:after {
					margin: 1px 5px 0 6px;
				}
			}
		}
	}

	&.locations {
		padding: 0 0 69px;

		a {
			>span.contentHolder {
				color: $metaMenuActiveGrey;
				font-size: 15px;
				line-height: 21px;
				display: block;
				margin: 21px 30px 10px;
				min-height: 253px;

				>span.name {
					display: block;
					font-family: $fontLatoBold;
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 6px;
					position: relative;

					&:before {
						color: $metaMenuGrey;
						content: '\E80A';
						display: inline-block;
						font-family: $icon;
						font-size: 13px;
						left: -17px;
						position: absolute;
						top: 1px;
					}

					>span {
						display: block;
					}
				}

				>span.telNum {
					display: block;
					font-size: 15px;
					line-height: 21px;
					margin: 12px 0 0;
					padding-left: 18px;
					position: relative;
					text-decoration: none;

					&:before {
						color: $metaMenuGrey;
						content: 'T';
						display: inline-block;
						font-size: 15px;
						position: absolute;
						left: 0;
					}
				}

				>span.areas {
					display: block;
					font-size: 13px;
					line-height: 20px;
					margin: 33px 0 54px;

					>strong {
						display: block;
					}
				}
			}

			.imageContainer {
				position: relative;
				width: 100%;
				height: 0;
				padding-bottom: 55.66%;

				img {
					background: $mainMenuBackground;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	&.actual {
		li {
			@include min-screen(930px) {
				width: column(6);
			}

			@include max-screen($breadcrumBreakpoint) {
				display: inline-block;
				float: none;
			}


			&:first-child,
			&:nth-child(2n + 1) {
				@include min-screen(930px) {
					clear: left;
					margin-left: 0;
				}
			}

			a.actualBlock {
				display: flex;
				flex-direction: column;
				max-width: 100%;
				padding: 27px 28px 0;

				>span.content {
					flex: 1;
					min-height: 0;

					>span.image {
						width: 53.8%;

						@include max-screen($tabletPortrait) {
							width: 100%;
						}
					}

					>span.text {
						width: 42.7%;

						@include max-screen($tabletPortrait) {
							width: 100%;
						}
					}
				}

				>span.moreLink {
					margin: 13px 0 24px;
				}
			}
		}
	}

	div.pagerHolder {
		>ul {
			>li {
				width: auto;
			}
		}
	}
}

div.pagerHolder {
	padding: 42px 0 21px;
	width: 100%;

	@include clear;

	>ul {
		text-align: center;
		width: 100%;

		>li {
			display: inline-block;
			float: none;
			font-family: $fontLatoBold;
			font-size: 16px;
			width: auto;

			.pageItem {
				border: none;
				@include border-radius(3px);
				display: inline-block;
				min-width: 30px;
				padding: 6px 5px;
				text-align: center;
			}

			a {
				@extend .pageItem;
				color: $orangeTextColor;
				text-decoration: none;
				@include transition(all 0.2s ease-in-out);

				&:hover {
					@include box-shadow(none);
					color: $mainMenuPurple;
				}

				.next,
				.prev {
					color: $orangeTextColor;

					&:hover {
						&:after {
							margin: 1px 5px 0 6px;
						}

						&:before {
							margin: 1px 6px 0 5px;
						}
					}

					&:before,
					&:after {
						color: $moreLinkIconColor;
					}
				}
			}

			span {
				@extend .pageItem;
				color: $topicalGrey;
				@include transition(all 0.2s ease-in-out);

				&.active {
					background: $prefooterText;
					color: $mainMenuPurple;
				}

				.next,
				.prev {
					color: $topicalGrey;

					&:before,
					&:after {
						color: $topicalGrey;
					}
				}
			}

			.next {
				text-decoration: none;

				&:hover {
					&:after {
						margin: 1px 5px 0 6px;
					}
				}

				&:after {
					content: '\e802';
					display: inline;
					font-family: $icon;
					font-size: 9px;
					line-height: 21px;
					margin: 1px 0 0 11px;
					@include transition(all 0.2s ease-in-out);
					vertical-align: top;
				}
			}

			.prev {
				text-decoration: none;

				&:hover {
					&:before {
						margin: 1px 6px 0 5px;
					}
				}

				&:before {
					content: '\e806';
					display: inline;
					font-family: $icon;
					font-size: 10px;
					line-height: 22px;
					margin: 1px 11px 0 0;
					@include transition(all 0.2s ease-in-out);
					vertical-align: top;
				}
			}

			&:first-child {
				float: left;
			}

			&:last-child {
				float: right;
			}
		}
	}
}

section.searchHeader {
	background: url(/tempimg/searchHero.jpg) repeat center 23%;
	padding: 70px 0;

	div.searchBox {
		background: #F2EBE6;
		border: 1px solid $formInputBorder;
		@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
		@include border-radius(3px);
		margin: 0 auto;
		max-width: 780px;
		position: relative;
		width: 100%;

		input[type=search] {
			@include appearance(none);
			background: transparent;
			border: none;
			@include border-radius(0px);
			font-family: $fontLato;
			font-size: 18px;
			line-height: 22px;
			margin: 0;
			padding: 13px 48px 13px 14px;
			width: 100%;

			&:focus {
				outline: none;
				outline-color: transparent;
				outline-style: auto;
				outline-width: 0;
			}
		}

		button {
			background: transparent;
			border: none;
			cursor: pointer;
			height: 48px;
			position: absolute;
			right: 0;
			top: 0;
			width: 48px;

			&:after {
				color: $mainMenuSearchGrey;
				content: '\e804';
				cursor: pointer;
				font-family: $icon;
				font-size: 20px;
			}

			&:after {
				&:before {
					color: $mainMenuPurple;
				}
			}
		}
	}
}

html.no-js {
	section.searchResults {
		.col3.right {
			div.searchFilters {
				>.filterTitle {
					@include max-screen($menuBreakpointRes) {
						display: block;
					}
				}

				form {
					button {
						display: inline-block;
						margin: 0 16px 14px;
					}
				}
			}
		}
	}
}

section.searchResults {
	padding: 45px 0 66px;

	.col3.right {
		margin-left: $gutterWidth;
		float: right;

		@include max-screen($menuBreakpointRes) {
			margin-left: 0;
			float: left;
			width: 100%;
		}

		div.searchFilters {
			background: #ffffff;
			border: 1px solid $mainMenuBackground;
			@include border-radius(3px);
			line-height: 24px;
			margin: 0 0 40px;
			padding: 29px 30px 17px;
			width: 100%;

			@include max-screen($menuBreakpointRes) {
				padding: 0;
			}

			>.filterTitle {
				color: $submenuTitle;
				display: block;
				font-family: $fontLatoBold;
				margin: 0 0 17px;

				@include max-screen($menuBreakpointRes) {
					display: none;
				}
			}

			>span.openSub {
				display: none;

				@include max-screen($menuBreakpointRes) {
					color: $orangeTextColor;
					cursor: pointer;
					display: block;
					font-family: $fontLato;
					font-size: 16px;
					margin: 0;
					padding: 12px 23px 12px 15px;
				}

				&:after {
					@include max-screen($menuBreakpointRes) {
						color: $moreLinkIconColor;
						content: '\e807';
						display: inline-block;
						float: right;
						font-family: $icon;
						font-size: 8px;
						margin: 2px 0 0;
						@include transition(all 0.2s ease-in-out);
					}
				}
			}

			&.showSub {
				>span.openSub {
					&:after {
						@include rotate(180);
					}
				}

				ul {
					@include max-screen($menuBreakpointRes) {
						max-height: 600px;
						@include opacity(1);
					}
				}
			}

			button {
				display: none;
			}

			form {
				@include max-screen($menuBreakpointRes) {}
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				@include max-screen($menuBreakpointRes) {
					margin: 0 16px;
					max-height: 0;
					@include opacity(0);
					overflow: hidden;
					@include transition(all 0.2s ease-in-out);
					font-size: 15px;
				}

				li {
					padding-bottom: 14px;
				}
			}

			[type="checkbox"]:not(:checked),
			[type="checkbox"]:checked {
				position: absolute;
				left: -99999px;
			}

			[type="checkbox"]:not(:checked)+label,
			[type="checkbox"]:checked+label {
				display: inline-block;
				cursor: pointer;
				height: 24px;
				padding: 0 0 0 31px;
				position: relative;

				@include max-screen($tabletPortrait) {
					float: none;
				}
			}

			[type="checkbox"]:not(:checked)+label:before,
			[type="checkbox"]:checked+label:before {
				background: rgba(242, 235, 230, 0.50);
				border: 1px solid $formInputBorder;
				@include border-radius(3px);
				content: '';
				position: absolute;
				left: 0;
				top: 3px;
				height: 20px;
				@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
				width: 20px;
			}

			/* checked mark aspect */
			[type="checkbox"]:not(:checked)+label:after,
			[type="checkbox"]:checked+label:after {
				color: $metaMenuActiveGrey;
				content: '\E809';
				position: absolute;
				top: 6px;
				left: 5px;
				font-family: $icon;
				font-size: 10px;
				line-height: 16px;
				@include transition(all 0.2s ease-in-out);
			}

			/* checked mark aspect changes */
			[type="checkbox"]:not(:checked)+label:after {
				@include opacity(0);
				@include scale(0);
			}

			[type="checkbox"]:checked+label:after {
				@include opacity(1);
				@include scale(1);
			}

			/* disabled checkbox */
			[type="checkbox"]:disabled:not(:checked)+label:before,
			[type="checkbox"]:disabled:checked+label:before {}

			[type="checkbox"]:disabled:checked+label:after {}

			[type="checkbox"]:disabled+label {
				@include opacity(0.45);
			}

			/* accessibility */
			[type="checkbox"]:checked:focus+label:before,
			[type="checkbox"]:not(:checked):focus+label:before {}
		}
	}

	.col9 {
		margin-left: 0;

		>ul {
			list-style: none;
			margin: 49px 0 0;
			padding: 0;

			li {
				a {
					background: #ffffff;
					border: 1px solid $mainMenuBackground;
					border-left: 6px solid $resultBlockLeftBorder;
					@include border-radius(3px);
					display: block;
					margin-top: 28px;
					padding: 16px 39px 24px 25px;
					position: relative;
					text-decoration: none;
					@include transition(all 0.2s ease-in-out);

					&:hover {
						background: rgba(242, 239, 236, 0.5);
					}

					span.title {
						color: $metaMenuActiveGrey;
						display: block;
						font-family: $fontLatoBold;
						font-size: 21px;
						line-height: 31px;
					}

					span.url {
						color: $metaMenuGrey;
						display: block;
						font-size: 15px;
						line-height: 24px;
					}

					span.summary {
						color: $metaMenuActiveGrey;
						display: block;
						font-size: 18px;
						line-height: 30px;
						margin: 15px 0 6px;
					}
				}
			}
		}
	}
}

div.wizardBanner {
	background: #E8F3FB;
	@include border-radius(3px);
	color: $sWTitleColor;
	margin: 28px 0 15px;
	padding: 21px 17px 17px;

	span {
		display: inline-block;
		margin-right: 11px;

		@include max-screen($tabletPortrait) {
			margin-bottom: 5px;
			display: block;
		}
	}
}

div.moreInfoCol {
	@include max-screen($menuBreakpointRes) {
		width: 100%;
	}
}

div.formHolder,
.col-md-12>div>div.contour {
	background: $formHolderBackground;
	@include border-radius(3px);
	// margin: 6px 0 0 0;
	position: relative;
	@include clear;
	border: 1px solid $formBorderTop;
	border-left: 6px solid $formPurple;

	.formHolder{
		border: none !important;
	}

	&.normal {
		// background: $formHolderBackground; 
		div.title {
			>h1 {
				color: $sWTitleColor;
			}
		} 

		div.form {
			input[type="submit"] {
				background: $homeBlue;
				border: none;
				@include border-radius(3px);
				cursor: pointer;
				font-family: $fontLatoBold;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 29px;
				margin: 33px 30px 31px 72px;
				padding: 9px 19px 11px 17px;
				@include transition(all 0.2s ease-in-out);

				&:hover {
					background: $homeBlue35;
				}
			}
		}
	}

	.help-block{
		font-style: italic;
		color: $metaMenuGrey;
	}

	div.title {
		border-bottom: 43px solid #ffffff;
		padding: 18px 35px;

		@include max-screen($menuBreakpoint) {
			border-bottom: 17px solid #ffffff;
			padding: 14px 25px;
		}

		>svg {
			display: inline-block;
			margin-right: 22px;
			vertical-align: top;
		}

		>h1 {
			display: inline-block;
			font-family: $fontLato;
			font-size: 24px;
			line-height: 22px;
			margin: 18px 0 22px;
			vertical-align: top;

			>strong {
				font-family: $fontLatoBlack;
			}
		}
	}

	div.requestTitle {
		background: #ffffff;
		color: $metaMenuActiveGrey;
		font-size: 18px;
		line-height: 30px;
		padding-left: 68px;
	}

	div.formIntro {
		background: #ffffff;
		color: $metaMenuGrey;
		font-size: 18px;
		padding: 5px 20px 0 35px;

		@include max-screen($menuBreakpoint) {
			padding: 5px 25px 0;
		}

		p {
			margin: 0;
			padding-bottom: 43px;
		}

		.moreLink,
		.moreLink a {
			color: $metaMenuActiveGrey;
			margin-left: 4px;

			&:after {
				color: $breadcrumbArrowPurple;
			}
		}
	}

	>div.text {
		background: #ffffff;
		padding: 0 80px 50px 72px;
	}

	div.contourPage {
		border-top: 44px solid #ffffff;
		
		@include max-screen($menuBreakpoint) {
			border-top: 18px solid #ffffff;
		}
	}

	div.form,
	div.contourPage {

		.umbraco-forms-field.date .umbraco-forms-field-wrapper{
			position: relative;

			svg.datePickerIcon{
				position: absolute;
				width: 25px;
				height: 25px;
				top: 10px;
				left: 10px;
				fill: $mainTitleColor;
			}

			input{
				padding-left: 50px;
			}
		}

		fieldset {
			background: #ffffff;
			padding: 0 80px 20px 72px;

			@include max-screen($menuBreakpoint) {
				padding: 0 40px 20px 32px;
			}

			@include max-screen($tabletPortrait) {
				padding: 0 20px 10px 17px;
			}
		}

		div.umbraco-forms-field {
			margin-bottom: 30px;

			@include max-screen($tabletPortrait) {
				margin-bottom: 20px;
			}
		}

		.form>.umbraco-forms-form{
			padding-top: 0;
		}

		label.umbraco-forms-label {
			color: $metaMenuGrey;
			font-size: 18px;
			line-height: 30px;
			float: left;
			margin-right: 3%;
			padding: 7px 0 0;
			width: 30%;
			font-weight: 400;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-right: 0;
				padding: 7px 0 6px;
				width: 100%;
			}

			&+div {
				float: left;
				position: relative;
				width: 67%;

				@include max-screen($tabletPortrait) {
					float: none;
					width: 100%;
				}
			}
		}

		input.text,
		input.datepickerfield,
		input.datepickerfieldshadow,
		input.title,
		textarea {
			background: rgba(242, 235, 230, 0.50);
			border: 1px solid $formInputBorder;
			@include border-radius(3px);
			@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
			box-sizing: border-box;
			color: $metaMenuActiveGrey;
			font-family: $fontLato;
			font-size: 18px;
			line-height: 30px;
			padding: 8px 13px 9px;
			@include transition(all 0.2s ease-in-out);
			width: 100%;

			&:hover {
				border-color: $metaMenuGrey;
			}

			&:focus {
				@include box-shadow-double(0px 0px 5px 0px $formInputBorder, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
				border-color: $metaMenuGrey;
			}

			&.input-validation-error {}
		}

		textarea {
			resize: none;
		}

		.umbraco-forms-field:before,
		.umbraco-forms-field:after {
			content: '';
			display: table;
		}

		.umbraco-forms-field:after {
			clear: both;
		}

		span.field-validation-error {
			color: #D84E1E;
			font-family: $fontLatoItalic;
			font-size: 15px;

			&:after {
				content: '';
				display: table;
				clear: left;
			}
		}

		label.singleBox {
			display: inline-block;
			margin-top: 11px;
		}

		div.checkboxlist {
			span.checkboxlist {
				display: block;
				padding: 8px 0 10px;
			}

			>div {
				label {
					display: inline;
				}
			}
		}

		div.longanswer {
			label {
				width: 100%;
			}

			>div {
				width: 100%;
			}
		}

		div.radiobuttonlist {
			label {
				width: 100%
			}

			span.radiobuttonlist {
				display: flex;
				justify-content: space-between;
				padding: 8px 0 10px;
			}

			>div {
				width: 100%;

				label {
					display: inline;
					width: auto;
				}
			}
		}

		[type="checkbox"]:not(:checked),
		[type="checkbox"]:checked {
			position: absolute;
			left: -99999px;
		}

		[type="checkbox"]:not(:checked)+label,
		[type="checkbox"]:checked+label {
			min-height: 24px;
			line-height: normal;
			position: relative;
			padding: 0 0 0 31px;
			cursor: pointer;

			@include max-screen($tabletPortrait) {
				float: none;
			}
		}

		[type="checkbox"]:not(:checked)+label:before,
		[type="checkbox"]:checked+label:before {
			background: rgba(242, 235, 230, 0.50);
			border: 1px solid $formInputBorder;
			@include border-radius(3px);
			content: '';
			position: absolute;
			left: 0;
			top: 2px;
			height: 20px;
			@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
			width: 20px;
		}

		/* checked mark aspect */
		[type="checkbox"]:not(:checked)+label:after,
		[type="checkbox"]:checked+label:after {
			color: $metaMenuActiveGrey;
			content: '\E809';
			position: absolute;
			top: 3px;
			left: 5px;
			font-family: $icon;
			font-size: 10px;
			line-height: 16px;
			@include transition(all 0.2s ease-in-out);
		}

		/* checked mark aspect changes */
		[type="checkbox"]:not(:checked)+label:after {
			@include opacity(0);
			@include scale(0);
		}

		[type="checkbox"]:checked+label:after {
			@include opacity(1);
			@include scale(1);
		}

		/* disabled checkbox */
		[type="checkbox"]:disabled:not(:checked)+label:before,
		[type="checkbox"]:disabled:checked+label:before {}

		[type="checkbox"]:disabled:checked+label:after {}

		[type="checkbox"]:disabled+label {
			@include opacity(0.45);
		}

		/* accessibility */
		[type="checkbox"]:checked:focus+label:before,
		[type="checkbox"]:not(:checked):focus+label:before {}

		[type="radio"]:not(:checked),
		[type="radio"]:checked {
			position: absolute;
			left: -99999px;
		}

		[type="radio"]:not(:checked)+label,
		[type="radio"]:checked+label {
			height: 24px;
			position: relative;
			padding: 0 0 0 31px;
			cursor: pointer;

			@include max-screen($tabletPortrait) {
				float: none;
			}
		}

		[type="radio"]:not(:checked)+label:before,
		[type="radio"]:checked+label:before {
			content: '';
			position: absolute;
			left: 0;
			top: 2px;
			height: 20px;
			background: #ffffff;
			@include border-radius(50%);
			border: 1px solid $metaMenuGrey;
			@include box-shadow-double(inset 0px -2px 2px 0px #FFFCFA, inset 0px 2px 2px 0px rgba(153, 151, 153, 0.20));
			width: 20px;
		}

		/* checked mark aspect */
		[type="radio"]:not(:checked)+label:after,
		[type="radio"]:checked+label:after {
			background: $metaMenuActiveGrey;
			@include border-radius(50%);
			content: '';
			display: block;
			height: 8px;
			left: 6px;
			position: absolute;
			top: 8px;
			@include transition(all 0.2s ease-in-out);
			width: 8px;
		}

		/* checked mark aspect changes */
		[type="radio"]:not(:checked)+label:after {
			@include opacity(0);
			@include scale(0);
		}

		[type="radio"]:checked+label:after {
			@include opacity(1);
			@include scale(1);
		}

		/* disabled checkbox */
		[type="radio"]:disabled:not(:checked)+label:before,
		[type="radio"]:disabled:checked+label:before {}

		[type="radio"]:disabled:checked+label:after {}

		[type="radio"]:disabled+label {}

		/* accessibility */
		[type="radio"]:checked:focus+label:before,
		[type="radio"]:not(:checked):focus+label:before {}


		select {
			@include appearance(none);
			background: #ffffff;
			border: 1px solid rgba(0, 126, 204, 0.30);
			@include border-radius(4px);
			@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
			color: $sWTitleColor;
			cursor: pointer;
			display: inline-block;
			font-family: $fontLato;
			font-size: 16px;
			margin: 0;
			line-height: 20px;
			outline: none;
			padding: 8px 36px 8px 8px;
			vertical-align: top;
			width: 100%;

			&.disabled,
			&:disabled {
				cursor: default;
				@include opacity(0.5);

				&:hover {
					&:after {
						top: 9px;
					}
				}
			}

			&.hide {
				display: none;
			}

			@include clear;
		}

		&.select {
			cursor: pointer;
			max-width: 49%;
			position: relative;
			width: 100%;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-left: 0;
				margin-top: 10px;
				max-width: 100%;
			}

			&:after {
				display: none;
			}
		}

		.styledSelect {
			background: #ffffff;
			border: 1px solid $formInputBorder;
			@include border-radius(4px);
			@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
			color: $metaMenuActiveGrey;
			cursor: pointer;
			display: inline-block;
			font-family: $fontLato;
			font-size: 18px;
			margin: 0;
			line-height: 30px;
			outline: none;
			padding: 7px 36px 9px 12px;
			vertical-align: top;
			width: 100%;

			&:after {
				background: transparent;
				color: $metaMenuActiveGrey;
				content: '\E807';
				font-family: $icon;
				font-size: 8px;
				padding: 0 0 2px;
				pointer-events: none;
				position: absolute;
				right: 12px;
				top: 9px;
				@include transition(all 0.2s ease-in-out);
			}

			&:hover {
				&:after {
					top: 12px;
				}
			}

			&:active,
			&.active {
				&:hover {
					&:after {
						top: 9px;
					}
				}
			}

			&.disabled,
			&:disabled {
				cursor: default;
				@include opacity(0.5);

				&:hover {
					&:after {
						top: 9px;
					}
				}
			}
		}

		.options {
			background-color: white;
			border: 1px solid $formInputBorder;
			@include border-radius(3px);
			@include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.15));
			cursor: pointer;
			display: none;
			font-size: 18px;
			left: 0;
			list-style: none;
			margin: 0;
			padding: 4px 0 11px;
			position: absolute;
			right: 0;
			top: 100%;
			top: calc(100% + 4px);
			z-index: 999;

			li {
				color: $metaMenuActiveGrey;
				line-height: 30px;
				margin: 0;
				padding: 0 12px;

				&:hover,
				&.selected {
					background-color: rgba(242, 235, 230, 0.50);
				}
			}
		}

		input[type="submit"],
		button.configure,
		button[id^="submit"] {
			-webkit-appearance: none;
			-moz-appearance: none;
			background: $formSubmit;
			border: none;
			@include border-radius(3px);
			cursor: pointer;
			font-family: $fontLatoBold;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 29px;
			margin: 33px 30px 31px 72px;
			padding: 9px 19px 11px 17px;
			@include transition(all 0.2s ease-in-out);

			@include max-screen($tabletPortrait) {
				margin: 17px;
			}

			&:hover {
				background: $formSubmitHover;
			}
		}
	}
}

h4.umbraco-forms-caption {
	color: $mainTitleColor;
	display: inline-block;
	font-family: $fontLato;
	font-weight: normal;
	font-size: 24px !important;
	line-height: 22px !important;
	margin: 18px 0 22px;
	padding: 18px 35px;
	vertical-align: top;

	&+fieldset {
		padding-top: 38px !important;
	}
}

.col-md-12>div>div.contour div.contourPage {
	border-top: none;
}

div.highLight {
	border: 1px solid $mainMenuBackground;
	@include border-radius(3px);
	box-sizing: border-box;
	color: $metaMenuActiveGrey;
	font-size: 15px;
	margin: 39px 0 0;
	padding: 12px 22px 16px;

	span.title {
		color: $topicalGrey;
		font-size: 13px;
		text-transform: uppercase;
	}
}

.tel {
	color: $telColor;
	display: block;
	font-family: $fontLatoBold;
	font-size: 18px;
	line-height: 18px;
	margin: 31px 0 9px;
	/*&:before {
		color: $telIconColor;
		content: '\E80C';
		display: inline-block;
		font-family: $icon;
		margin-right: 21px;
	}*/
}

.locationFilter {
	background: $selectDropdown;
	@include border-radius(3px);
	float: right;
	margin: 82px 0 0;
	padding: 15px 25px 27px;

	@include clear;

	@include max-screen($menuBreakpointRes) {
		width: 100%;
		margin-top: 0;
	}

	label {
		color: #0D486E;
		display: block;
		font-family: $fontLatoBold;
		font-size: 13px;
		line-height: 29px;
		margin: 2px 0 11px;
		text-transform: uppercase;
	}

	div.selectBox {
		position: relative;

		select {
			@include appearance(none);
			background: #ffffff;
			border: 1px solid rgba(0, 126, 204, 0.30);
			@include border-radius(4px);
			@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
			color: $homeBlue;
			cursor: pointer;
			display: inline-block;
			font-family: $fontLato;
			font-size: 16px;
			margin: 0;
			line-height: 20px;
			outline: none;
			padding: 8px 36px 8px 8px;
			vertical-align: top;
			width: 100%;

			&.disabled,
			&:disabled {
				cursor: default;
				@include opacity(0.5);

				&:hover {
					&:after {
						top: 9px;
					}
				}
			}

			&.hide {
				display: none;
			}

			@include clear;
		}

		&.select {
			cursor: pointer;
			max-width: 49%;
			position: relative;
			width: 100%;

			@include max-screen($tabletPortrait) {
				float: none;
				margin-left: 0;
				margin-top: 10px;
				max-width: 100%;
			}

			&:after {
				display: none;
			}
		}

		.styledSelect {
			background: #ffffff;
			border: 1px solid rgba(0, 126, 204, 0.3);
			@include border-radius(4px);
			@include box-shadow(0px 2px 2px 0px rgba(0, 60, 101, 0.05));
			color: $homeBlue;
			cursor: pointer;
			display: inline-block;
			font-family: $fontLato;
			font-size: 16px;
			margin: 0;
			line-height: 30px;
			outline: none;
			padding: 7px 36px 9px 12px;
			vertical-align: top;
			width: 100%;

			&:after {
				background: transparent;
				color: $homeBlue;
				content: '\E807';
				font-family: $icon;
				font-size: 8px;
				padding: 0 0 2px;
				pointer-events: none;
				position: absolute;
				right: 12px;
				top: 9px;
				@include transition(all 0.2s ease-in-out);
			}

			&:hover {
				&:after {
					top: 12px;
				}
			}

			&:active,
			&.active {
				&:hover {
					&:after {
						top: 9px;
					}
				}
			}

			&.disabled,
			&:disabled {
				cursor: default;
				@include opacity(0.5);

				&:hover {
					&:after {
						top: 9px;
					}
				}
			}
		}

		.options {
			background-color: white;
			border: 1px solid rgba(0, 126, 204, 0.3);
			@include border-radius(3px);
			@include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.15));
			cursor: pointer;
			display: none;
			font-size: 16px;
			left: 0;
			list-style: none;
			margin: 0;
			padding: 4px 0 11px;
			position: absolute;
			right: 0;
			top: 100%;
			top: calc(100% + 4px);
			z-index: 999;

			li {
				color: $homeBlue;
				line-height: 28px;
				margin: 0;
				padding: 0 12px;

				&:hover,
				&.selected {
					background-color: rgba(0, 126, 204, 0.1);
				}
			}
		}
	}

	button {
		background: $homeBlue;
		border: none;
		@include border-radius(4px);
		color: #ffffff;
		cursor: pointer;
		font-family: $fontLatoBold;
		font-size: 16px;
		font-weight: normal;
		line-height: 18px;
		margin: 10px 0 0;
		padding: 10px 11px 9px;
		vertical-align: top;
	}
}

div.contactLink {
	font-size: 15px;
	margin: 38px 0 84px;

	@include max-screen($tabletPortrait) {
		margin: 25px 0 32px;
	}

	span {
		display: inline-block;
		margin-right: 18px;

		@include max-screen($tabletPortrait) {
			display: block;
		}
	}

	a.moreLink {
		font-family: $fontLatoBold;

		&:after {
			line-height: 30px;
		}
	}
}

ul.vacancies {
	list-style: none;
	margin: 0 -15px;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0;

	li {
		width: 50%;
		padding: 15px;

		@include max-screen($tabletPortrait) {
			width: 100%;
		}

		a {
			background: #ffffff;
			border: 1px solid $mainMenuBackground;
			@include border-radius(3px);
			box-sizing: border-box;
			display: block;
			padding: 28px 28px 21px;
			text-align: left;
			text-decoration: none;
			@include transition(all 0.2s ease-in-out);
			width: 100%;

			&:hover {
				@include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.1));

				>span.moreLink {
					&:after {
						margin: 1px 5px 0 6px;
					}
				}
			}

			span.content {
				display: block;
				margin-bottom: 22px;

				span.name {
					color: $metaMenuActiveGrey;
					display: block;
					font-family: $fontLatoBold;
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 17px;
					min-height: 48px;
				}

				span.hours,
				span.closing {
					color: $metaMenuActiveGrey;
					display: block;
					font-size: 15px;
					line-height: 21px;
				}
			}

			span.moreLink {
				color: $orangeTextColor;
				font-size: 15px;
				line-height: 29px;
				text-decoration: none;

				&:hover {
					&:after {
						margin: 1px 5px 0 6px;
					}
				}

				&:after {
					color: $moreLinkIconColor;
					content: '\e802';
					display: inline;
					font-family: $icon;
					font-size: 6px;
					line-height: 32px;
					margin: 1px 0 0 11px;
					@include transition(all 0.2s ease-in-out);
					vertical-align: top;

					@include max-screen($breadcrumBreakpoint) {
						line-height: 25px;
					}
				}
			}
		}
	}
}
.cf-turnstile {
	margin-left: 72px;
}
table.bordered {
	border-right: 1px solid #E6DFDA;
	border-bottom: 1px solid #E6DFDA;

	tr td {
		border-left: 1px solid #E6DFDA;
		border-top: 1px solid #E6DFDA;
	}
}

table tr td {
	padding: 5px 10px;
}
