$consent-cookiebar-bg: #fff;
$consent-cookiebar-color: black;

$consent-button-background: #D54D12;;
$consent-button-color: #fff;

$consent-button-highlight-background: #D54D12;;
$consent-button-highlight-color: #fff;

$consent-view-breakpoint: 768px;

@import '@humandigital-agency/consent-controller/src/css/consent';

@font-face {
	font-family: 'latolight';
	src: url('/fonts/lato-lig-webfont.eot');
	src: url('/fonts/lato-lig-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-lig-webfont.woff2') format('woff2'), url('/fonts/lato-lig-webfont.woff') format('woff'), url('/fonts/lato-lig-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'latobold';
	src: url('/fonts/lato-bol-webfont.eot');
	src: url('/fonts/lato-bol-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/lato-bol-webfont.woff2') format('woff2'), url('/fonts/lato-bol-webfont.woff') format('woff'), url('/fonts/lato-bol-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.consent-cookiebar {
    border-radius: 4px 4px 0 0;
    z-index: 9999999;
    border-top: 1px solid #d8d8d8;

    .row{
        margin: 0;
    }

    .content {
        .title {
            font-size: 34px;
            line-height: 43px;
            font-family: latolight;
            margin-bottom: 30px;
            color: #532078;
        }

        .text{
            max-width: 800px;
        }
    }
}

.consent-cookiebar .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    a.button, button.button {
        cursor: pointer;
        width: 100%;
        text-align: center;
        font-weight: 600;
        padding: 10px 15px;
        justify-content: center;
        text-decoration: none;
        z-index: 1;
        outline: 0;
        border: 0;
        font-size: 1rem;
        line-height: normal;
        display: block;
    
        span{
            font-size: unset;
            line-height: unset;
            margin: 0;
        }
    
        &:hover{
            opacity: .8;
        }
    
        @media screen and (min-width: 768px) {
            max-width: none;
            display: block;
            font-size: 1.2rem;
            padding: 7px 15px;
        }    
    }
}

.consent-form {
    margin-bottom: 40px;
    margin-top: 40px;

    .title {
        font-size: 21px;
        font-family: latobold;
        font-weight: 400;
    }

    .checkboxTitle{
        font-size: 18px;
        font-weight: 400;
    }

    .consentCheckbox label{
        cursor: pointer;

        &::before, &::after{
            top: 4px;
        }
    }

    .button{
        border: 0;
        cursor: pointer;
    }
}
