@use "sass:math";

@mixin screen($res-min, $res-max, $orientation: false) {
  @if $orientation {
    @media screen and (min-width: $res-min) and (max-width: $res-max)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $res-min) and (max-width: $res-max) {
      @content;
    }
  }
}

@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

@mixin screen-height($res-min, $res-max, $orientation: false) {
  @if $orientation {
    @media screen and (min-height: $res-min) and (max-height: $res-max)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
  @else {
    @media screen and (min-height: $res-min) and (max-height: $res-max) {
      @content;
    }
  }
}

@mixin max-screen-height($res) {
	@media screen and (max-height: $res) {
		@content;
	}
}

@mixin min-screen-height($res) {
	@media screen and (min-height: $res) {
		@content;
	}
}

// --- hdpi --------------------------------------------------------------------

// Based on bourbon hidpi-media-queries file
// (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_hidpi-media-query.scss)
// HiDPI mixin. Default value set to 1.3 to target Google Nexus 7
// (http://bjango.com/articles/min-device-pixel-ratio/)


// --- hdtv --------------------------------------------------------------------

@mixin hdtv($standard: '1080') {
  $device-min-width: false;
  $device-min-height: false;

  $standards: ('720p', 1280px, 720px)
              ('1080', 1920px, 1080px)
              ('2K', 2048px, 1080px)
              ('4K', 4096px, 2160px);

  @each $s in $standards {
    @if $standard == nth($s, 1) {
      $device-min-width: nth($s, 2);
      $device-min-height: nth($s, 3);
    }
  }

  @media only screen
  and (min-device-width: $device-min-width)
  and (min-device-height: $device-min-height)
  and (min-width: $device-min-width)
  and (min-height: $device-min-height) {
    @content;
  }
}

// --- iphone-3 ----------------------------------------------------------------

@mixin iphone3($orientation: all) {
  $device-min-width: 320px;
  $device-max-width: 480px;
  $device-pixel-ratio: 1;

  @if $orientation == all {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
}

// --- iphone4 -----------------------------------------------------------------

@mixin iphone4($orientation: all) {
  $device-min-width: 320px;
  $device-max-width: 480px;
  $device-pixel-ratio: 2;
  $device-aspect-ratio: '2/3';

  @if $orientation == all {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (device-aspect-ratio: $device-aspect-ratio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (device-aspect-ratio: 2/3)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
}

// --- iphone5 -----------------------------------------------------------------

@mixin iphone5($orientation: all) {
  $device-min-width: 320px;
  $device-max-width: 568px;
  $device-pixel-ratio: 2;
  $device-aspect-ratio: '40/71';

  @if $orientation == all {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (device-aspect-ratio: $device-aspect-ratio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (device-aspect-ratio: $device-aspect-ratio)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
}

// --- iphone6 -----------------------------------------------------------------

@mixin iphone6($orientation: all) {
  $deviceMinWidth: 375px;
  $deviceMaxWidth: 667px;
  $devicePixelRatio: 2;

  @if $orientation == all {
    @media only screen
    and (min-device-width: $deviceMinWidth)
    and (max-device-width: $deviceMaxWidth)
    and (-webkit-device-pixel-ratio: $devicePixelRatio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $deviceMinWidth)
    and (max-device-width: $deviceMaxWidth)
    and (-webkit-device-pixel-ratio: $devicePixelRatio)
    and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// --- iphone6 plus ------------------------------------------------------------

@mixin iphone6-plus($orientation: all) {
  $deviceMinWidth: 414px;
  $deviceMaxWidth: 736px;
  $devicePixelRatio: 3;

  @if $orientation == all {
    @media only screen
    and (min-device-width: $deviceMinWidth)
    and (max-device-width: $deviceMaxWidth)
    and (-webkit-device-pixel-ratio: $devicePixelRatio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $deviceMinWidth)
    and (max-device-width: $deviceMaxWidth)
    and (-webkit-device-pixel-ratio: $devicePixelRatio)
    and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// --- ipad (all) --------------------------------------------------------------

@mixin ipad($orientation: all) {
  $device-min-width: 768px;
  $device-max-width: 1024px;

  @if $orientation == all {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
}

// --- ipad-retina -------------------------------------------------------------

@mixin ipad-retina($orientation: all) {
  $device-min-width: 768px;
  $device-max-width: 1024px;
  $device-pixel-ratio: 2;

  @if $orientation == all {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio) {
      @content;
    }
  }
  @else {
    @media only screen
    and (min-device-width: $device-min-width)
    and (max-device-width: $device-max-width)
    and (-webkit-device-pixel-ratio: $device-pixel-ratio)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
}

// --- orientation -------------------------------------------------------------

@mixin _orientation($orientation) {
  @media screen and (orientation: $orientation) {
    @content;
  }
}

@mixin landscape() {
  @include _orientation(landscape) {
    @content;
  }
}

@mixin portrait() {
  @include _orientation(portrait) {
    @content;
  }
}

/* Transition Mixin */
@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transition-delay($transition) {
	-webkit-transition-delay: $transition;
	   -moz-transition-delay: $transition;
		-ms-transition-delay: $transition;
		 -o-transition-delay: $transition;
			transition-delay: $transition;
}

/* Border-Radius Mixin */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
	   -o-border-radius: $radius;
          border-radius: $radius;
}

/* Box-Shadow Mixin */
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
			box-shadow: $shadow;
}

/* Box-Shadow Mixin */
@mixin box-shadow-double($shadow, $othershadow) {
    -webkit-box-shadow: $shadow, $othershadow;
       -moz-box-shadow: $shadow, $othershadow;
			box-shadow: $shadow, $othershadow;
}

/* Text-Shadow Mixin */
@mixin text-shadow($textShadow) {
    text-shadow: $textShadow;
}

/* Opacity Mixin */
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8

	@if ($opacity > 0) {
		pointer-events: all;
	} @else {
		pointer-events: none;
	}
}

/* Gradient Top Bottom Mixin */
@mixin gradientTopBottom($from, $to) {
	background-color: $from;
	background-image: -moz-linear-gradient($from, $to);
	background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
	background-image: -webkit-linear-gradient($from, $to);
	background-image: -o-linear-gradient($from, $to);
	background-image:	-ms-linear-gradient($from, $to);
}

/* Gradient Left Right Mixin */
@mixin gradientLeftRight($from, $to) {
	background-color: $from;
	background-image: -moz-linear-gradient(left, $from 0%, $to 100%);
	background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop(100%, $to));
	background-image: -webkit-linear-gradient(left, $from 0%, $to 100%);
	background-image: -o-linear-gradient(left, $from 0%, $to 100%);
	background: -ms-linear-gradient(left, $from 0%, $to 100%);
	background: linear-gradient(to right, $from 0%, $to 100%);
}

@mixin gradientRadial($from, $to) {
	background: -moz-radial-gradient(center, ellipse cover, $from 0%, $to 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
	background: -webkit-radial-gradient(center, ellipse cover, $from 0%, $to 100%);
	background: -o-radial-gradient(center, ellipse cover, $from 0%, $to 100%);
	background: -ms-radial-gradient(center, ellipse cover, $from 0%, $to 100%);
	background: radial-gradient(center, ellipse cover, $from 0%, $to 100%);
	background-color: $from;
}

@mixin gradientRadialBottom($from, $to) {
	background: -moz-radial-gradient(bottom, ellipse cover, $from 10%, $to 60%);
	background: -webkit-gradient(radial, bottom center, 10%, center center, 60%, color-stop(0%, $from), color-stop(100%, $to));
	background: -webkit-radial-gradient(bottom, ellipse cover, $from 10%, $to 60%);
	background: -o-radial-gradient(bottom, ellipse cover, $from 10%, $to 60%);
	background: -ms-radial-gradient(bottom, ellipse cover, $from 10%, $to 60%);
	background: radial-gradient(bottom, ellipse cover, $from 10%, $to 60%);
	background-color: $from;
			
	@include max-screen($tabletPortrait) {
		background: -moz-radial-gradient(bottom, ellipse cover, $from 30%, $to 60%);
		background: -webkit-gradient(radial, bottom center, 30%, center center, 60%, color-stop(0%, $from), color-stop(100%, $to));
		background: -webkit-radial-gradient(bottom, ellipse cover, $from 30%, $to 60%);
		background: -o-radial-gradient(bottom, ellipse cover, $from 30%, $to 60%);
		background: -ms-radial-gradient(bottom, ellipse cover, $from 30%, $to 60%);
		background: radial-gradient(bottom, ellipse cover, $from 30%, $to 60%);
		background-color: $from;
	}
}

/* Transform Mixin */
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
            transform: $transforms;
}

/* Rotate Mixin */
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
/* Scale Mixin */
@mixin scale($scale) {
	 @include transform(scale($scale));
} 

/* Translate Mixin */
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

/* TranslateY Mixin */
@mixin translateY ($y) {
	-webkit-transform: translateY($y);
	-moz-transform: translateY($y);
	-ms-transform: translateY($y);
	transform: translateY($y);
}

/* TranslateX Mixin */
@mixin translateX ($x) {
	-webkit-transform: translateX($x);
	-moz-transform: translateX($x);
	-ms-transform: translateX($x);
	transform: translateX($x);
}

/* Transform Origin Mixin */
@mixin transform-origin ($origin) {
		moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
            transform-origin: $origin;
}

/* Animate Mixin */
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}  
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}  
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
    -moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};      
}

/* Placeholder Mixin (Placeholder styling doesnt work in every browser) */
@mixin placeholder {
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content}  
}

/* Flexbox Mixin */
@mixin flex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin appearance($content) {
	-webkit-appearance: $content;
	-moz-appearance: $content;
	appearance: $content;
}

		

input::-moz-focus-inner { 
	border: 0; 
}

object, embed, a, a:hover, a:active, a:focus { 
	outline: 0;
}

/* Grid */
$mobile: 568px;
$tabletPortrait: 767px;
$tabletLandscape: 1024px;

$menuBreakpoint: 1100px;
$menuBreakpointRes: 1000px;
$breadcrumBreakpoint: 767px;
$footerBreakpoint: 700px;

$containerWidth: 1180px;
$totalCols: 12;
$colWidth: percentage( math.div(80px, $containerWidth) );
$gutterWidth: percentage( math.div(20px, $containerWidth) );

@function column( $columnSize, $gutter: $gutterWidth){
	@return ($colWidth * $columnSize) + (($columnSize * $gutterWidth) - $gutterWidth);
}

.clearfix {
	zoom:1;
	
	&:before, &:after {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
	}
	
	&:after {
		clear: both;
	}
}

@mixin clear{
	&:after,
	&:before {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

.left{
	float: left;
}

.row {
	@extend .clearfix;
	margin: 0 -20px;
}

.column {
	@extend .left;
	margin-left: $gutterWidth;
}

@for $i from 1 through $totalCols {
	.col#{$i},
	.col-md-#{$i} {
		@extend .column;
		width: column($i);

		@include max-screen($tabletPortrait) {
			float: none;
			margin-left: 0;
			width: 100%;
		}
		
		&.centered:first-child {
			float: none;
			margin: 0 auto;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

section.content {
	@for $i from 1 through 4 {
		.push#{$i},
		.push#{$i}:first-child {
			$marginCol: (column($i) + $gutterWidth);
			margin: 0 $marginCol;

			@include max-screen($menuBreakpointRes) {
				margin: 0;
			}

			h1,
			.row > div > div > h2,
			.row > div > div > h3,
			.row > div > div > h4,
			.row > div > div > ul, 
			.row > div > div > p,
            .row > div > div > table,
			.pageWidgets,
			.row > div > div > .highlighted,
			.row > div > div > .additionalContent,
			span.newsDate {
				margin-left: $marginCol;
				margin-right: $marginCol;
                
				@include max-screen($tabletPortrait) {
					margin-left: 0;
					margin-right: 0;
				}
			}

			div.pageTools {
				margin-left: $marginCol;
				margin-right: $marginCol;
				width: column(10);

				@include max-screen($tabletPortrait) {
					margin-left: 0;
					margin-right: 0;
					width: 100%;
				}
			}
		}
	}

	div.container {
		@for $i from 1 through 4 {
			.push#{$i},
			.push#{$i}:first-child {
				@include max-screen($menuBreakpointRes) {
					margin: 0;
				}
			}
			h1,
			.row > div > div > h2,
			.row > div > div > h3,
			.row > div > div > h4,
			.row > div > div > ul,
			.row > div > div > p,
			.pageWidgets,
			.row > div > div > .highlighted,
			.row > div > div > .additionalContent,
			span.newsDate,
			div.pageTools {
				@include max-screen($tabletPortrait) {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	.col10.push1 {
		@include max-screen($menuBreakpointRes) {
			width: 100%;
		}
	}
}

.moreInfoCol {
	float: Right;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	outline: 0;
	border: 0;
	background: transparent;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

nav ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	background: transparent;
	vertical-align: baseline;
	font-size: 100%;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-weight: bold;
	font-style: italic;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

hr {
	display: block;
	margin: 1em 0;
	padding: 0;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
}

input,
select {
	vertical-align: middle;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.browserUpgrade {
	background: #ffffff;
	border-bottom: 1px solid #000000;
	color: #000000;
	left: 0;
	line-height: 20px;
	padding: 8px 20px;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;

	a {
		color: #000000;
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}
}

.container {
	box-sizing: border-box;
    margin: 0 auto;
    max-width: $containerWidth + 40px;
    padding: 0 20px;
	position: relative;
    width: 100%;
}

.container.noPaddingMobile {
	@include max-screen($tabletPortrait) {
		padding: 0;
	}
}

.container.fullHeight {
	height: 100vh;
	min-height: 800px;
}

.container:before,
.container:after {
	content: '';
	display: table;
}

.container:after {
	clear: both;
}

img {
	max-width: 100%;
    pointer-events:none !important;
}

/* Core Owl Carousel CSS */

/* clearfix 
.carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}*/
/* display none until init 
.carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}

.carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.carousel .owl-wrapper-outer{
	@include border-radius(3px);
	overflow: hidden;
	position: relative;
	width: 100%;
}
.carousel .owl-wrapper-outer.autoHeight{
	@include transition(height 500ms ease-in-out);
}
	
.carousel .owl-item{
	float: left;
}
.owl-controls .owl-pagination {
	bottom: -42px;
	display: block;
	position: absolute;
	text-align: center;
	width: 100%;
}

.owl-controls .owl-page {
	background: #E6DFDA;
	@include border-radius(50%);
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 5px 0 6px;
	width: 12px;

	&.active {
		background: #999799;
	}
}

.owl-controls .owl-buttons div{
	background-color: #D54D12;
	@include border-radius(3px);
	bottom: 30px;
	@include box-shadow(0px 1px 3px 0px rgba(138,49,11,0.24));
	box-sizing: border-box;
	cursor: pointer;
	height: 40px;
	line-height: 20px;
	margin-top: -20px;
	@include opacity(0.9);
	padding: 7px;
	position: absolute;
	right: 40px;
	text-align: center;
	top: 50%;
	@include transition(all 0.2s ease-out);
	width: 40px;
	z-index: 1;
		
	@include max-screen($menuBreakpointRes) {
		right: 0;
	}
		
	@include max-screen($tabletPortrait) {
		display: none;
	}

	@include max-screen-height(600px) {
		bottom: 30px;
		left: 10px;
		right: auto;
	}
	
	&:after {
		content: '\E802';
		color: #ffffff;
		display: inline;
		font-family: 'helioIcon';
		font-size: 12px;
		margin-right: -2px;
	} 
}
.owl-controls .owl-buttons div:hover {
	@include opacity(1);
}
.owl-controls .owl-buttons .owl-prev {
	left: 40px;
}
.owl-controls .owl-buttons .owl-next {
	right: 40px;
}
.owl-controls .owl-buttons .owl-prev:after {
	content: '\E806';
	margin-right: 2px;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*/
/* mouse grab icon 
.grabbing { 
    cursor:  move;
}
*/
/* fix 
.carousel .owl-wrapper,
.carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}*/




.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-controls .owl-dots {
	bottom: -42px;
	display: block;
	position: absolute;
	text-align: center;
	width: 100%;
}

.owl-controls .owl-dot {
	background: #E6DFDA;
	@include border-radius(50%);
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 5px 0 6px;
	width: 12px;

	&.active {
		background: #999799;
	}
}

.owl-controls .owl-nav div{
	background-color: #D54D12;
	@include border-radius(3px);
	bottom: 30px;
	@include box-shadow(0px 1px 3px 0px rgba(138,49,11,0.24));
	box-sizing: border-box;
	cursor: pointer;
	height: 40px;
	line-height: 20px;
	margin-top: -20px;
	@include opacity(0.9);
	padding: 7px;
	position: absolute;
	right: 40px;
	text-align: center;
	top: 50%;
	@include transition(all 0.2s ease-out);
	width: 40px;
	z-index: 1;
		
	@include max-screen($menuBreakpointRes) {
		right: 0;
	}
		
	@include max-screen($tabletPortrait) {
		display: none;
	}
	
	&.disabled {
		display: none !important;
	}

	&:after {
		content: '\E802';
		color: #ffffff;
		display: inline;
		font-family: 'helioIcon';
		font-size: 12px;
		margin-right: -2px;
	} 
}
.owl-controls .owl-nav div:hover {
	@include opacity(1);
}
.owl-controls .owl-nav .owl-prev {
	left: 40px;
}
.owl-controls .owl-nav .owl-next {
	right: 40px;
}
.owl-controls .owl-nav .owl-prev:after {
	content: '\E806';
	margin-right: 2px;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}